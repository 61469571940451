import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthState } from '../store/auth/auth.state';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const token = this.store.selectSnapshot(AuthState.token);

    const isLoggedIn = token !== undefined;

    if (!isLoggedIn){
      this.router.navigate(['loader']);
      
      return isLoggedIn;
    }      

    let isAllowed = true;

    const routeRoles = !route.data? [] : !route.data.roles? [] : route.data.roles;

    if(routeRoles && routeRoles.length > 0){
      const userRole = this.store.selectSnapshot(AuthState.role);
      
      if(!userRole)
        isAllowed = false;
      else
        isAllowed = routeRoles.includes(userRole);        
    }

    if(!isAllowed)
      this.router.navigate(['loader']);

    return isLoggedIn && isAllowed;
  }
}
