import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Order } from 'app/store/orders/.model';
import { MatDialog } from '@angular/material/dialog';
import { OrdersService } from 'app/shared/services/api/orders.service';
import { RecreateOrder } from 'app/store/orders/orders.actions';
import { ConfirmDialogComponent } from 'app/layout/components/confirm/confirm.component';

@Component({
  selector: 'change-drugstore',
  templateUrl: './change-drugstore.component.html',
  styleUrls: ['./change-drugstore.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ChangeDrugstoreComponent implements OnInit  {
  @Input() order: Order;
  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();

  public enabled: boolean = false;
  public loading: boolean = true;
  public sending: boolean = false;
  public drugstores = [];  
  public items = [];

  constructor(
    private _store: Store,
    private _ordersService: OrdersService,
    private _dialog: MatDialog
  ) { }

  ngOnInit(){
    this.loading = true;

    this._ordersService.getTopFive(this.order.shoppingCartId, this.order.address.latitude, this.order.address.longitude)
      .subscribe((response) => {
        this.items = JSON.parse(JSON.stringify(this.order.items));

        this.drugstores = response.top5.filter((entry) => entry.drugstoreId != this.order.drugstoreId);

        this.drugstores.forEach((drugstore) => {
          let ditems = {};

          this.items.forEach((item) => {
            let drugstoreItem = drugstore.items.find((entry) => entry.productId == item.productId);

            if(drugstoreItem){
              ditems[item.productId] = drugstoreItem;
            }else{
              ditems[item.productId] = {
                quantity: 0,
                quantityToCalc: 0,
                totalQuantityInStock: 0,
                discountPrice: 0,
                price: 0
              };
            }
          });

          drugstore.selected = false;
          drugstore.items = ditems;
        });

        setTimeout(() => {
          this.enabled = false;
          this.loading = false;
        }, 500);
      });
  }
  
  public goBack(){
    this.onCancel.emit([]);
  }

  public selectedChange($event){
    this.enabled = false;

    for(let i = 0; i < this.drugstores.length;i++){
      let drugstore = this.drugstores[i];
      if(drugstore.drugstoreId == $event.id && $event.selected){
        let total = this.drugstoreTotal(drugstore);
        if(total != drugstore.convenienceFeeValue + drugstore.deliveryFeeValue){
          drugstore.selected = true;
          this.enabled = true;
        }else{
          drugstore.selected = false;
        }        
      }else{
        drugstore.selected = false;
      }
    }
  }

  public quantityChange($event){
    this.drugstores.forEach((drugstore) => {
      if(drugstore.items[$event.id].totalQuantityInStock > $event.quantity){
        drugstore.items[$event.id].quantity = $event.quantity;
        drugstore.items[$event.id].quantityToCalc = $event.quantity;
      }else{
        drugstore.items[$event.id].quantity = drugstore.items[$event.id].totalQuantityInStock;
        drugstore.items[$event.id].quantityToCalc = drugstore.items[$event.id].totalQuantityInStock;
      }        
    });
    this.selectedChange({id: $event.drugstore, selected: true});
  }

  public drugstoreTotal(drugstore){
    let total = 0;

    Object.keys(drugstore.items).forEach((key) => {
      let value = drugstore.items[key];
      if(value.quantityToCalc)
        total += value.quantityToCalc * value.discountPrice;
    });
    
    return total + drugstore.deliveryFeeValue + drugstore.convenienceFeeValue;
  }

  public sendOrder(){
    let selected = this.drugstores.find((drugstore) => drugstore.selected);

    if(selected){
      let items = [];

      Object.keys(selected.items).forEach((key) => {
        let item = selected.items[key];

        if(item.quantity > 0){
          items.push({
            productId: item.productId,
            quantity: item.quantity,
            price: item.price,
            discountPrice: item.discountPrice
          });
        }
      });

      if(items.length == 0)
        return;
        
      let newOrderRequest = {
        orderId: this.order.id,
        drugstoreId: selected.drugstoreId,
        items: items,
        drugstorePositionId: selected.drugstorePositionId,
        deliveryFee: selected.deliveryFee
      }
      
      this.sending = true;

      this._ordersService.recreateOrder(this.order.id, newOrderRequest)
        .subscribe((response) => {
          this._store.dispatch(
            new RecreateOrder({ id: this.order.id, newId: response.id })
          );
          
          this.sending = false;
          this.onConfirm.emit();
          this._dialog.open(ConfirmDialogComponent, { data: { title: "Novo Pedido Criado", text: "Um novo pedido foi criado com sucesso. Identificador do pedido: "+ response.id +".", close: true }});
        },(error) => {
          console.log(error);
          this.sending = false;
          this._dialog.open(ConfirmDialogComponent, { data: { title: "Erro", text: "Houve um erro recriando o pedido.", close: true }});
        });
    }
  }
}
