import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { OrdersStateModel, getInitialState } from './.model';
import { 
    ProcessNotifications, ProcessNewOrderNotifications, SetOrders, SetOrder, 
    AcceptOrder, RejectOrder, CancelOrder, AcceptMissingOrder, AcceptUpdatedOrder,
    RecreateOrder, SelectOrder, ClearSelectedOrder, 
    SearchOrders, SetOrdersByStatus, ClearOrders 
} from './orders.actions';
import { orderStatus } from './orderStatus';
import { workingDetails } from 'app/shared/helpers';
import { OrdersService } from 'app/shared/services/api/orders.service';
import { UpdateAppsCounter } from '../activeApps/activeApps.actions';

@State<OrdersStateModel>({
    name: 'Orders',
    defaults: getInitialState()
})
export class OrdersState {
    constructor(
        private _store: Store,
        private _ordersService: OrdersService
    ) {}

    @Selector()
    static getAAFOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.AAF.value)[0].orders;
    }

    @Selector()
    static getPREOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.PRE.value)[0].orders;
    }
    
    @Selector()
    static getAAEOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.AAE.value)[0].orders;
    }

    @Selector()
    static getETFOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.ETF.value)[0].orders;
    }

    @Selector()
    static getETCROrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.ETCR.value)[0].orders;
    }

    @Selector()
    static getENLROrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.ENLR.value)[0].orders;
    }

    @Selector()
    static getETFROrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.ETFR.value)[0].orders;
    }

    @Selector()
    static getEAFOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.EAF.value)[0].orders;
    }

    @Selector()
    static getEMPFOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.EMPF.value)[0].orders;
    }

    @Selector()
    static getEAAPFOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.EAAPF.value)[0].orders;
    }

    @Selector()
    static getEALCFOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.EALCF.value)[0].orders;
    }

    @Selector()
    static getEPPFOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.EPPF.value)[0].orders;
    }

    @Selector()
    static getETCOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.ETC.value)[0].orders;
    }

    @Selector()
    static getENLOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.ENL.value)[0].orders;
    }

    @Selector()
    static getPECOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.PEC.value)[0].orders;
    }

    @Selector()
    static getPENOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.PEN.value)[0].orders;
    }

    @Selector()
    static getPCAOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.PCA.value)[0].orders;
    }

    @Selector()
    static getPSUOrders(state: OrdersStateModel) {
        return state.ordersByStatus.filter(o => o.status == orderStatus.PSU.value)[0].orders;
    }

    @Selector()
    static getOrdersByStatus(state: OrdersStateModel) {
        let response = {};

        state.counterByStatus.forEach((item) => {
            response[item.key] = item.count
        });

        return response;
    }

    @Selector()
    static getTotalOrdersByStatus(state: OrdersStateModel) {
        return state.totalByStatus;
    }

    @Selector()
    static getSearchResults(state: OrdersStateModel) {
        return state.ordersSearchResult;
    }

    @Selector()
    static getSelectedOrder(state: OrdersStateModel) {
        return state.selectedOrder;
    }
    
    updateLists(list, orderId, oldStatus, newStatus, updateNewStatusList, childOrder = undefined){
        let oldStatusIndex = list.findIndex(l => l.status == oldStatus);
        let oldStatusList = list.splice(oldStatusIndex, 1)[0];

        let newStatusIndex = list.findIndex(l => l.status == newStatus);
        let newStatusList = list.splice(newStatusIndex, 1)[0];

        let orderIndex = oldStatusList.orders.findIndex(e => e.id == orderId);

        if(orderIndex >= 0){
            let order = oldStatusList.orders[orderIndex];

            order.status = newStatus;
            order.updatedAt = new Date();

            if(newStatus == orderStatus.PRE.value)
                order.loadedAt = null;

            if(childOrder)
                order.childOrder = childOrder;

            if(updateNewStatusList)
                newStatusList.orders.push(order);

            oldStatusList.orders.splice(orderIndex, 1);
        }

        return [...list, ...oldStatusList, ...newStatusList];
    }

    updateCounters(counters, oldStatus, newStatus, updateNewStatusCounter){
        let oldCounterIndex = counters.findIndex(c => c.id == oldStatus);
        let newCounterIndex = counters.findIndex(c => c.id == newStatus);
        
        counters[oldCounterIndex].count -= 1;
        
        if(updateNewStatusCounter)
            counters[newCounterIndex].count += 1;

        return counters;
    }

    updateTotal(counters){
        return counters.reduce((total, item) => { return item.id == orderStatus.PSU.value || item.id == orderStatus.PCA.value? total : total + item.count}, 0);
    }

    @Action(ProcessNewOrderNotifications)
    ProcessNewOrderNotifications(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: ProcessNewOrderNotifications
    ) {
        let notifications:any[] = JSON.parse(payload.notifications);
        let list = JSON.parse(JSON.stringify(getState().ordersByStatus));
        let counters = JSON.parse(JSON.stringify(getState().counterByStatus));     

        notifications.forEach((notification) => {
            let listIndex = list.findIndex(l => l.status == notification.statusId);
            let itemIndex = list[listIndex].orders.findIndex(l => l.id == notification.orderId);

            if(itemIndex == -1){                            
                list[listIndex].loadedAt = null;                        
                list[listIndex].orders.push({
                    id: notification.orderId,
                    drugstore: { name: notification.drugstore },
                    total: notification.total,
                    problemDescription: notification.description,
                    status: notification.statusId,
                    createdAt: notification.date,
                    updatedAt: new Date(),
                    loaded: false
                });

                let counterIndex = counters.findIndex(c => c.id == notification.statusId);
                counters[counterIndex].count =  list[listIndex].orders.length;
            }
        });

        patchState({
            'totalByStatus': this.updateTotal(counters),
            'counterByStatus': [...counters],
            'ordersByStatus': [...list]
        });
    }

    @Action(ProcessNotifications)
    ProcessNotifications(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: ProcessNotifications
    ) {     
        let notifications:any[] = JSON.parse(payload.notifications);
        let list = JSON.parse(JSON.stringify(getState().ordersByStatus));
        let counters = JSON.parse(JSON.stringify(getState().counterByStatus));     

        notifications.forEach((notification) => {
            let previousListIndex = list.findIndex(l => l.status == notification.previousStatusId);
            let itemIndex = list[previousListIndex].orders.findIndex(l => l.id == notification.orderId);
            let newListIndex = list.findIndex(l => l.status == notification.statusId);
            
            if(itemIndex != -1 || (notification.previousStatusId == orderStatus.AAF.value && notification.statusId == orderStatus.PRE.value) || (notification.statusId == orderStatus.EALCF.value) || (notification.statusId == orderStatus.EAAPF.value) ){
                let alreadySet = list[newListIndex].orders.findIndex(l => l.id == notification.orderId);

                if(alreadySet == -1){
                    list[newListIndex].loadedAt = null;
                    list[newListIndex].orders.push({
                        id: notification.orderId,
                        drugstore: { name: notification.drugstore },
                        total: notification.total,
                        problemDescription: notification.description,
                        status: notification.statusId,
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        loaded: false
                    });                
    
                    let newCounterIndex = counters.findIndex(c => c.id == notification.statusId);
                    counters[newCounterIndex].count = list[newListIndex].orders.length;
                }                

                if(itemIndex != -1){
                    list[previousListIndex].orders.splice(itemIndex, 1);

                    let previousCounterIndex = counters.findIndex(c => c.id == notification.previousStatusId);
                    counters[previousCounterIndex].count -= 1;

                    if(counters[previousCounterIndex].count < 0)
                        counters[previousCounterIndex].count = 0;
                }
            }
        });

        patchState({
            'totalByStatus': this.updateTotal(counters),
            'counterByStatus': [...counters],
            'ordersByStatus': [...list]
        });
    }

    @Action(SetOrders)
    SetOrders(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: SetOrders
    ) {
        let list = JSON.parse(JSON.stringify(getState().ordersByStatus));
        
        let index = list.findIndex(l => l.status == payload.status);

        let statusEntry = list.splice(index, 1)[0];
        
        if(!statusEntry.loadedAt){            
            this._ordersService.getOrders(payload.status)
                .subscribe((response) =>{
                    statusEntry = { ...statusEntry, loadedAt: new Date(), orders: response };                

                    patchState({
                        'ordersByStatus': [...list, ...statusEntry]
                    });
                });
        }else{
            patchState({
                'ordersByStatus': [...list, ...statusEntry]
            });
        }
    }

    @Action(SetOrder)
    SetOrder(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: SetOrder
    ) {
        this._ordersService.getOrder(payload.id)
            .subscribe((response) => {
                patchState({
                    'selectedOrder': response
                });
            });
    }

    @Action(ClearOrders)
    ClearOrders(
        {patchState}: StateContext<OrdersStateModel>
    ) {
        patchState(getInitialState());
    }

    @Action(ClearSelectedOrder)
    ClearSelectedOrder(
        {patchState}: StateContext<OrdersStateModel>
    ) {
        patchState({
            'selectedOrder': undefined
        });
    }

    @Action(SelectOrder)
    SelectOrder(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: SelectOrder
    ) {
        let list = JSON.parse(JSON.stringify(getState().ordersByStatus));
        let index = list.findIndex(l => l.status == payload.order.status);
        let statusEntry = list.splice(index, 1)[0];
        let orderIndex = statusEntry.orders.findIndex(entry => entry.id == payload.order.id);
        let order = statusEntry.orders[orderIndex];

        if(orderIndex >= 0){
            if(!order.loaded){
                this._ordersService.getOrder(payload.order.id)
                    .subscribe((response) => {    
                        if(orderIndex >= 0){
                            order.loaded = true;
                            order = {...order, ...response, drugstore: {...response.drugstore, workingDetails: workingDetails(response.drugstore)}}
                            statusEntry.orders[orderIndex] = {...order};
                        }
                        patchState({
                            'ordersByStatus': [...list, ...statusEntry],
                            'selectedOrder': order
                        });
                    }, (error) => {
                        console.log(error);
                    });
            }else{
                patchState({
                    'selectedOrder': order
                });
            }
        }else{
            this._ordersService.getOrder(payload.order.id)
                .subscribe((response) => {
                    response.drugstore.workingDetails = workingDetails(response.drugstore);
                    patchState({
                        'selectedOrder': response
                    });
                })
        }
    }

    @Action(AcceptOrder)
    AcceptOrder(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: AcceptOrder
    ) {
        let list = JSON.parse(JSON.stringify(getState().ordersByStatus));
        let counters = JSON.parse(JSON.stringify(getState().counterByStatus));

        let updatedLists = this.updateLists(list, payload.id, orderStatus.AAF.value, orderStatus.AAE.value, true);
        let updatedCounters = this.updateCounters(counters, orderStatus.AAF.value, orderStatus.AAE.value, true);

        patchState({
            'totalByStatus': this.updateTotal(updatedCounters),
            'counterByStatus': [...updatedCounters],
            'ordersByStatus': [...updatedLists],
            'selectedOrder': undefined
        });
    }

    @Action(AcceptMissingOrder)
    AcceptMissingOrder(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: AcceptMissingOrder
    ) {
        let list = JSON.parse(JSON.stringify(getState().ordersByStatus));
        let counters = JSON.parse(JSON.stringify(getState().counterByStatus));

        let updatedLists = this.updateLists(list, payload.id, orderStatus.PRE.value, orderStatus.AAE.value, true, payload.newId);
        let updatedCounters = this.updateCounters(counters, orderStatus.PRE.value, orderStatus.AAE.value, true);
        
        patchState({
            'totalByStatus': this.updateTotal(updatedCounters),
            'counterByStatus': [...updatedCounters],
            'ordersByStatus': [...updatedLists],
            'selectedOrder': undefined
        });
    }

    @Action(AcceptUpdatedOrder)
    AcceptUpdatedOrder(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: AcceptUpdatedOrder
    ) {
        let list = JSON.parse(JSON.stringify(getState().ordersByStatus));
        let counters = JSON.parse(JSON.stringify(getState().counterByStatus));
        /*
        let updatedLists = this.updateLists(list, payload.id, orderStatus.PRE.value, orderStatus.AAE.value, true, payload.newId);
        let updatedCounters = this.updateCounters(counters, orderStatus.PRE.value, orderStatus.AAE.value, true);
        
        patchState({
            'totalByStatus': this.updateTotal(updatedCounters),
            'counterByStatus': [...updatedCounters],
            'ordersByStatus': [...updatedLists],
            'selectedOrder': undefined
        });
        */
    }

    @Action(RecreateOrder)
    RecreateOrder(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: RecreateOrder
    ) {
        let list = JSON.parse(JSON.stringify(getState().ordersByStatus));
        let counters = JSON.parse(JSON.stringify(getState().counterByStatus));

        let updatedLists = this.updateLists(list, payload.id, orderStatus.PRE.value, orderStatus.PSU.value, true, payload.newId);
        let updatedCounters = this.updateCounters(counters, orderStatus.PRE.value, orderStatus.PSU.value, true);

        patchState({
            'totalByStatus': this.updateTotal(updatedCounters),
            'counterByStatus': [...updatedCounters],
            'ordersByStatus': [...updatedLists],
            'selectedOrder': undefined
        });
    }

    @Action(CancelOrder)
    CancelOrder(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: CancelOrder
    ) {
        let list = JSON.parse(JSON.stringify(getState().ordersByStatus));
        let counters = JSON.parse(JSON.stringify(getState().counterByStatus));
        
        let updatedLists = this.updateLists(list, payload.id, payload.status, orderStatus.PCA.value, true);
        let updatedCounters = this.updateCounters(counters, payload.status, orderStatus.PCA.value, true);
        
        patchState({
            'totalByStatus': this.updateTotal(updatedCounters),
            'counterByStatus': [...updatedCounters],
            'ordersByStatus': [...updatedLists],
            'selectedOrder': undefined
        });
    }

    @Action(RejectOrder)
    RejectOrder(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: RejectOrder
    ) {
        let list = JSON.parse(JSON.stringify(getState().ordersByStatus));
        let counters = JSON.parse(JSON.stringify(getState().counterByStatus));
        
        let updatedLists = this.updateLists(list, payload.orderRejection.id, orderStatus.AAF.value, orderStatus.PRE.value, true);
        let updatedCounters = this.updateCounters(counters, orderStatus.AAF.value, orderStatus.PRE.value, true);
        
        patchState({
            'totalByStatus': this.updateTotal(updatedCounters),
            'counterByStatus': [...updatedCounters],
            'ordersByStatus': [...updatedLists],
            'selectedOrder': undefined
        });
    }

    @Action(SearchOrders)
    SearchOrders(
        { patchState, getState }: StateContext<OrdersStateModel>,
        { payload }: SearchOrders
    ) {
        this._ordersService.search(payload)
            .subscribe((response) => {            
                patchState({
                    'ordersSearchResult': [...response]
                });
            }, (error) => {    
                patchState({
                    'ordersSearchResult': []
                });
            });
    }

    @Action(SetOrdersByStatus)
    SetOrdersByStatus(
        { patchState, getState }:StateContext<OrdersStateModel>,
        { payload } : SetOrdersByStatus
    ) {
        let list = JSON.parse(JSON.stringify(getState().counterByStatus));
        let total = 0;

        for(let i = 0; i < list.length; i++){
            let entry = list[i];
            let counter = payload.find(c => c.statusId == entry.id);

            if(counter){
                list[i] = {
                    ...entry,
                    count: counter.count
                };

                if(entry.id != orderStatus.PCA.value && entry.id != orderStatus.PSU.value)
                    total += counter.count;
            }
        }

        patchState({
            'totalByStatus': total,
            'counterByStatus': list
        });
    }
}
