import { Component, ViewEncapsulation, Inject, ViewChild, OnInit, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { CommonModule } from '@angular/common';
import { AnimationController, IonSlides } from '@ionic/angular';
import { Order } from 'app/store/orders/.model';

@Component({
  selector: 'tab-support-details',
  templateUrl: './support-details.component.html',
  styleUrls: ['./support-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SupportDetailsComponent implements OnInit {
  @Input() public startOnTab: string; 
  @Input() public order: Order;

  public tab = "whatsapp";
  
  @ViewChild("slides", {static: false}) slides: IonSlides;

  constructor(
    private store: Store
  ) { }

  ngOnInit(){
    if(this.startOnTab){
      let that = this;

      setTimeout(function(){
        that.showTab(that.startOnTab);
      }, 1);
    }
  }

  public segmentChanged(event){
    this.showTab(event.detail.value);
  }

  public showTab(tab) {
    this.tab = tab;
    switch(tab){
      case 'whatsapp':
        this.slides.slideTo(0);
        break;
      case 'sms':
        this.slides.slideTo(1);
        break;
      case 'phonecall':
        this.slides.slideTo(2);
        break;
      default:
        break;
    }
  }
}
