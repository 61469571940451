import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngxs/store';
import { Subject, Observable, Subscription } from 'rxjs';
import { SetOrder } from 'app/store/orders/orders.actions';
import { SetCreditRequests, AcceptCreditRequest, RejectCreditRequest } from 'app/store/creditRequests/creditRequests.actions';
import { CreditRequestsState } from 'app/store/creditRequests/creditRequests.state';
import { OrderEntryDetailDialogComponent } from 'app/layout/components/order-entry-detail/order-entry-detail.component';
import { BackofficeService } from 'app/shared/services/api/backoffice.service';
import { LoadingController } from '@ionic/angular';
import * as moment from 'moment';

@Component({
  selector: 'app-credit-requests',
  templateUrl: 'credit-requests.page.html',
  styleUrls: ['credit-requests.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreditRequestsPage implements OnDestroy {
  public creditRequests: any[] = [];
  public creditRequests$: Observable<any[]>;
  public creditRequestsSubscription$: Subscription;

  public dirty:boolean = false;
  public loading:boolean = false;
  public loadingModal;
  public actionLoading:boolean = false;

  public currentDirection:string = "asc";
  public currentSortField:string = "deliveryManName";

  private _destroyComponent$ = new Subject();
  
  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _backofficeService: BackofficeService,
    private _loadingController: LoadingController,
  ) {
    this.listCreditRequests();
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public async openDialog(area, creditRequest) {
    this._store.dispatch(new SetOrder({id: creditRequest.orderId}));
    this._dialog.open(OrderEntryDetailDialogComponent, {data:{ area: area, tab: undefined }});
  }

  public acceptRequest(creditRequest){
    this._loadingController.create({
      message: ""
    }).then((response) => {
      this.loadingModal = response;
      this.loadingModal.present();
      setTimeout(() => {
        this._backofficeService.acceptCreditRequest(creditRequest.id)
          .subscribe((response) => {
            this._store.dispatch(new AcceptCreditRequest({id: creditRequest.id}));        
            this._loadingController.dismiss();  
          });
      }, 500);
    });
  }

  public rejectRequest(creditRequest){
    this._loadingController.create({
      message: ""
    }).then((response) => {
      this.loadingModal = response;
      this.loadingModal.present();
      setTimeout(() => {
        this._backofficeService.rejectCreditRequest(creditRequest.id)
          .subscribe((response) => {
            this._store.dispatch(new RejectCreditRequest({id: creditRequest.id}))
            this._loadingController.dismiss();  
          });
      }, 500);
    });
  }

  public listCreditRequests(){
    this.dirty = true;
    this.loading = true;

    this._store.dispatch(
      new SetCreditRequests({counter: undefined})
    );

    this.creditRequests$ = this._store.select(CreditRequestsState.getCreditRequests);
    this.creditRequestsSubscription$ = this.creditRequests$.subscribe((response) => {
      if(response)
        this.creditRequests = response;

      setTimeout(() => {
        this.loading = false;
      }, 500);
    });
  }

  public orderBy(header){
    if(this.currentSortField != header)
      this.currentDirection = "asc";

    switch(header){
      case "deliveryManName":
        this.currentSortField = "deliveryManName";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.creditRequests = this.creditRequests.slice().sort(function(a, b) {
            return b.deliveryManName.localeCompare(a.deliveryManName);
          });
        }else{
          this.currentDirection = "asc";
          this.creditRequests = this.creditRequests.slice().sort(function(a, b) {
            return a.deliveryManName.localeCompare(b.deliveryManName);
          });
        }
        break;
      case "total":
        this.currentSortField = "total";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.creditRequests = this.creditRequests.slice().sort(function(a, b) {
            return b.total - a.total;
          });
        }else{
          this.currentDirection = "asc";  
          this.creditRequests = this.creditRequests.slice().sort(function(a, b) {
            return a.total - b.total;
          });
        }
        break;
        case "createdAt":
          this.currentSortField = "createdAt";
          if(this.currentDirection == "asc")
          {
            this.currentDirection = "desc";
            this.creditRequests = this.creditRequests.slice().sort(function(a, b) {
              return moment(b.createdAt).isAfter(moment(a.createdAt))? -1 : 1;
            });
          }else{
            this.currentDirection = "asc";  
            this.creditRequests = this.creditRequests.slice().sort(function(a, b) {
              return moment(a.createdAt).isAfter(moment(b.createdAt))? -1 : 1;
            });
          }
          break;
      default:
        break;
    }
  }
}
