import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'drugstore-working-hours',
  templateUrl: './drugstore-working-hours.component.html',
  styleUrls: ['./drugstore-working-hours.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DrugstoreWorkingHoursComponent implements OnInit {
  @Input() drugstore: any;
  
  constructor(
    private store: Store
  ) { }

  ngOnInit(){ }
}