import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { OrdersHistoryComponent } from './orders-history.component';

@NgModule({
    declarations: [
        OrdersHistoryComponent
    ],
    entryComponents: [
        OrdersHistoryComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
    ],
    exports: [
        OrdersHistoryComponent
    ]
})

export class OrdersHistoryModule { }

