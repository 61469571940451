import { NgModule } from '@angular/core';
import { OrderItemComponent } from './order-item.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MapModule } from 'app/layout/components/map/map.module';

@NgModule({
    declarations: [
        OrderItemComponent
    ],
    entryComponents: [
        OrderItemComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        MapModule
    ],
    exports: [
        OrderItemComponent
    ]
})

export class OrderItemModule { }

