import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { FormsModule } from "@angular/forms";
import {
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatSelectModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatAutocompleteModule,
} from "@angular/material";
import { UsersPage } from "./users.page";
import { UsersPageRoutingModule } from "./users-routing.module";
import { SharedModule } from "app/shared/shared.module";
import { CreateUserModalComponent } from "./create-user-modal/create-user-modal.component";
import { EditUserModalComponent } from "./edit-user-modal/edit-user-modal.component";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { CpfCnpjValidatorDirective } from "app/shared/directives/validators/cpf/cpf-validator.directive";
import { BankDataComponent } from "./bank-data/bank-data.component";
import { DeliveryDataComponent } from "./delivery-data/delivery-data.component";
import { CreditCardDataComponent } from "./credit-card-data/credit-card-data.component";
import { PersonaldDataComponent } from "./personal-data/personal-data.component";
import { NavigationModule } from "app/layout/components/navigation/navigation.module";

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    FormsModule,
    IonicModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    SharedModule,
    UsersPageRoutingModule,
    MatSelectModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    NavigationModule
  ],
  providers: [CpfCnpjValidatorDirective],
  entryComponents: [
    CreateUserModalComponent,
    EditUserModalComponent,
    PersonaldDataComponent,
    BankDataComponent,
    DeliveryDataComponent,
    CreditCardDataComponent,
  ],
  declarations: [
    UsersPage,
    CreateUserModalComponent,
    EditUserModalComponent,
    PersonaldDataComponent,
    BankDataComponent,
    DeliveryDataComponent,
    CreditCardDataComponent,
  ],
})
export class UsersPageModule {}
