import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ChatsPage } from './chats.page';

import { ChatModule } from 'app/layout/components/chat/chat.module';
import { MapModule } from 'app/layout/components/map/map.module';
import { ChatsPageRoutingModule } from './chats-routing.module';
import { OrdersHistoryModule } from 'app/layout/components/orders-history/orders-history.module';

import { ChatListComponent } from './chat-list/chat-list.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DeliveryDetailsComponent } from './delivery-details/delivery-details.component';

import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ChatModule,
    MapModule,
    ChatsPageRoutingModule,
    OrdersHistoryModule,
    SharedModule
  ],
  declarations: [ChatsPage, ChatListComponent, UserDetailsComponent, DeliveryDetailsComponent]
})
export class ChatsPageModule {}
