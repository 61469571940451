import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { UsersStateModel, User, UserRoleList, UserOrder, getInitialState } from './.model';
import { CreateUser, UpdateUser, DeleteUser, SelectUser, ClearSelectedUser, GetUserDetails, GetUserHistory, ListUsers, ListWorkingAreas } from './users.actions';
import { BackofficeService } from 'app/shared/services/api/backoffice.service';

@State<UsersStateModel>({
    name: 'Users',
    defaults: getInitialState()
})
export class UsersState {
    constructor(
        private _store: Store,
        private _backofficeService: BackofficeService
    ) {}

    @Selector()
    static getManagers(state: UsersStateModel) {
        return state.usersByRole.filter(o => o.role == 1)[0].users;
    }

    @Selector()
    static getSupervisors(state: UsersStateModel) {
        return state.usersByRole.filter(o => o.role == 2)[0].users;
    }

    @Selector()
    static getOperators(state: UsersStateModel) {
        return state.usersByRole.filter(o => o.role == 3)[0].users;
    }

    @Selector()
    static getDeliveries(state: UsersStateModel) {
        return state.usersByRole.filter(o => o.role == 4)[0].users;
    }

    @Selector()
    static getWorkingAreas(state: UsersStateModel) {
        return state.workingAreas;
    }

    @Action(ListUsers)
    ListUsers(
        { patchState, getState }: StateContext<UsersStateModel>,
        { payload }: ListUsers
    ) {
        let usersByRoles:UserRoleList[] = JSON.parse(JSON.stringify(getState().usersByRole));

        this._backofficeService.listUsers(payload.roleId)
            .subscribe((response) => {
                if(response){
                    let index = usersByRoles.findIndex(url => url.role == payload.roleId);
                    if(index >= 0){
                        usersByRoles[index].users = response.users;
                    }

                    patchState({
                        'usersByRole': usersByRoles
                    });
                }
            }, (error) => {
                console.log(error);
            });
    }

    @Action(CreateUser)
    CreateUser(
        { patchState, getState }: StateContext<UsersStateModel>,
        { payload }: CreateUser
    ) {
        let usersByRoles:UserRoleList[] = JSON.parse(JSON.stringify(getState().usersByRole));
        let index = usersByRoles.findIndex(url => url.role == payload.role);

        if(index >= 0){
            usersByRoles[index].users.push(payload);

            patchState({
                'usersByRole': usersByRoles
            })
        }
    }
    

    @Action(UpdateUser)
    UpdateUser(
        { patchState, getState }: StateContext<UsersStateModel>,
        { payload }: UpdateUser
    ) {
        let usersByRoles:UserRoleList[] = JSON.parse(JSON.stringify(getState().usersByRole));
        let index = usersByRoles.findIndex(url => url.role == payload.role);

        if(index >= 0){
            let userIndex = usersByRoles[index].users.findIndex(u => u.id == payload.id);

            if(userIndex >= 0){
                usersByRoles[index].users[userIndex] = {...payload};

                patchState({
                    'usersByRole': usersByRoles
                })
            }
        }
    }

    @Action(DeleteUser)
    DeleteUser(
        { patchState, getState }: StateContext<UsersStateModel>,
        { payload }: DeleteUser
    ) {
        let usersByRoles:UserRoleList[] = JSON.parse(JSON.stringify(getState().usersByRole));
        let index = usersByRoles.findIndex(url => url.role == payload.role);

        if(index >= 0){
            let userIndex = usersByRoles[index].users.findIndex(u => u.id == payload.id);
            
            if(userIndex >= 0){
                usersByRoles[index].users.splice(userIndex, 1);

                patchState({
                    'usersByRole': usersByRoles
                })
            }
        }
    }

    //TALVEZ NÃO PRECISE
    @Action(SelectUser)
    SelectOrder(
        { patchState, getState }: StateContext<UsersStateModel>,
        { payload }: SelectUser
    ) {
        console.log(payload);
    }

    //TALVEZ NÃO PRECISE
    @Action(ClearSelectedUser)
    ClearSelectedOrder(
        { patchState }: StateContext<UsersStateModel>
    ) {
        patchState({
            'selectedUser': undefined
        });
    }

    //TALVEZ NÃO PRECISE
    @Action(GetUserDetails)
    GetUserDetails(
        { patchState, getState }: StateContext<UsersStateModel>,
        { payload }: GetUserDetails
    ) {
        console.log(payload);
    }

    @Action(GetUserHistory)
    GetUserHistory(
        { patchState, getState }: StateContext<UsersStateModel>,
        { payload }: GetUserHistory
    ) {
        console.log(payload);
    }

    @Action(ListWorkingAreas)
    ListWorkingAreas(
        { patchState }: StateContext<UsersStateModel>,
        { }: ListWorkingAreas
    ) {
        this._backofficeService.listWorkingAreas()
            .subscribe((response) => {
                if(response){
                    patchState({
                        'workingAreas': response
                    });
                }
            }, (error) => {
                console.log(error);
            });
    }
}