import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngxs/store';
import { Subject, Observable } from 'rxjs';
import { DrugstoreDetailDialogComponent } from 'app/layout/components/drugstore-detail/drugstore-detail.component';
import { ActiveAppsState } from 'app/store/activeApps/activeApps.state';

@Component({
  selector: 'app-active-connections',
  templateUrl: 'active-connections.page.html',
  styleUrls: ['active-connections.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveConnectionsPage implements OnInit, OnDestroy {
  public drugstores$: Observable<any[]>;
  public drugstores: any[] = [];
  private _destroyComponent$ = new Subject();
  
  public currentDirection:string = "desc";
  public currentSortField:string = "status";

  constructor(
    private _store: Store,
    private _dialog: MatDialog
  ) {}

  ngOnInit() {
    this.drugstores$ = this._store.select(ActiveAppsState.usingApp);
    this.drugstores$.subscribe((response) => {
      if(response)
        this.drugstores = response;
    });
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public orderBy(header){
    if(this.currentSortField != header)
      this.currentDirection = "asc";

    switch(header){
      case "status":
        this.currentSortField = "status";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.drugstores = this.drugstores.slice().sort(function(a, b) {
            return b.online - a.online;
          });
        }else{
          this.currentDirection = "asc";
          this.drugstores = this.drugstores.slice().sort(function(a, b) {
            return a.online - b.online;
          });
        }
        break;
      case "name":
        this.currentSortField = "name";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.drugstores = this.drugstores.slice().sort(function(a, b) {
            return b.name.localeCompare(a.name);
          });
        }else{
          this.currentDirection = "asc";  
          this.drugstores = this.drugstores.slice().sort(function(a, b) {
            return a.name.localeCompare(b.name);
          });
        }
      break;
      case "currentOrders":
        this.currentSortField = "currentOrders";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.drugstores = this.drugstores.slice().sort(function(a, b) {
            return b.onGoingOrders - a.onGoingOrders;
          });
        }else{
          this.currentDirection = "asc";  
          this.drugstores = this.drugstores.slice().sort(function(a, b) {
            return a.onGoingOrders - b.onGoingOrders;
          });
        }
        break;
      case "address":
        this.currentSortField = "address";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.drugstores = this.drugstores.slice().sort(function(a, b) {
            return b.address.street.localeCompare(a.address.street);
          });
        }else{
          this.currentDirection = "asc";  
          this.drugstores = this.drugstores.slice().sort(function(a, b) {
            return a.address.street.localeCompare(b.address.street);
          });
        }
        break;
      default:
        break;
    }
  }

  getWorkingHours(drugstore) {
    let weekDay = new Date().getDay();

    if(weekDay == 0 && !drugstore.sundayOpenHour)
        return drugstore.workingDetails.sunday;
    if(weekDay == 6 && !drugstore.saturdayOpenHour)
        return drugstore.workingDetails.saturday;
    else
        return drugstore.workingDetails.weekday;
  }

  public async openDialog(event) {
    this._dialog.open(DrugstoreDetailDialogComponent, { data: { 'drugstore': event.drugstore, 'tab': event.tab } });
  }
}
