import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngxs/store";
import { ConfirmDialogComponent } from "app/layout/components/confirm/confirm.component";
import { OrderEntryDetailDialogComponent } from "app/layout/components/order-entry-detail/order-entry-detail.component";
import { CpfCnpjValidatorDirective } from "app/shared/directives/validators/cpf/cpf-validator.directive";
import { BackofficeService } from "app/shared/services/api/backoffice.service";
import { SelectOrder } from "app/store/orders/orders.actions";
import { PixKeyType, UpdateUserRequest, User, UserStatus } from "app/store/users/.model";
import { ListWorkingAreas } from "app/store/users/users.actions";
import { UsersState } from "app/store/users/users.state";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "edit-user-modal",
  templateUrl: "edit-user-modal.component.html",
  styleUrls: ["edit-user-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditUserModalComponent implements OnInit, OnDestroy {
  private _destroyComponent$ = new Subject();

  public selectedHelpDeskLevel: number = 0;
  public selectedActiveStatus: boolean = true;

  public form: FormGroup;
  public personalDataForm: FormGroup;
  public deliveryDataForm: FormGroup;
  public bankDataForm: FormGroup;

  public loading: boolean = false;
  public menuSelected: string = "personal";

  public history: any[] = [];
  public historyError: boolean = false;

  public workingAreas = [];
  public workingAreasSub: Subscription;

  public menuItems = [
    {
      id: "personal",
      label: "Dados Pessoais",
      icon: "farmapp-icon-user-list",
    },
    {
      id: "bank",
      label: "Dados Bancários",
      icon: "farmapp-icon-bank",
      roleId: 4,
    },
    // {
    //   id: "delivery",
    //   label: "Dados de Entrega",
    //   icon: "farmapp-icon-user",
    //   roleId: 4,
    // },
    {
      id: "credit-card",
      label: "Dados do Cartão",
      icon: "farmapp-icon-credit-card",
      roleId: 4,
    },
    {
      id: "history",
      label: "Atendimentos",
      icon: "farmapp-icon-chat",
      roleId: 3,
    },
    {
      id: "history",
      label: "Entregas",
      icon: "farmapp-icon-order",
      roleId: 4,
    },
  ];

  constructor(
    private cpfValidator: CpfCnpjValidatorDirective,
    private _store: Store,
    private _formBuilder: FormBuilder,
    private _backofficeService: BackofficeService,
    private _dialog: MatDialog,
    public dialogRef: MatDialogRef<EditUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User }
  ) {
    this.personalDataForm = this._formBuilder.group({
      name: [this.data.user.name, [Validators.required]],
      birthDate: [this.formatBirthDate(this.data.user.birthdate), [Validators.required]],
      rg: [this.data.user.rg, [Validators.required]],
      cpf: [{ value: this.data.user.cpf, disabled: true }, [this.cpfValidator, Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
      email: [this.data.user.email, [Validators.required, Validators.email]],
      phoneNumber: [this.data.user.phoneNumber, [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
      gender: [this.data.user.gender, [Validators.required]],
      zipCode: [this.data.user.zipCode, [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      address: [this.data.user.address, [Validators.required]],
      neighborhood: [this.data.user.neighborhood, [Validators.required]],
      number: [this.data.user.number, [Validators.required]],
      complement: [this.data.user.complement, [Validators.required]],
      city: [this.data.user.city, [Validators.required]],
      state: [this.data.user.state, [Validators.required]],
    });

    this.bankDataForm = this._formBuilder.group({
      bank: [this.data.user.bank, [Validators.required]],
      agency: [this.data.user.agency, [Validators.required, Validators.minLength(3)]],
      digit: [this.data.user.digit],
      account: [this.data.user.account, [Validators.required, Validators.minLength(4)]],
      pixKeyType: [this.data.user.pixKeyType, [Validators.required]],
      pixKey: [this.data.user.pixKey, [Validators.required, Validators.minLength(10)]],
    });

    // this.deliveryDataForm = new FormGroup({
    //   vehicle: new FormControl(0, [Validators.required, Validators.min(1)]),
    //   workingAreaId: new FormControl([], [Validators.required]),
    // });

    this.form = new FormGroup({
      creditCard: new FormControl(this.data.user.creditCard),
      isActive: new FormControl({ value: !!this.data.user.creditCard && this.data.user.isActive, disabled: !this.data.user.creditCard }),
      helpDeskLevel: new FormControl(this.data.user.helpDeskLevel),
      personalData: this.personalDataForm,
    });

    if (this.data.user.role === 4) {
      this.form.addControl("bankData", this.bankDataForm);
      // this.form.addControl("deliveryData", this.deliveryDataForm);
      const isNotPending = this.data.user.status !== UserStatus.Pending;
      if (isNotPending) {
        this.form.get("creditCard").setValidators([Validators.required, Validators.minLength(16), Validators.maxLength(16)]);
        this.form.get("creditCard").updateValueAndValidity();
      }
    }

    this.initActiveToggleSafety();

    this.selectedHelpDeskLevel = this.data.user.helpDeskLevel;
  }

  private initActiveToggleSafety() {
    this.form.controls.creditCard.valueChanges.subscribe((cc) => {
      const isActiveControl = this.form.controls.isActive;
      console.log(isActiveControl.value);
      isActiveControl.setValue(!!cc && isActiveControl.value);
      isActiveControl[cc ? 'enable' : 'disable']();
      isActiveControl.updateValueAndValidity();
    });
  }

  ngOnInit() {
    this._store.dispatch(new ListWorkingAreas());
    this.workingAreasSub = this._store.select(UsersState.getWorkingAreas).subscribe((areas) => (this.workingAreas = areas));
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public changeMenu(area) {
    this.menuSelected = area;
    if (area == "history") {
      if (this.data.user.role == 3) {
        //ATENDIMENTOS
        this.loading = true;
        this.historyError = false;
        this._backofficeService.getUserHelpDeskHistory(this.data.user.id).subscribe(
          (response) => {
            this.history = response;

            setTimeout(() => {
              this.loading = false;
            }, 1000);
          },
          (error) => {
            this.loading = false;
            this.historyError = true;
          }
        );
      } else if (this.data.user.role == 4) {
        //ENTREGAS
        this.loading = true;
        this.historyError = false;
        this._backofficeService.getUserDeliveryHistory(this.data.user.id).subscribe(
          (response) => {
            this.history = response;
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          },
          (error) => {
            this.loading = false;
            this.historyError = true;
          }
        );
      }
    }
  }

  public selectHelpDeskLevel(level) {
    this.selectedHelpDeskLevel = level;
    this.form.get("helpDeskLevel").setValue(level);
  }

  public confirmDelete() {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      height: 'auto',
      data: {
        'title': 'Atenção',
        'text': `Essa ação é permanente. Você tem certeza que deseja excluir o entregador?`,
        'confirm': this.deleteUser.bind(this)
      }
    });
  }

  public deleteUser() {
    this.loading = true;

    this._backofficeService.deleteUser(this.data.user.id).subscribe(
      (response) => {
        if (response) {
          setTimeout(() => {
            this.dialogRef.close({ deleted: true, user: this.data.user });

            setTimeout(() => {
              this._dialog.open(ConfirmDialogComponent, {
                data: {
                  title: "Usuário removido",
                  text: "Usuário removido com sucesso.",
                  close: true,
                },
              });
            }, 250);
          }, 1000);
        }
      },
      (error) => {
        let message = "Houve um problema removendo o usuário";

        if (error.error.errors) message = error.error.errors.message;

        this._dialog.open(ConfirmDialogComponent, {
          data: { title: "Atenção!", text: message, close: true },
        });
      }
    );
  }

  public saveUser(status?: UserStatus) {
    if (!this.form.valid && status !== UserStatus.Reproved && status !== UserStatus.Pending) {
      this.form.markAsDirty();
      this.form.markAsTouched();

      return;
    }

    let rawValues = this.form.getRawValue();
    const updateUserRequest = this.structUser(rawValues, status);
    this.loading = true;

    this._backofficeService.updateUser(updateUserRequest).subscribe(
      (response) => {
        setTimeout(() => {
          this.dialogRef.close({ updated: true, user: updateUserRequest });

          setTimeout(() => {
            this._dialog.open(ConfirmDialogComponent, {
              data: {
                title: "Usuário atualizado",
                text: "Usuário atualizado com sucesso.",
                close: true,
              },
            });
          }, 250);
        }, 1000);
      },
      (error) => {
        this.loading = false;
        let message = "Houve um problema atualizando o usuário";

        if (error.error.errors) message = error.error.errors.message;

        this._dialog.open(ConfirmDialogComponent, {
          data: { title: "Atenção!", text: message, close: true },
        });
      }
    );
  }

  public restoreUser() {
    this.saveUser(UserStatus.Pending);
  }

  public acceptUser() {
    this.saveUser(UserStatus.Approved);
  }

  public confirmReject() {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      height: 'auto',
      data: {
        'title': 'Atenção',
        'text': `Você tem certeza que deseja recusar o entregador?`,
        'confirm': this.rejectUser.bind(this)
      }
    });
  }

  public rejectUser() {
    this.form.controls.isActive.setValue(false);
    this.saveUser(UserStatus.Reproved);
  }

  public openOrder(order) {
    this._store.dispatch(new SelectOrder({ order: order }));
    this._dialog.open(OrderEntryDetailDialogComponent, {
      data: { area: "order", tab: undefined },
    });
  }

  public formatBirthDate(dateString?: string) {
    if (!dateString) return null;
    const d = dateString.substring(0, 2);
    const m = dateString.substring(3, 5);
    const y = dateString.substring(6, 10);
    return new Date(`${y}-${m}-${d}T00:00`);
  }

  structUser(values, status?: UserStatus): UpdateUserRequest {
    const personalValues = values.personalData;
    const bankValues = values.bankData;

    let req: UpdateUserRequest = {
      id: this.data.user.id,
      status: status || this.data.user.status,
      roleId: this.data.user.role,
      isActive: values.isActive,
      helpDeskLevel: values.helpDeskLevel ? values.helpDeskLevel : 0,
      name: personalValues.name,
      birthDate: moment(personalValues.birthDate).locale("pt-br").format("L"),
      email: personalValues.email,
      phoneNumber: personalValues.phoneNumber,
      cpf: personalValues.cpf,
      gender: personalValues.gender,
      address: personalValues.address,
      number: personalValues.number,
      city: personalValues.city,
      complement: personalValues.complement,
      neighborhood: personalValues.neighborhood,
      rg: personalValues.rg,
      zipCode: personalValues.zipCode,
      state: personalValues.state,
    };

    if (this.data.user.role === 4) {
      req = {
        ...req,
        creditCard: values.creditCard,
        bank: bankValues.bank,
        agency: bankValues.agency,
        digit: bankValues.digit,
        account: bankValues.account,
        pixKey: bankValues.pixKey,
        pixKeyType: bankValues.pixKeyType,
      };
    }

    return req;
  }
}
