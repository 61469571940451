import { Component, ViewChild, ElementRef, OnInit, OnDestroy, ViewEncapsulation, Input, OnChanges, SimpleChanges, SimpleChange  } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngxs/store';
import { Subject, Observable, Subscription } from 'rxjs';
import { OrderEntryDetailDialogComponent } from 'app/layout/components/order-entry-detail/order-entry-detail.component';
import { SelectOrder, SetOrders } from 'app/store/orders/orders.actions';
import { BackofficeService } from "app/shared/services/api/backoffice.service";
import { Chat } from 'app/store/chats/.model';

@Component({
  selector: 'user-details',
  templateUrl: 'user-details.component.html',
  styleUrls: ['user-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public customer:Number;

  public details:any = undefined;
  public loading:boolean = true;

  private _destroyComponent$ = new Subject();

  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _backofficeService: BackofficeService
  ) {}

  ngOnInit() {
    this.loading = true;

    this.fetchDetails(this.customer);
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    const currentCustomer: SimpleChange = changes.customer;
    
    this.customer = currentCustomer.currentValue;

    this.fetchDetails(this.customer);
  }

  private fetchDetails(id){
    this._backofficeService.getCustomerOrderDetails(this.customer)
      .subscribe((response) => {
        if(response)
          this.details = response;
        
          setTimeout(() => {
            this.loading = false;
          }, 500);
      }, (error) => {
        this.loading = false;
      });
  }

  public openDetails(order){
    this._store.dispatch(new SelectOrder({order: order}));
    
    this._dialog.open(OrderEntryDetailDialogComponent, {data: {area: "order", tab: undefined}});
  }
}
