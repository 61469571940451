import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { Store } from "@ngxs/store";
import { Subject } from "rxjs";

@Component({
  selector: "app-navigation",
  templateUrl: "navigation.component.html",
  styleUrls: ["navigation.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent implements OnInit, OnDestroy, OnChanges {
  @Output() onTabChange: EventEmitter<any> = new EventEmitter();
  @Output() onClickCreate: EventEmitter<any> = new EventEmitter();
  @Input() currentTab: number;
  @Input() buttonLabel: string;
  @Input() tabs;

  private _destroyComponent$ = new Subject();

  public selectedTab: number = 1;

  constructor(private _store: Store) {}

  ngOnInit() {
    if (this.currentTab) this.selectedTab = this.currentTab;
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentTab: SimpleChange = changes.currentTab;

    if (currentTab != undefined) this.selectedTab = currentTab.currentValue;
  }

  public emitCreate() {
    this.onClickCreate.emit();
  }

  public select(role) {
    this.selectedTab = role.id;
    this.onTabChange.emit([role.id]);
  }
}
