import { environment } from '../environments/environment';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NotifierModule } from 'angular-notifier';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { Angulartics2Module } from 'angulartics2';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';

import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';

import { AuthState } from './store/auth/auth.state';
import { OrdersState } from './store/orders/orders.state';
import { ActiveAppsState } from './store/activeApps/activeApps.state';
import { ChatsState } from './store/chats/chats.state';
import { UsersState } from './store/users/users.state';
import { DeliveryMapState } from './store/deliveryMap/deliveryMap.state';

import { LoaderPageModule } from './pages/loader/loader.module';
import { LoginPageModule } from './pages/login/login.module';
import { DashboardPageModule } from './pages/dashboard/dashboard.module';
import { ActiveConnectionsPageModule } from './pages/active-connections/active-connections.module';
import { ChatsPageModule } from './pages/chats/chats.module';
import { SearchPageModule } from './pages/search/search.module';
import { DebuggerPageModule } from './pages/debugger/debugger.module';
import { UsersPageModule } from './pages/users/users.module';

import { SignalrService } from 'app/shared/services/signalr.service';
import { SessionService } from 'app/shared/session';

import { CustomHeaderModule } from 'app/layout/components/custom-header/custom-header.module';

import { CreditRequestsPageModule } from './pages/credit-requests/credit-requests.module';
import { CreditRequestsState } from './store/creditRequests/creditRequests.state';
import { MapPageModule } from './pages/map/map.module';
import { FeesState } from './store/fees/fees.state';
import { FeesService } from './shared/services/api/fees.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule, 
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDnPphHdbJ_lV5G0tZaoVXtAdOns3108UA'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NotifierModule,
    SharedModule.forRoot(),
    IonicModule.forRoot(), 
    Angulartics2Module.forRoot(),
    NgxsModule.forRoot([
      AuthState,
      OrdersState,
      ActiveAppsState,
      ChatsState,
      UsersState,
      CreditRequestsState,
      DeliveryMapState,
      FeesState
    ],
    {
      developmentMode: true
    }
    ),
    NgxsStoragePluginModule.forRoot({
      key: ['auth']
    }),
    NgxsRouterPluginModule.forRoot(),
    
    LayoutModule,

    //PAGES
    LoaderPageModule,
    LoginPageModule,
    DashboardPageModule,
    ActiveConnectionsPageModule,
    SearchPageModule,    
    ChatsPageModule,
    CreditRequestsPageModule,
    DebuggerPageModule,
    UsersPageModule,
    MapPageModule,
    CustomHeaderModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    SignalrService,
    SessionService,
    FeesService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
