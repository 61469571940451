import { CreateBaseFeeRequest, CreateDynamicFeeRequest } from "./.model";

export class ListBaseFees {
  static readonly type = "[Fees] ListBaseFees";
  constructor() {}
}

export class ListDynamicFees {
  static readonly type = "[Fees] ListDynamicFees";
  constructor() {}
}

export class CreateBaseFee {
  static readonly type = "[Fees] CreateBaseFee";
  constructor(public payload: CreateBaseFeeRequest) {}
}

export class CreateDynamicFee {
  static readonly type = "[Fees] CreateDynamicFee";
  constructor(public payload: CreateDynamicFeeRequest) {}
}

export class UpdateBaseFee {
  static readonly type = "[Fees] UpdateBaseFee";
  constructor(public payload: { updateBaseFeeRequest: CreateBaseFeeRequest; feeId: number }) {}
}

export class UpdateDynamicFee {
  static readonly type = "[Fees] UpdateDynamicFee";
  constructor(public payload: { updateDynamicFeeRequest: CreateDynamicFeeRequest; feeId: number }) {}
}

export class DeleteBaseFee {
  static readonly type = "[Fees] DeleteBaseFee";
  constructor(public payload: number) {}
}

export class DeleteDynamicFee {
  static readonly type = "[Fees] DeleteDynamicFee";
  constructor(public payload: number) {}
}
