import { NgModule } from '@angular/core';
import { MatDialogModule, MatIconModule, MatButtonModule } from '@angular/material';
import { DrugstoreDetailDialogComponent } from './drugstore-detail.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ChatModule } from 'app/layout/components/chat/chat.module';
import { PhoneCallHistoryModule } from 'app/layout/components/phonecall-history/phonecall-history.module';
import { MapModule } from 'app/layout/components/map/map.module';
import { DrugstoreWorkingHoursComponent } from './drugstore-working-hours/drugstore-working-hours.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    declarations: [
        DrugstoreDetailDialogComponent,
        DrugstoreWorkingHoursComponent
    ],
    entryComponents: [
        DrugstoreDetailDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        IonicModule,
        MatIconModule,
        MatButtonModule,
        ChatModule,
        PhoneCallHistoryModule,
        MapModule,
        SharedModule
    ],
    exports: [
        DrugstoreWorkingHoursComponent
    ]
})

export class DrugstoreDetailModule { }

