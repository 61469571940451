import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ApiService } from "./services/api/api.service";
import { BackofficeService } from "./services/api/backoffice.service";
import { AuthService } from "./services/api/auth.service";
import { AuthGuard } from "./auth.guard";
import { OrdersService } from "./services/api/orders.service";
import { ActiveAppsService } from "./services/api/activeApps.service";

import { PhoneNumberPipe } from "./pipes/phone-number.pipe";
import { BrasilApiService } from "./services/brasil-api/brasil-api.service";

@NgModule({
  declarations: [PhoneNumberPipe],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, CurrencyPipe, PhoneNumberPipe],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [ApiService, BrasilApiService, BackofficeService, AuthService, OrdersService, ActiveAppsService, AuthGuard],
    };
  }
}
