import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ActiveAppsStateModel, ActiveApp } from './.model';
import { ProcessAppStatusNotification, SetUsingApps, UpdateAppsCounter, ClearAppsCounter } from './activeApps.actions';
import { Store } from '@ngxs/store';
import { workingDetails } from 'app/shared/helpers';
import { orderStatus } from '../orders/orderStatus';

@State<ActiveAppsStateModel>({
    name: 'ActiveApps'
})
export class ActiveAppsState {
    constructor(
        private _store: Store,
    ) {}

    @Selector()
    static usingApp(state: ActiveAppsStateModel) {
        return state.usingApp;
    }

    @Selector()
    static offlineCount(state: ActiveAppsStateModel) {
        return state.usingApp.filter(entry => !entry.online && entry.workingDetails.isOpen).length;
    }

    @Action(ProcessAppStatusNotification)
    ProcessAppStatusNotification(
        { patchState, getState }: StateContext<ActiveAppsStateModel>,
        { payload }: ProcessAppStatusNotification
    ) {
        let usingApp =  JSON.parse(JSON.stringify(getState().usingApp));
        let online =  JSON.parse(JSON.stringify(getState().online));
        
        if(payload.status == "OFFLINE"){
            let onlineIndex = online.findIndex(entry => entry == payload.id);
            if(onlineIndex >= 0)
                online.splice(onlineIndex, 1);
        }else{
            online.push(payload.id);
        }
        
        let index = usingApp.findIndex(entry => entry.uid == payload.id);

        if(index >= 0)
            usingApp[index] = { ...usingApp[index], online: payload.status == "ONLINE" };

        patchState({
            'usingApp': usingApp,
            'online': online
        });
    }
    
    @Action(SetUsingApps)
    SetActiveApps(
        { patchState, getState }: StateContext<ActiveAppsStateModel>,
        { payload }: SetUsingApps
    ) {
        for(let i = 0; payload.usingApps.length > i; i++) {
            payload.usingApps[i] = { 
                ...payload.usingApps[i], 
                online: false,
                workingDetails: workingDetails(payload.usingApps[i])
            };

            if(payload.onlineApps.includes(payload.usingApps[i].uid))
                payload.usingApps[i].online = true;
        }

        patchState({
            'usingApp': payload.usingApps,
            'online': payload.onlineApps
        });
    }
    
    @Action(UpdateAppsCounter)
    UpdateAppsCounter(
        { patchState, getState }: StateContext<ActiveAppsStateModel>,
        { payload }: UpdateAppsCounter
    ) {
        let usingApp = JSON.parse(JSON.stringify(getState().usingApp));

        let newOrders = payload.notifications.filter(a => a.statusId == orderStatus.AAF.value && !a.delayed);
        for(let i = 0; i < newOrders.length; i++){
            let item = newOrders[i];
            if(item.details){
                let index = usingApp.findIndex(i => i.uid == item.details.drugstoreId);

                if(index >= 0)
                    usingApp[index].onGoingOrders += 1;
            }
        }

        let removedOrders = payload.notifications.filter(a => (a.statusId == orderStatus.PCA.value || a.statusId == orderStatus.PSU.value) && !a.delayed);
        for(let i = 0; i < removedOrders.length; i++){
            let item = removedOrders[i];
            if(item.details){
                let index = usingApp.findIndex(i => i.uid == item.details.drugstoreId);

                if(index >= 0)
                    usingApp[index].onGoingOrders -= 1;
            }
        }

        patchState({
            'usingApp': usingApp
        })
    }

    @Action(ClearAppsCounter)
    ClearAppsCounter(
        { patchState, getState }: StateContext<ActiveAppsStateModel>
    ) {
        patchState({
            'usingApp': [],
            'online': []
        });
    }
}
