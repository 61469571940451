import { Component, OnDestroy, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Store } from "@ngxs/store";
import { User } from "app/store/users/.model";
import { DeleteUser, ListUsers, UpdateUser } from "app/store/users/users.actions";
import { UsersState } from "app/store/users/users.state";
import { Observable, Subject, Subscription } from "rxjs";
import { CreateUserModalComponent } from "./create-user-modal/create-user-modal.component";
import { EditUserModalComponent } from "./edit-user-modal/edit-user-modal.component";

@Component({
  selector: "app-users",
  templateUrl: "users.page.html",
  styleUrls: ["users.page.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class UsersPage implements OnDestroy {
  public users: User[] = [];
  public users$: Observable<User[]>;
  public usersSubscription$: Subscription;

  public usersTabs = [
    { id: 1, label: "Gerentes" },
    { id: 2, label: "Supervisores" },
    { id: 3, label: "Operadores" },
    { id: 4, label: "Entregadores" },
  ];

  public loading: boolean = false;

  public currentDirection: string = "asc";
  public currentSortField: string = "id";

  public selectedRole: number = 1;

  private _createUserDialogRef: MatDialogRef<CreateUserModalComponent>;
  private _editUserDialogRef: MatDialogRef<EditUserModalComponent>;

  private _destroyComponent$ = new Subject();

  constructor(private _store: Store, private _dialog: MatDialog) {
    this.loading = true;

    this.users$ = this._store.select(UsersState.getManagers);

    this.usersSubscription$ = this.users$.subscribe((response) => {
      if (response) this.users = response;

      this.loading = false;
    });

    this._store.dispatch(new ListUsers({ roleId: this.selectedRole }));
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public orderBy(header) {
    if (this.currentSortField != header) this.currentDirection = "asc";

    switch (header) {
      case "id":
        this.currentSortField = "id";
        if (this.currentDirection == "asc") {
          this.currentDirection = "desc";
          this.users = this.users.slice().sort(function (a, b) {
            return b.id - a.id;
          });
        } else {
          this.currentDirection = "asc";
          this.users = this.users.slice().sort(function (a, b) {
            return a.id - b.id;
          });
        }
        break;
      case "name":
        this.currentSortField = "name";
        if (this.currentDirection == "asc") {
          this.currentDirection = "desc";
          this.users = this.users.slice().sort(function (a, b) {
            return b.name.localeCompare(a.name);
          });
        } else {
          this.currentDirection = "asc";
          this.users = this.users.slice().sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
        }
        break;
      case "phoneNumber":
        this.currentSortField = "phoneNumber";
        if (this.currentDirection == "asc") {
          this.currentDirection = "desc";
          this.users = this.users.slice().sort(function (a, b) {
            return b.phoneNumber.localeCompare(b.phoneNumber);
          });
        } else {
          this.currentDirection = "asc";
          this.users = this.users.slice().sort(function (a, b) {
            return a.phoneNumber.localeCompare(b.phoneNumber);
          });
        }
        break;
      case "email":
        this.currentSortField = "email";
        if (this.currentDirection == "asc") {
          this.currentDirection = "desc";
          this.users = this.users.slice().sort(function (a, b) {
            return b.email.localeCompare(a.email);
          });
        } else {
          this.currentDirection = "asc";
          this.users = this.users.slice().sort(function (a, b) {
            return a.email.localeCompare(b.email);
          });
        }
        break;
      case "isBlocked":
        this.currentSortField = "isBlocked";
        if (this.currentDirection == "asc") {
          this.currentDirection = "desc";
          this.users = this.users.slice().sort(function (a, b) {
            return +b.isActive - +a.isActive;
          });
        } else {
          this.currentDirection = "asc";
          this.users = this.users.slice().sort(function (a, b) {
            return +a.isActive - +b.isActive;
          });
        }
        break;
      case "approval":
        this.currentSortField = "approval";
        if (this.currentDirection == "asc") {
          this.currentDirection = "desc";
          this.users = this.users.slice().sort(function (a, b) {
            return a.status - b.status;
          });
        } else {
          this.currentDirection = "asc";
          this.users = this.users.slice().sort(function (a, b) {
            return b.status - a.status;
          });
        }
        break;
      default:
        break;
    }
  }

  public onRoleChanged(role, reload) {
    if (role != this.selectedRole || reload) {
      this.users = [];

      if (this.usersSubscription$) this.usersSubscription$.unsubscribe();

      let update = false;

      switch (role) {
        case 1:
          this.users$ = this._store.select(UsersState.getManagers);
          update = true;
          break;
        case 2:
          this.users$ = this._store.select(UsersState.getSupervisors);
          update = true;
          break;
        case 3:
          this.users$ = this._store.select(UsersState.getOperators);
          update = true;
          break;
        case 4:
          this.users$ = this._store.select(UsersState.getDeliveries);
          update = true;
          break;
        default:
          break;
      }

      if (update) {
        this.loading = true;
        this.selectedRole = role;

        this.usersSubscription$ = this.users$.subscribe((response) => {
          if (response) this.users = response;

          setTimeout(() => {
            this.loading = false;
          }, 500);
        });

        this._store.dispatch(new ListUsers({ roleId: role }));
        this.currentSortField = "id";
        this.currentDirection = "asc";
      }
    }
  }

  public openEditModal(user) {
    this._editUserDialogRef = this._dialog.open(EditUserModalComponent, { data: { user: user }, autoFocus: false });

    this._editUserDialogRef.afterClosed().subscribe((data) => {
      if (data && data.updated) {
        this._store.dispatch(new UpdateUser(data.user));
      } else if (data && data.deleted) {
        this._store.dispatch(new DeleteUser(data.user));
      }

      if ((data && data.updated) || data.deleted) {
        this.onRoleChanged(data.user.roleId, true);
      }
    });
  }

  public openCreateModal() {
    this._createUserDialogRef = this._dialog.open(CreateUserModalComponent, { data: { role: this.selectedRole }, autoFocus: false });

    this._createUserDialogRef.afterClosed().subscribe((data) => {
      if (data.succeed) this.onRoleChanged(data.role, true);
    });
  }
}
