import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MapComponent implements OnInit {
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number;

  constructor() { }

  ngOnInit() {
    if(!this.latitude)
      this.latitude = -22.970722;
    if(!this.longitude)
      this.longitude = -43.182365;
    if(!this.zoom)
      this.zoom = 18;
  }
}
