import { Component, ViewEncapsulation, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Subject, Observable, Subscription } from 'rxjs';
import { AnimationController, IonSlides } from '@ionic/angular';
import { Order } from 'app/store/orders/.model';
import { ChatsState } from 'app/store/chats/chats.state';
import { SetChat, NewChat } from 'app/store/chats/chats.actions';

@Component({
  selector: 'tab-pickr-details',
  templateUrl: './pickr-details.component.html',
  styleUrls: ['./pickr-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PickrDetailsComponent implements OnInit {
  @Input() public startOnTab: string; 
  @Input() public order: Order;
  @Output() public onCloseDialog:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("slides", {static: false}) slides: IonSlides;

  public tab = "details";

  constructor(
    private _store: Store,
    private _router: Router
  ) { }

  ngOnInit(){
    if(this.startOnTab){
      let that = this;

      setTimeout(function(){
        that.showTab(that.startOnTab);
      }, 1);
    }
  }

  public segmentChanged(event){
    this.showTab(event.detail.value);
  }

  public showTab(tab) {
    this.tab = tab;
    switch(tab){
      case 'whatsapp':
        this.slides.slideTo(0);
        break;
      case 'sms':
        this.slides.slideTo(1);
        break;
      case 'phonecall':
        this.slides.slideTo(2);
        break;
      default:
        break;
    }
  }
}
