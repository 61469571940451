import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ConfirmDialogComponent {

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, text: string, close?: boolean, confirm: Function }
  ) { }

  public close() {
    this.dialogRef.close();
  }

  public confirm(){
    if(this.data.confirm)
      this.data.confirm();
      
    this.dialogRef.close();
  }
}
