import { Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { Order } from 'app/store/orders/.model';
import { OrdersService } from 'app/shared/services/api/orders.service';
import { AcceptOrder } from 'app/store/orders/orders.actions';
import { ConfirmDialogComponent } from 'app/layout/components/confirm/confirm.component';

@Component({
  selector: 'tab-accept-details',
  templateUrl: './accept-details.component.html',
  styleUrls: ['./accept-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class AcceptDetailsComponent {
  @Input() public order: Order;
  @Output() public onCloseDialog:EventEmitter<any> = new EventEmitter<any>();

  public loading:boolean = false;

  constructor(
    private _store: Store,
    private _ordersService: OrdersService,    
    private _dialog: MatDialog
  ) { }

  acceptOrder(){
    this.loading = true;

    this._ordersService.acceptOrder(this.order.id, null)
      .subscribe((response) => {
        this._store.dispatch(
          new AcceptOrder({ id: this.order.id })
        );
        this.loading = false;
        this.onCloseDialog.emit([]);
        this._dialog.open(ConfirmDialogComponent, { data: { title: "Pedido Aceito", text: "O pedido foi aceito com sucesso.", close: true }});
      }, (error) => {
        console.log(error);
        this._dialog.open(ConfirmDialogComponent, { data: { title: "Erro", text: "Houve um problema aceitando o pedido.", close: true }});
      })
  }
}
