import { Component, ViewEncapsulation, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Store } from '@ngxs/store';
import { Subject, Observable, Subscription } from 'rxjs';
import { OrderDetailDialogComponent } from 'app/layout/components/order-detail/order-detail.component';

@Component({
  selector: 'orders-history',
  templateUrl: './orders-history.component.html',
  styleUrls: ['./orders-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrdersHistoryComponent implements OnInit, OnDestroy {
  private _destroyComponent$ = new Subject();

  constructor(
    private _store: Store,
    private _router: Router,
    private _dialog: MatDialog
  ) {}

  ngOnInit() {

  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public async openDialog() {
    this._dialog.open(OrderDetailDialogComponent);
  }
}
