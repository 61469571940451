import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Store } from '@ngxs/store';
import { LoadingController } from '@ionic/angular';
import { AuthState } from 'app/store/auth/auth.state';
import { SessionService } from 'app/shared/session';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  isLogged:boolean = false;
  isLoaded:boolean = false;

  private _loading:any;

  constructor(
    private router: Router,
    private _store: Store,
    private _loadingController: LoadingController,
    private _sessionService: SessionService
  ) {    
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationStart){        
        if(event.url == "/login" || event.url == "/loader" || event.url == "/"){
          this.isLogged = false;
          this.isLoaded = false;
        }else{
          this.isLogged = true;
          
          if(!this.isLoaded) {
            let token = this._store.selectSnapshot(AuthState.token);

            if(token)
              this.load(token);
          }
        }
      }
    });
  }

  async load(token) {
    this._loadingController.create({
      message: ""
    }).then((response) => {
      this._loading = response;
      this._loading.present();
    });

    this._sessionService.load(token)
      .then(() => {      
        this.isLoaded = true;  
        this._loadingController.dismiss();  
      });
  }
}
