import { NgModule } from '@angular/core';
import { MatDialogModule, MatIconModule, MatButtonModule } from '@angular/material';
import { OrderEntryDetailDialogComponent } from './order-entry-detail.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TabsModule } from 'app/layout/components/tabs/tabs.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        OrderEntryDetailDialogComponent
    ],
    entryComponents: [
        OrderEntryDetailDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        IonicModule,
        MatIconModule,
        MatButtonModule,
        FormsModule,
        TabsModule
    ]
})

export class OrderEntryDetailModule { }

