import { CreditRequest } from './.model';

export class ProcessCreditRequestNotification {
    static readonly type = '[CreditRequest] ProcessCreditRequestNotification';
    constructor(public payload: CreditRequest) {}
}

export class SetCreditRequests {
    static readonly type = '[CreditRequest] SetCreditRequest';
    constructor(public payload: { counter: number}) {}
}

export class AcceptCreditRequest {
    static readonly type = '[CreditRequest] AcceptCreditRequest';
    constructor(public payload: { id: number }) {}
}

export class RejectCreditRequest {
    static readonly type = '[CreditRequest] RejectCreditRequest';
    constructor(public payload: { id: number }) {}
}