import { NgModule } from '@angular/core';
import { MatDialogModule, MatIconModule, MatButtonModule } from '@angular/material';
import { StatusItemComponent } from './status-item.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MapModule } from 'app/layout/components/map/map.module';

@NgModule({
    declarations: [
        StatusItemComponent
    ],
    entryComponents: [
        StatusItemComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        IonicModule,
        MatIconModule,
        MatButtonModule,
        MapModule
    ],
    exports: [
        StatusItemComponent
    ]
})

export class StatusItemModule { }

