export class UsersStateModel {
  selectedUser: User;
  usersByRole: UserRoleList[];
  workingAreas: WorkingArea[];
}

export enum UserStatus {
  Pending = 1,
  Approved,
  Reproved,
}

export class User {
  id: number;
  role: number;
  status: UserStatus;
  name: string;
  birthdate: string;
  phoneNumber: string;
  gender: string;
  email: string;
  isActive: boolean;
  cpf: string;
  rg: string;
  zipCode: string;
  address: string;
  neighborhood: string;
  number: string;
  complement: string;
  city: string;
  state: string;
  bank: string;
  agency: string;
  digit: string;
  account: string;
  pixKey: string;
  pixKeyType: PixKeyType;
  creditCard: string;
  workingAreaId: number[];
  helpDeskLevel: number;
  createdAt: string;
  orders: UserOrder[];
}


export enum PixKeyType {
  Email = 1,
  CPF,
  CNPJ,
  Phone,
  Random
}
export interface CreateUserRequest {
  isActive: true;
  helpDeskLevel: number;
  roleId: number;
  name: string;
  birthDate: string;
  email: string;
  phoneNumber: string;
  cpf: string;
  rg: string;
  gender: string;
  zipCode: string;
  address: string;
  neighborhood: string;
  number: number;
  complement: string;
  city: string;
  state: string;
  creditCard?: string;
  workingAreaId?: number[];
  vehicle?: number;
  bank?: string;
  agency?: string;
  digit?: string;
  account?: string;
  pixKey?: string;
  pixKeyType?: PixKeyType;
}

export interface UpdateUserRequest extends CreateUserRequest {
  id: number;
  status: UserStatus;
}

export interface PersonalData {
  name: string;
  birthDate: string;
  rg: string;
  cpf: string;
  email: string;
  phoneNumber: string;
  gender: string;
  zipCode: string;
  address: string;
  neighborhood: string;
  number: string;
  complement: string;
  city: string;
  state: string;
}

export class UserRoleList {
  role: number;
  users: User[];
}

export class UserOrder {
  id: number;
}

export class WorkingArea {
  id: number;
  name: string;
}

export function getInitialState() {
  let initial: UsersStateModel = {
    selectedUser: undefined,
    usersByRole: [
      { role: 1, users: [] },
      { role: 2, users: [] },
      { role: 3, users: [] },
      { role: 4, users: [] },
    ],
    workingAreas: [],
  };

  return initial;
}
