import { Component, ViewEncapsulation, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Subject, Observable, Subscription } from 'rxjs';
import { AnimationController, IonSlides } from '@ionic/angular';
import { Order } from 'app/store/orders/.model';
import { OrdersService } from 'app/shared/services/api/orders.service';
import { ChatsState } from 'app/store/chats/chats.state';
import { SetChat, NewChildChat } from 'app/store/chats/chats.actions';
import { AuthState } from 'app/store/auth/auth.state';

@Component({
  selector: 'tab-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DeliveryDetailsComponent implements OnInit {
  @Input() public startOnTab: string; 
  @Input() public order: Order;
  @Output() public onCloseDialog:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("slides", {static: false}) slides: IonSlides;

  private timer;

  public tab = "details";
  public chat: any;
  public chats: any[] = [];
  public chats$: Observable<any[]>;
  public chatsSubscription$: Subscription;

  private role:number;
  private role$: Observable<number>;
  private roleSubscription$: Subscription;

  constructor(
    private _store: Store,
    private _router: Router,
    private _ordersService: OrdersService
  ) { }

  ngOnInit(){
    this.chats$ = this._store.select(ChatsState.getChats);
    this.chatsSubscription$ = this.chats$.subscribe((response) => {
      if(response){
        this.chats = response;
        
        let parent = response.find(c => c.contactPhoneNumber == this.order.user.phoneNumber);

        if(parent)
          this.chat = parent.children.find(cc => cc.contactPhoneNumber == this.order.delivery.phoneNumber);
      }        
    });

    this.role$ = this._store.select(AuthState.role);
    this.roleSubscription$ = this.role$.subscribe((response) => {
        this.role = response;
    });
  }

  /*
  public segmentChanged(event){
    this.showTab(event.detail.value);
  }
  */
 
  public sendMessage(){
    if(this.chat){
      this._store.dispatch(
        new SetChat({ phoneNumber: this.order.delivery.driverPhone, parent: this.order.user.uid, role: this.role})
      );
    }else{
      this._store.dispatch(
        new NewChildChat({
          contactId: this.order.delivery.driverId,
          contactUid: this.order.delivery.driverUid,        
          contactName: this.order.delivery.driverName,
          contactPhoneNumber: this.order.delivery.driverPhone,
          contactType: 1,
          parent: {
            contactUid: this.order.user.uid,
            contactId: this.order.user.id,
            contactName: this.order.user.name,
            contactPhoneNumber: this.order.user.phoneNumber
          }
        })
      );
    }
    
    setTimeout(() => {
      this.onCloseDialog.emit([]);
      this._router.navigate(['chats']);
    }, 250);    
  }

  public startTimer() {
    this.timer = setInterval(() => {
      this._ordersService.getOrderLocation(this.order.id)
        .subscribe((response) => {
          this.order = {...this.order, delivery: response};
        });
    }, 10000);
  }

  public clearTimer() {
    if(this.timer)
      clearInterval(this.timer);
  }

  /*
  public showTab(tab) {
    this.tab = tab;
    this.clearTimer();

    switch(tab){
      case 'details':
        this.startTimer();
        this.slides.slideTo(0);
        break;
      case 'whatsapp':
        this.slides.slideTo(1);
        break;
      case 'sms':
        this.slides.slideTo(2);
        break;
      case 'phonecall':
        this.slides.slideTo(3);
        break;
      default:
        break;
    }
  }
  */
}
