import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  public transform(phone: string): string {
    if (!phone || phone.trim() === '')
      return '';

    let formatted: string = '(';

    formatted = formatted + phone.substring(0, 2) + ') ';

    formatted = formatted + phone.substring(2, phone.length - 4) + '-';

    formatted = formatted + phone.substring(phone.length - 4, phone.length);

    return formatted;
  }
}
