import { Order, OrderItem, OrderRejection, OrderRejectionItem, OrderSearch } from './.model';

export class ProcessNotifications {
    static readonly type = '[Order] ProcessNotifications';
    constructor(public payload: { notifications: any }) {}
}

export class ProcessNewOrderNotifications {
    static readonly type = '[Order] ProcessNewOrderNotifications';
    constructor(public payload: { notifications: any }) {}
}

export class SetOrders {
    static readonly type = '[Order] SetOrders';
    constructor(public payload: { status: number, orders: Order[] }) {}
}

export class SetOrder {
    static readonly type = '[Order] SetOrder';
    constructor(public payload: { id: number }) {}
}

export class ClearOrders {
    static readonly type = '[Order] ClearOrders';
    constructor() {}
}

export class SelectOrder {
    static readonly type = '[Order] SelectOrder';
    constructor(public payload: { order: Order }) {}
}

export class ClearSelectedOrder {
    static readonly type = '[Order] ClearSelectedOrder';
    constructor() {}
}

export class AcceptOrder {
    static readonly type = '[Order] AcceptOrder';
    constructor(public payload: { id: number }) {}
}

export class CancelOrder {
    static readonly type = '[Order] CancelOrder';
    constructor(public payload: { id: number, status: number, details: string }) {}
}

export class RejectOrder {
    static readonly type = '[Order] RejectOrder';
    constructor(public payload: { orderRejection: OrderRejection }) {}
}

export class AcceptMissingOrder {
    static readonly type = '[Order] AcceptMissingOrder';
    constructor(public payload: {id: number, newId:number}) {}
}

export class AcceptUpdatedOrder {
    static readonly type = '[Order] AcceptUpdatedOrder';
    constructor(public payload: { id: number }) {}
}

export class RecreateOrder {
    static readonly type = '[Order] RecreateOrder';
    constructor(public payload: {id: number, newId:number}) {}
}


export class SearchOrders {
    static readonly type = '[Order] SearchOrders';
    constructor(public payload: OrderSearch) {}
}

export class SetOrdersByStatus {
    static readonly type = '[Order] SetOrdersByStatus';
    constructor(public payload:any[]) {}
}