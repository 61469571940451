import { NgModule } from '@angular/core';
import { MatDialogModule, MatIconModule, MatButtonModule } from '@angular/material';
import { OrderDetailDialogComponent } from './order-detail.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MapModule } from 'app/layout/components/map/map.module';
import { OrderItemModule } from 'app/layout/components/order-item/order-item.module';
import { StatusItemModule } from 'app/layout/components/status-item/status-item.module';
import { OrdersHistoryModule } from 'app/layout/components/orders-history/orders-history.module';

@NgModule({
    declarations: [
        OrderDetailDialogComponent
    ],
    entryComponents: [
        OrderDetailDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        IonicModule,
        MatIconModule,
        MatButtonModule,
        MapModule,
        OrderItemModule,
        StatusItemModule,
        OrdersHistoryModule
    ]
})

export class OrderDetailModule { }

