import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CreditRequestsPage } from './credit-requests.page';
import { CreditRequestsPageRoutingModule } from './credit-requests-routing.module';
import { TimerModule } from 'app/layout/components/timer/timer.module';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    TimerModule,
    CreditRequestsPageRoutingModule
  ],
  declarations: [CreditRequestsPage]
})
export class CreditRequestsPageModule {}
