export class SetProfile {
  static readonly type = '[Auth] SetProfile';
  constructor(
    public payload: { id:string, username: string, role: string, token: string }
  ) {}
}

export class LoadProfile {
  static readonly type = '[Auth] LoadProfile';
  constructor() {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
  constructor() {}
}