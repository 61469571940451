import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Store } from '@ngxs/store';
import { Subject, Observable, Subscription } from 'rxjs';
import { ChatsState } from 'app/store/chats/chats.state';
import { CloseChat, SetChat } from 'app/store/chats/chats.actions';
import { Chat, SelectedChat } from 'app/store/chats/.model';
import { ConfirmDialogComponent } from 'app/layout/components/confirm/confirm.component';
import { BackofficeService } from "app/shared/services/api/backoffice.service";

@Component({
  selector: 'app-chats',
  templateUrl: 'chats.page.html',
  styleUrls: ['chats.page.scss'],
})
export class ChatsPage implements OnInit, OnDestroy {
  private _destroyComponent$ = new Subject();
  
  public selectedChat:Chat = undefined;
  public chats: Chat[] = [];
  public chats$: Observable<Chat[]>;
  public chatsSubscription$: Subscription;

  public queue: any[] = [];
  public queue$: Observable<any[]>;
  public queueSubscription$: Subscription;

  public chat: Chat;
  public chat$:Observable<Chat>;
  public chatSubscription$:Subscription;

  public loading:boolean;

  constructor(
    private _store: Store,
    private _router: Router,
    private _dialog: MatDialog,
    private _backofficeService: BackofficeService
  ) {}

  ngOnInit() {
    this.chats$ = this._store.select(ChatsState.getChats);
    this.chatsSubscription$ = this.chats$.subscribe((response) => {
      if(response)
        this.chats = response;
      this.loading = false;
    });

    this.chat$ = this._store.select(ChatsState.getChat);
    this.chatSubscription$ = this.chat$.subscribe((response) => {
      if(response){
        this.chat = response;
        this.selectedChat = response;
      }        
    });

    this._backofficeService.getHelpDeskQueue()
      .subscribe((response) => {
        if(response)
          this.queue = response;
      });
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public closeChatConfirmation(){
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      height: 'auto',
      data: {
        'title': 'Atenção',
        'text': `Essa ação encerrará o atendimento ao cliente. Você tem certeza que deseja continuar?`,
        'confirm': this.closeChat
      }
    });
  }

  public closeChat = () => {
    this._backofficeService.closeChat(this.selectedChat.contactPhoneNumber)
      .subscribe((response) => {
        this._store.dispatch(
          new CloseChat({ phoneNumber: this.selectedChat.contactPhoneNumber })
        );
        this.selectedChat = undefined;
      }, (error) => {
        console.log(error);
      });
  }
}
