import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BrasilApiService {

  constructor(
    private http: HttpClient
  ) { }

  public getBanksList() {
    return this.http.get("https://brasilapi.com.br/api/banks/v1", this.getHeaders());
  }

  private getHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return { headers };
  }
}
