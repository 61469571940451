import { Component, Input, Output, ViewEncapsulation, EventEmitter, OnInit} from '@angular/core';

enum Mode {
    Control = 'control',
    Display = 'display'
}
@Component({
  selector: 'item-quantity',
  templateUrl: './item-quantity.component.html',
  styleUrls: ['./item-quantity.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ItemQuantityComponent implements OnInit {
    @Output() quantityChange: EventEmitter<any> = new EventEmitter();

    @Input() id: number = 0;
    @Input() drugstore: number = 0;
    @Input() mode: Mode = Mode.Control;
    @Input() price: number = 0;
    @Input() max: number = 0;

    public _quantity: number;
    @Input() 
    set quantity(quantity: number) {
        this._quantity = quantity > this.max? this.max : quantity;
    }
    get quantity() {
        return this._quantity;
    };

    public control = Mode.Control;
    public display = Mode.Display;

    constructor() { }

    ngOnInit() { }

    increment() {
        if(this.quantity >= this.max) 
            return;

        this.quantity++;
        this.quantityChange.emit({drugstore: this.drugstore, id: this.id, quantity: this.quantity});
    }

    decrement() {
        if(this.quantity === 0) 
            return;
        
        this.quantity--;
        this.quantityChange.emit({drugstore: this.drugstore, id: this.id, quantity: this.quantity});
    }
}
