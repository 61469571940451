import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngxs/store';
import { Subject, Observable, Subscription } from 'rxjs';
import { OrderEntryDetailDialogComponent } from 'app/layout/components/order-entry-detail/order-entry-detail.component';
import { SearchOrders, SelectOrder, SetOrders } from 'app/store/orders/orders.actions';
import { OrdersState } from 'app/store/orders/orders.state';
import { orderStatus} from 'app/store/orders/orderStatus';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-search',
  templateUrl: 'search.page.html',
  styleUrls: ['search.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchPage implements OnDestroy {
  public orders: any[] = [];
  public orders$: Observable<any[]>;
  public ordersSubscription$: Subscription;

  public form: FormGroup;
  public statusList = [];
  public dirty:boolean = false;
  public loading:boolean = false;

  public currentDirection:string = "asc";
  public currentSortField:string = "id";

  private _destroyComponent$ = new Subject();
  
  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {
    this.form = this._formBuilder.group({
      userName: [''],
      userCpf: [''],
      userPhone: [''],
      id: [''],
      status: ['']
    });

    Object.keys(orderStatus).forEach((key) => {
      this.statusList.push({key: key, value: orderStatus[key].value});
    });
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public search(){
    this.dirty = true;
    this.loading = true;

    this._store.dispatch(
      new SearchOrders(this.form.getRawValue())
    );

    this.orders$ = this._store.select(OrdersState.getSearchResults);
    this.ordersSubscription$ = this.orders$.subscribe((response) => {
      if(response)
        this.orders = response;

      setTimeout(() => {
        this.loading = false;
      }, 500);
    });
  }

  public orderBy(header){
    if(this.currentSortField != header)
      this.currentDirection = "asc";

    switch(header){
      case "id":
        this.currentSortField = "id";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return b.id - a.id;
          });
        }else{
          this.currentDirection = "asc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return a.id - b.id;
          });
        }
        break;
      case "status":
        this.currentSortField = "status";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return b.statusId - a.statusId;
          });
        }else{
          this.currentDirection = "asc";  
          this.orders = this.orders.slice().sort(function(a, b) {
            return a.statusId - b.statusId;
          });
        }
        break;
      case "createdAt":
        this.currentSortField = "createdAt";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return moment(b.createdAt).isAfter(moment(a.createdAt))? 1 : -1;
          });
        }else{
          this.currentDirection = "asc";  
          this.orders = this.orders.slice().sort(function(a, b) {
            return moment(a.createdAt).isAfter(moment(b.createdAt))? 1 : -1;
          });
        }
      break;
      case "user":
        this.currentSortField = "user";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return b.user.localeCompare(a.user);
          });
        }else{
          this.currentDirection = "asc";  
          this.orders = this.orders.slice().sort(function(a, b) {
            return a.user.localeCompare(b.user);
          });
        }
        break;
      case "total":
        this.currentSortField = "total";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return b.total - a.total;
          });
        }else{
          this.currentDirection = "asc";  
          this.orders = this.orders.slice().sort(function(a, b) {
            return a.total - b.total;
          });
        }
        break;
      default:
        break;
    }
  }

  public async openDialog(area, order) {
    this._store.dispatch(new SelectOrder({order: order}));
    this._dialog.open(OrderEntryDetailDialogComponent, {data:{ area: area, tab: undefined }});
  }
}
