import { Component, ViewEncapsulation, Inject, ViewChild, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { OrderItem } from 'app/store/orders/.model';

@Component({
  selector: 'order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OrderItemComponent {
  @Input() item: OrderItem;

  constructor(
    private _store: Store
  ) { }

  showOutOfStock(){
    return this.item.stock && this.item.stock == 0;
  }
  
  showQuantity(){
    return this.item.stock && this.item.stock < this.item.quantity;
  }

  getFormDescription(item){
    var reg = /^\d+$/;
    var isDigit = reg.test(item.productFormQuantity);
  
    if(isDigit)
      return item.productFormQuantity == '1'? item.productFormSingular : item.productFormPlural;
    else
      return "";
  }
}
