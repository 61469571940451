import { Component, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, Observable, Subscription } from 'rxjs';
import { OrdersState } from 'app/store/orders/orders.state';
import { orderStatus } from 'app/store/orders/orderStatus';

@Component({
  selector: 'status-navigation',
  templateUrl: 'navigation.component.html',
  styleUrls: ['navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Output() onStatusChange: EventEmitter<any> = new EventEmitter();

  public keys = {};
  
  public ordersByStatus: any = {};
  public ordersByStatus$: Observable<any[]>;
  public ordersByStatusSubscription$: Subscription;
  
  private _destroyComponent$ = new Subject();

  public selectedStatus:string = "AAF";
  
  constructor(
    private _store: Store,
  ) {
    this.keys = Object.keys(orderStatus).filter(k => k != "PEC");
  }

  public getDescription(key){
    if(orderStatus[key])
      return orderStatus[key].label;
    return "";
  }

  ngOnInit() {
    this._store.select(OrdersState.getOrdersByStatus)
      .subscribe((response) => {
        this.ordersByStatus = response;
      });
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public select(status){
    this.selectedStatus = status;
    this.onStatusChange.emit([status]);
  }
}
