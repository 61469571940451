import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AcceptDetailsComponent } from './accept-details/accept-details.component';
import { RejectDetailsComponent } from './reject-details/reject-details.component';
import { CancelDetailsComponent } from './cancel-details/cancel-details.component';
import { CostumerDetailsComponent } from './costumer-details/costumer-details.component';
import { DrugstoreDetailsComponent } from './drugstore-details/drugstore-details.component';
import { DeliveryDetailsComponent } from './delivery-details/delivery-details.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { PickrDetailsComponent } from './pickr-details/pickr-details.component';
import { SupportDetailsComponent } from './support-details/support-details.component';

import { IncompleteConfirmationComponent } from './order-details/incomplete-confirmation/incomplete-confirmation.component';
import { ChangeDrugstoreComponent } from './order-details/change-drugstore/change-drugstore.component';

import { ChatModule } from 'app/layout/components/chat/chat.module';
import { PhoneCallHistoryModule } from 'app/layout/components/phonecall-history/phonecall-history.module';
import { MapModule } from 'app/layout/components/map/map.module';
import { OrderItemModule } from 'app/layout/components/order-item/order-item.module';
import { TimerModule } from 'app/layout/components/timer/timer.module';
import { DrugstoreDetailModule } from 'app/layout/components/drugstore-detail/drugstore-detail.module';
import { ItemQuantityComponent } from './order-details/change-drugstore/item-quantity/item-quantity.component';
import { DrugstoreOptionComponent } from './order-details/change-drugstore/drugstore-option/drugstore-option.component';
import { MatIconModule } from '@angular/material';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        IonicModule,
        ChatModule,
        MapModule,
        OrderItemModule,
        TimerModule,
        PhoneCallHistoryModule,
        DrugstoreDetailModule,
        MatIconModule,
        SharedModule
    ],
    declarations: [
        AcceptDetailsComponent,
        RejectDetailsComponent,
        CancelDetailsComponent,
        CostumerDetailsComponent,
        DrugstoreDetailsComponent,
        DeliveryDetailsComponent,
        OrderDetailsComponent,
        PickrDetailsComponent,
        SupportDetailsComponent,
        IncompleteConfirmationComponent,
        ChangeDrugstoreComponent,
        ItemQuantityComponent,
        DrugstoreOptionComponent
    ],
    exports: [
        AcceptDetailsComponent,
        RejectDetailsComponent,
        CancelDetailsComponent,
        CostumerDetailsComponent,
        DrugstoreDetailsComponent,
        DeliveryDetailsComponent,
        OrderDetailsComponent,
        PickrDetailsComponent,
        SupportDetailsComponent
    ]
})
export class TabsModule {
}
