import { Component, ViewEncapsulation, Inject, ViewChild, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimationController, IonSlides } from '@ionic/angular';

@Component({
  selector: 'dialog-drugstore-detail',
  templateUrl: './drugstore-detail.component.html',
  styleUrls: ['./drugstore-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DrugstoreDetailDialogComponent implements OnInit {
  drugstore: any;
  
  public tab = "details";

  @ViewChild("slides", {static: false}) slides: IonSlides;

  constructor(
    public dialogRef: MatDialogRef<DrugstoreDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { drugstore: any,  tab: string  }
  ) { }

  ngOnInit(){
    this.drugstore = this.data.drugstore;
    
    /*
    var that = this;

    setTimeout(function(){
      that.showTab(that.data.tab);
    }, 1);
    */
  }

  /*
  public segmentChanged(event){
    this.showTab(event.detail.value);
  }

  public showTab(tab) {
    this.tab = tab;
    switch(tab){
      case 'details':
        this.slides.slideTo(0);
        break;
      case 'whatsapp':
        this.slides.slideTo(1);
        break;
      case 'sms':
        this.slides.slideTo(2);
        break;
      case 'phonecall':
        this.slides.slideTo(3);
        break;
      default:
        break;
    }
  }
  */
}
