import { Chat, NewChatEntry, NewChildChatEntry, ChatMessage } from './.model';

export class SetChatsCounter {
    static readonly type = '[Chat] SetCounter';
    constructor(public payload: number) {}
}

export class SetChats {
    static readonly type = '[Chat] SetChats';
    constructor(public payload: any) {}
}

export class ClearChats {
    static readonly type = '[Chat] ClearChats';
    constructor() {}
}

export class ProcessChatMessageNotification {
    static readonly type = '[Chat] ProcessChatMessageNotifications';
    constructor(public payload: { notifications: any }) {}
}

export class NewChat {
    static readonly type = '[Chat] NewChat';
    constructor(public payload: NewChatEntry) {}
}

export class NewChildChat{
    static readonly type = '[Chat] NewChildChat';
    constructor(public payload: NewChildChatEntry) {}
}

export class SetChat {
    static readonly type = '[Chat] SetChat';
    constructor(public payload: { phoneNumber: string, parent: string, role: number }) {}
}

export class SendMessage {
    static readonly type = '[Chat] SendMessage';
    constructor(public payload: { contactUid: string, contactId: number, contactName: string, contactPhoneNumber: string, contactType: number, content: any, parent: string }) {}
}

export class CloseChat {
    static readonly type = '[Chat] CloseChat';
    constructor(public payload: { phoneNumber: string }) {}
}