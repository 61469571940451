import { DeliveryPosition } from './.model';

export class ProcessDeliveryMapUpdateNotification {
    static readonly type = '[DeliveryMap] ProcessDeliveryMapUpdateNotification';
    constructor(public payload: { notification: string }) {}
}

export class ProcessDeliveryMapNewOrderNotification {
    static readonly type = '[DeliveryMap] ProcessDeliveryMapNewOrderNotification';
    constructor(public payload: { notifications: any }) {}
}

export class SelectDeliveryPosition {
    static readonly type = '[DeliveryMap] SelectDeliveryPosition';
    constructor(public payload: { id: number }) {}
}

export class GetMapData{
    static readonly type = '[DeliveryMap] GetMapData';
    constructor() {}
}