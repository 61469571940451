import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { CpfCnpjValidatorDirective } from "app/shared/directives/validators/cpf/cpf-validator.directive";
import { BrasilApiService } from "app/shared/services/brasil-api/brasil-api.service";
import { PixKeyType } from "app/store/users/.model";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

export interface Bank {
  code: Number;
  fullName: string;
}

@Component({
  selector: "bank-data",
  templateUrl: "bank-data.component.html",
  styleUrls: ["bank-data.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BankDataComponent implements OnInit {
  public bankList: Bank[] = [];
  public filteredBankList: Observable<Bank[]>;
  @Input() bankDataForm: FormGroup;
  @Input() label: string;

  PixKeyTypeEmail: PixKeyType = PixKeyType.Email;
  PixKeyTypeCPF: PixKeyType = PixKeyType.CPF;
  PixKeyTypeCNPJ: PixKeyType = PixKeyType.CNPJ;
  PixKeyTypePhone: PixKeyType = PixKeyType.Phone;
  PixKeyTypeRandom: PixKeyType = PixKeyType.Random;

  get controls() {
    return this.bankDataForm.controls;
  }

  get pixMask() {
    switch (this.controls.pixKeyType.value) {
      case PixKeyType.CPF:
        return "000.000.000-00";
      case PixKeyType.CNPJ:
        return "00.000.000/0000-00";
      case PixKeyType.Phone:
        return "(00) 00000-0000";
      default:
        return "";
    }
  }

  constructor(public brasilApi: BrasilApiService, private cpfCnpjValidator: CpfCnpjValidatorDirective) {}

  ngOnInit() {
    this.getBanksList();
    this.filteredBankList = this.bankDataForm.controls.bank.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );

    this.bankDataForm.controls.pixKeyType.valueChanges.subscribe((value: number) => {
      let validators: ValidatorFn[] = [Validators.required];
      let minMaxLength = value === PixKeyType.CPF ? 11 : 14;
      let additionalValidators = [this.cpfCnpjValidator as any, Validators.minLength(minMaxLength), Validators.maxLength(minMaxLength)];
      if (value === PixKeyType.CPF || value === PixKeyType.CNPJ) {
        validators = validators.concat(additionalValidators);
      }
      this.bankDataForm.controls.pixKey.setValidators(validators);
      this.bankDataForm.controls.pixKey.updateValueAndValidity();
    });
  }

  getBanksList() {
    this.brasilApi.getBanksList().subscribe((res: []) => {
      this.bankList = res;
    });
  }

  private _filter(value: string): Bank[] {
    const filterValue = value.toLowerCase();

    return this.bankList.filter((bank: Bank) => bank.fullName.toLowerCase().includes(filterValue));
  }
}
