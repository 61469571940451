function formatWorkingHours(open, close) {
    if(open && close){
        let openDate = new Date(open);
        openDate.setTime(openDate.getTime() + openDate.getTimezoneOffset() * 60 * 1000);
        let closeDate = new Date(close);
        closeDate.setTime(closeDate.getTime() + closeDate.getTimezoneOffset() * 60 * 1000);

        let openString = openDate.getMinutes() > 0? 
            openDate.getHours().toString().padStart(2, "0") + ":" + openDate.getMinutes().toString().padStart(2, "0") : 
            openDate.getHours().toString().padStart(2, "0");

        let closeString = closeDate.getMinutes() > 0? 
            closeDate.getHours().toString().padStart(2, "0") + ":" + closeDate.getMinutes().toString().padStart(2, "0") : 
            closeDate.getHours().toString().padStart(2, "0");
    
        return openString + "h às " + closeString + "h";
    }else{
        return "-";
    }
}

export function workingDetails(drugstore) {
    let now = new Date();
    let open = undefined;
    let close = undefined;
    let weekday = now.getDay();
   

    if(weekday == 0 && drugstore.sundayOpenHour)
        open = new Date(drugstore.sundayOpenHour);
    else if(weekday == 6 && drugstore.saturdayOpenHour)
        open = new Date(drugstore.saturdayOpenHour);
    else if(weekday >= 1 && weekday <= 5)
        open = new Date(drugstore.weekDayOpenHour);
    
    if(open)
        open.setTime(open.getTime() + open.getTimezoneOffset() * 60 * 1000);
    
    if(weekday == 0 && drugstore.sundayCloseHour)
        close = new Date(drugstore.sundayCloseHour);
    else if(weekday == 6 && drugstore.saturdayCloseHour)
        close = new Date(drugstore.saturdayCloseHour);
    else if(weekday >= 1 && weekday <= 5)
        close = new Date(drugstore.weekDayCloseHour);

    if(close)
        close.setTime(close.getTime() + close.getTimezoneOffset() * 60 * 1000);
    
    let isOpen = false;

    if(open && close){
        if(open.getHours() < now.getHours() && close.getHours() > now.getHours()){
            isOpen = true;
        }else if(open.getHours() == now.getHours()){
            if(open.getMinutes() < now.getMinutes())
                isOpen = true;
        }else if(close.getHours() == now.getHours()){
            if(close.getMinutes() > now.getMinutes())
                isOpen = true;
        }
    }  

    let details = {
        isOpen: isOpen,
        weekday: formatWorkingHours(drugstore.weekDayOpenHour, drugstore.weekDayCloseHour),
        saturday: formatWorkingHours(drugstore.saturdayOpenHour, drugstore.saturdayCloseHour),
        sunday: formatWorkingHours(drugstore.sundayOpenHour, drugstore.sundayCloseHour),
    };

    return details;
}