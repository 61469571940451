import { Component, OnInit, OnDestroy } from '@angular/core';
//import { LoadingController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Subject, Observable, Subscription } from 'rxjs';
import { AuthState } from 'app/store/auth/auth.state';
import { LoadProfile } from 'app/store/auth/auth.actions';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.page.html',
  styleUrls: ['./loader.page.scss'],
})
export class LoaderPage implements OnInit {
  public user: any;
  public user$: Observable<any>;

  private _destroyComponent$ = new Subject();

  constructor(
    private _router: Router,
    private _store: Store,
    //protected _loadingController: LoadingController
  ) { }

  async ngOnInit() {    
    //if (!await this._loadingController.getTop()) {
      //const loader = await this._loadingController.create();

      //await loader.present();
    //}
    
    this._store.dispatch(new LoadProfile());

    this.user$ = this._store.select(AuthState.token);

    this.user$.subscribe((response) => { 
      this.user = response;
      
      setTimeout(async () => {
        //if (await this._loadingController.getTop())
          //this._loadingController.dismiss();

        if (!this.user)
          this._router.navigate(['login']);
        else
          this._router.navigate(['dashboard']);
      }, 1000);
    });
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }
}
