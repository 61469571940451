import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { CreditRequestsStateModel, CreditRequest } from './.model';
import { BackofficeService } from 'app/shared/services/api/backoffice.service';
import { ProcessCreditRequestNotification, AcceptCreditRequest, RejectCreditRequest, SetCreditRequests } from './creditRequests.actions';

@State<CreditRequestsStateModel>({
    name: 'CreditRequests',
    defaults: {
        counter: 0,
        creditRequests: []
    }
})
export class CreditRequestsState {
    constructor(
        private _store: Store,
        private _backofficeService: BackofficeService
    ) {}
        
    @Selector()
    static getTotalCreditRequests(state: CreditRequestsStateModel){
        return state.counter;
    }

    @Selector()
    static getCreditRequests(state: CreditRequestsStateModel){
        return state.creditRequests;
    }

    @Action(ProcessCreditRequestNotification)
    ProcessCreditRequestNotification(
        { patchState, getState }: StateContext<CreditRequestsStateModel>,
        { payload }: ProcessCreditRequestNotification
    ) { 
        let notification:CreditRequest = payload;
        
        let list = JSON.parse(JSON.stringify(getState().creditRequests));
        
        let listIndex = list.findIndex(l => l.id == notification.id);

        if(listIndex == -1)
            list.push(notification);

        patchState({
            'counter': list.length,
            'creditRequests': list
        });
    }

    @Action(SetCreditRequests)
    SetCreditRequests(
        { patchState, getState }: StateContext<CreditRequestsStateModel>,
        { payload } : SetCreditRequests
    ){
        if(payload.counter){
            patchState({
                'counter': payload.counter
            });
        }else{
            this._backofficeService.getCreditRequests()
            .subscribe((response) =>{
                patchState({
                    'counter': response.length,
                    'creditRequests': response
                });
            });
        }
    }

    @Action(AcceptCreditRequest)
    ApproceCreditRequest(
        { patchState, getState }: StateContext<CreditRequestsStateModel>,
        { payload }: AcceptCreditRequest
    ) { 
        let list = JSON.parse(JSON.stringify(getState().creditRequests));
        
        let listIndex = list.findIndex(l => l.id == payload.id);
        
        if(listIndex != -1)
            list.splice(listIndex, 1);

        patchState({
            'counter': list.length,
            'creditRequests': list
        });
    }

    @Action(RejectCreditRequest)
    RejectCreditRequest(
        { patchState, getState }: StateContext<CreditRequestsStateModel>,
        { payload }: RejectCreditRequest
    ) { 
        let list = JSON.parse(JSON.stringify(getState().creditRequests));
        
        let listIndex = list.findIndex(l => l.id == payload.id);

        if(listIndex != -1)
            list.splice(listIndex, 1);

        patchState({
            'counter': list.length,
            'creditRequests': list
        });
    }
}