export const RejectionReason = [
    { "id": 1, "label":"Cliente recusou troca de medicamento" },
    { "id": 2, "label":"Cliente recusou troca de quantidade" },
    { "id": 3, "label":"Cliente recusou troca de preço" },
    { "id": 4, "label":"Cliente recusou troca de farmácia" },
    { "id": 5, "label":"Receita inválida para entregador" },
    { "id": 6, "label":"Receita inválida na farmácia" },
    { "id": 7, "label":"Receita não disponibilizada para entregador" },
    { "id": 8, "label":"Cliente não localizado para atendimento" },
    { "id": 9, "label":"Cliente não localizado para entrega" },
    { "id": 10, "label":"Pagamento na entrega não realizado" },
    { "id": 12, "label":"Cliente solicitou cancelamento por atraso" },
    { "id": 13, "label":"Cliente desistiu do pedido" },
    { "id": 14, "label":"Cliente solicitou cancelamento por outro motivo" },
    { "id": 15, "label":"Nenhum entregador disponível" },
    { "id": 16, "label":"Nenhuma farmácia disponível (Problema Técnico)" },
    { "id": 17, "label":"Nenhuma farmácia disponível" },
    { "id": 20, "label":"Furto do Pedido" },
    { "id": 21, "label":"Furto da Bicicleta" },
    { "id": 22, "label":"Acidente de Trabalho" },
    { "id": 23, "label":"Problema na Bicicleta" }
];