import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AuthState } from 'app/store/auth/auth.state';
import { ActiveAppsState } from 'app/store/activeApps/activeApps.state';
import { OrdersState } from 'app/store/orders/orders.state';
import { ChatsState } from 'app/store/chats/chats.state';
import { CreditRequestsState } from 'app/store/creditRequests/creditRequests.state';
import { ROLES } from 'app/store/auth/roles';
import { SessionService } from 'app/shared/session';

@Component({
  selector: 'custom-header',
  templateUrl: './custom-header.component.html',
  styleUrls: ['./custom-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CustomHeaderComponent implements OnInit {
  readonly ROLES = ROLES;
  
  public offlineCount$: Observable<number>;
  public offlineCounter: number;
  public ordersCount$: Observable<number>;
  public ordersCounter: number;
  public chatsCount$: Observable<number>;
  public chatsCounter: number;
  public creditRequestsCount$: Observable<number>;
  public creditRequestsCounter: number;

  public url:string = "";
  
  public role:number;
  public role$: Observable<number>;
  public roleSubscription$: Subscription;

  constructor(
    private _store: Store,
    private _router: Router,
    private _sessionService: SessionService
  ) { }

  async ngOnInit() {
    this.offlineCount$ = this._store.select(ActiveAppsState.offlineCount);
    this.offlineCount$.subscribe((response) => { 
      this.offlineCounter = response;
    });

    this.ordersCount$ = this._store.select(OrdersState.getTotalOrdersByStatus);
    this.ordersCount$.subscribe((response) => {
      this.ordersCounter = response;
    });

    this.chatsCount$ = this._store.select(ChatsState.getTotalChats);
    this.chatsCount$.subscribe((response) => {
      this.chatsCounter = response;
    });

    this.creditRequestsCount$ = this._store.select(CreditRequestsState.getTotalCreditRequests);
    this.creditRequestsCount$.subscribe((response) => {
      this.creditRequestsCounter = response;
    });

    //

    this._router.events.subscribe((event) => {
      if(event instanceof NavigationEnd)
        this.url = this._router.url;
    });

    this.role$ = this._store.select(AuthState.role);
    this.roleSubscription$ = this.role$.subscribe((response) => {
      this.role = response;
    });
  }

  logout(){    
    this._sessionService.logout();
  }
}
