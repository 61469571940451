import { Component, ViewChild, ElementRef, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { Chat,SelectedChat } from 'app/store/chats/.model';
import { ChatsState } from 'app/store/chats/chats.state';
import { SetChat } from 'app/store/chats/chats.actions';
import { AuthState } from 'app/store/auth/auth.state';
import { ROLES } from 'app/store/auth/roles';
import { BackofficeService } from 'app/shared/services/api/backoffice.service';

@Component({
  selector: 'chat-list',
  templateUrl: 'chat-list.component.html',
  styleUrls: ['chat-list.component.scss'],
})
export class ChatListComponent implements OnInit, OnDestroy {
  readonly ROLES = ROLES;

  @Input() public entries: Chat[]; 
  @Input() public queue: any[];
  @Output() public onSelectChat:EventEmitter<SelectedChat> = new EventEmitter<SelectedChat>();

  public chat: any;
  public chat$: Observable<any>;
  public chatSubscription$: Subscription;
  public selectedChat:Chat = undefined;

  public selectedTab:string = "chats";
  
  public role:number;
  public role$: Observable<number>;
  public roleSubscription$: Subscription;

  private _destroyComponent$ = new Subject();

  constructor(
    private _store: Store,
    private _backofficeService: BackofficeService,
  ) {}

  ngOnInit() {
    this.chat$ = this._store.select(ChatsState.getChat);
    this.chatSubscription$ = this.chat$.subscribe((response) => {
      this.chat = response;
      this.selectedChat = response;
    });
    this.role$ = this._store.select(AuthState.role);
    this.roleSubscription$ = this.role$.subscribe((response) => {
      this.role = response;
    });
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public getUnread(entry:Chat){
    return entry.badge > 0? entry.badge : typeof entry.messages === 'number'? entry.messages : entry.messages.filter(m => m.unread).length;
  }

  public select(entry:Chat, parent){
    this._store.dispatch(
      new SetChat({ phoneNumber: entry.contactPhoneNumber, parent: parent, role: this.role })
    );
    this.selectedChat = entry;
  }

  public changeTab(tab){
    this.selectedTab = tab;
    if(tab == "queue"){
      console.log(this.queue);
    }
  }
}
