import { User, UserRoleList } from './.model';

export class ListUsers {
    static readonly type = '[Users] ListUsers';
    constructor(public payload: { roleId: number}) {}
}

export class CreateUser {
    static readonly type = '[User] CreateUser';
    constructor(public payload: User) {}
}

export class UpdateUser {
    static readonly type = '[User] UpdateUser';
    constructor(public payload: User) {}
}

export class DeleteUser {
    static readonly type = '[User] DeleteUser';
    constructor(public payload: User) {}
}

export class SelectUser {
    static readonly type = '[User] SelectUser';
    constructor(public payload: number) {}
}

export class ClearSelectedUser {
    static readonly type = '[User] ClearSelectedUser';
    constructor() {}
}

export class GetUserDetails {
    static readonly type = '[User] GetUserDetails';
    constructor(public payload: number) {}
}

export class GetUserHistory {
    static readonly type = '[User] GetUserHistory';
    constructor(public payload: number) {}
}

export class ListWorkingAreas {
    static readonly type = '[Users] ListWorkingAreas';
    constructor() {}
}