import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class ActiveAppsService {

  private _baseUrl: string;
  private _hubUrl: string;

  constructor(private _apiService: ApiService, private http: HttpClient) {
    this._baseUrl = environment.API_URL;
    this._hubUrl = environment.NOTIFICATIONS_URL;
  }
  
  public getUsingApps() {
    return this._apiService.get(this._baseUrl + '/drugstore/using-store-app');
  }

  public getOnline() {
    return this._apiService.get(this._hubUrl + '/api/online');
  }
}
