import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";
import { environment } from "../../../../environments/environment";
import { map } from "rxjs/operators";
import * as jwt_decode from "jwt-decode";
import { Observable } from "rxjs";
import { CreateBaseFeeRequest, CreateDynamicFeeRequest, ListBaseFeesResponse, ListDynamicFeesResponse } from "app/store/fees/.model";

@Injectable()
export class BackofficeService {
  private _baseUrl: string;

  constructor(private _apiService: ApiService, private http: HttpClient) {
    this._baseUrl = environment.API_URL;
  }

  public connect() {
    return this._apiService.post(this._baseUrl + "/connect", {});
  }

  public disconnect() {
    return this._apiService.post(this._baseUrl + "/disconnect", {});
  }

  public getChatHistory(phoneNumber, page) {
    let url = this._baseUrl + "/chats/" + phoneNumber;

    if (page) url += "?page=" + page;

    return this._apiService.get(url);
  }

  public markChatAsRead(phoneNumber) {
    console.log(this._baseUrl + "/chats/" + phoneNumber + "/markasread");
    return this._apiService.post(this._baseUrl + "/chats/" + phoneNumber + "/markasread", {});
  }

  public sendChatMessage(contactUid, content, parent) {
    return this._apiService.post(this._baseUrl + "/chats", { contactUid: contactUid, parent: parent, body: content });
  }

  public closeChat(phoneNumber) {
    return this._apiService.post(this._baseUrl + "/chats/" + phoneNumber + "/close", {});
  }

  public getCustomerOrderDetails(id) {
    return this._apiService.get(this._baseUrl + "/customers/" + id + "/orders");
  }
  public getDeliveryOrderDetails(id) {
    return this._apiService.get(this._baseUrl + "/users/" + id + "/deliveries");
  }

  public listWorkingAreas() {
    return this._apiService.get(this._baseUrl + "/workingarea/get-all");
  }

  public listUsers(role) {
    return this._apiService.get(this._baseUrl + "/users/", { roleId: role, pageSize: 100 });
  }

  public createUser(newUser) {
    return this._apiService.post(this._baseUrl + "/users", newUser);
  }

  public updateUser(updatedUser) {
    return this._apiService.put(this._baseUrl + "/users/" + updatedUser.id, updatedUser);
  }

  public deleteUser(userId) {
    return this._apiService.delete(this._baseUrl + "/users/" + userId);
  }

  public getUserDetails(id) {
    return this._apiService.get(this._baseUrl + "/users/" + id);
  }

  public getUserDeliveryHistory(id) {
    return this._apiService.get(this._baseUrl + "/users/" + id + "/deliveries/history");
  }

  public getUserHelpDeskHistory(id) {
    return this._apiService.get(this._baseUrl + "/users/" + id + "/helpdesks/history");
  }

  public getRoles() {
    return this._apiService.get(this._baseUrl + "/config/roles");
  }

  public getHelpDeskLevels() {
    return this._apiService.get(this._baseUrl + "/config/helpdesklevels");
  }

  public getSession() {
    return this._apiService.get(this._baseUrl + "/session");
  }

  public getMapData() {
    return this._apiService.get(this._baseUrl + "/map");
  }

  public getOrderMapData(id) {
    return this._apiService.get(this._baseUrl + "/map/order/" + id);
  }

  public getCreditRequests() {
    return this._apiService.get(this._baseUrl + "/creditRequests");
  }

  public getOrder(id) {
    return this._apiService.get(this._baseUrl + "/orders/" + id);
  }

  public getHelpDeskQueue() {
    return this._apiService.get(this._baseUrl + "/helpdesks/queue");
  }

  public acceptCreditRequest(id) {
    return this._apiService.post(this._baseUrl + "/creditRequests/" + id + "/accept", {});
  }

  public rejectCreditRequest(id) {
    return this._apiService.post(this._baseUrl + "/creditRequests/" + id + "/reject", {});
  }
}
