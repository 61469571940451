import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetProfile, LoadProfile, Logout } from './auth.actions';
import { AuthStateModel } from './.model';

@State<AuthStateModel>({
  name: 'auth'
})
export class AuthState {

  constructor() { }

  @Selector()
  static token(state: AuthStateModel): string {
    return state.token;
  }

  @Selector()
  static userId(state: AuthStateModel): string {
    return state.id;
  }

  @Selector()
  static username(state: AuthStateModel): string {
    return state.username;
  }

  @Selector()
  static role(state: AuthStateModel) {
    switch(state.role)
    {
      case "gerente":
        return 1;
      case "supervisor":
        return 2;
      case "operador":
        return 3;
      case "entregador":
        return 4;
      default:
        return -1;
    }
  }

  @Action(Logout)
  Logout(
    { patchState }: StateContext<AuthStateModel>
  ) {
    localStorage.removeItem('FarmAPPBackOffice_LoggedUser');

    patchState({
      id: undefined,
      token: undefined,
      username: undefined,
      email: undefined,
      role: undefined
    });
  }

  @Action(SetProfile)
  SetProfile(
    { patchState }: StateContext<AuthStateModel>,
    { payload }: SetProfile
  ) {
    localStorage.setItem('FarmAPPBackOffice_LoggedUser', JSON.stringify(payload));

    patchState({
      ...payload
    });
  }
  
  @Action(LoadProfile)
  LoadProfile(
    { patchState }: StateContext<AuthStateModel>
  ) {
    /*
    let user = localStorage.getItem('FarmAPPBackOffice_LoggedUser');

    if(user && user != ""){
      let parsedUser = JSON.parse(user);

      patchState({
        ...parsedUser
      });
    }
    */
  }
}
