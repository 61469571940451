import { NgModule } from '@angular/core';
import { AlertComponent } from './alert.component';
import { MatDialogModule, MatIconModule, MatButtonModule } from '@angular/material';

@NgModule({
    declarations: [
        AlertComponent
    ],
    entryComponents: [
        AlertComponent
    ],
    imports: [
        MatDialogModule,
        MatIconModule,
        MatButtonModule
    ]
})

export class AlertModule { }

