import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CustomHeaderComponent } from './custom-header.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { SignalrService } from 'app/shared/services/signalr.service';

@NgModule({
    declarations: [
        CustomHeaderComponent
    ],
    entryComponents: [
        CustomHeaderComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        SharedModule
    ],
    providers: [
        SignalrService
    ],
    exports: [
        CustomHeaderComponent
    ]
})

export class CustomHeaderModule { }

