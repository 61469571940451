import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "credit-card-data",
  templateUrl: "credit-card-data.component.html",
  encapsulation: ViewEncapsulation.None,
})
export class CreditCardDataComponent implements OnInit {
  @Input() creditCardFormControl: FormControl;
  @Input() label: string;

  constructor() {}

  ngOnInit() {}
}
