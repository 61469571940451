import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { DateAdapter, MatCheckbox, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MY_DATE_FORMATS } from "app/shared/constants";
import { Observable } from "rxjs";
import { debounceTime, filter, map, startWith } from "rxjs/operators";

interface ZipCodeAddress {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  gia: string;
  ibge: string;
  localidade: string;
  logradouro: string;
  siafi: string;
  uf: string;
}

type ZipCodeError = { erro: string };

type ZipCodeResponse = ZipCodeAddress | ZipCodeError;


@Component({
  selector: "personal-data",
  templateUrl: "personal-data.component.html",
  styleUrls: ["personal-data.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
})
export class PersonaldDataComponent implements OnInit, AfterViewInit {
  @ViewChild("noComplement", { static: false }) noComplementCheckbox: MatCheckbox;

  @Input() personalDataForm: FormGroup;
  @Input() label: string;

  public ufs = [
    { value: "RO", label: "RO" },
    { value: "AC", label: "AC" },
    { value: "AM", label: "AM" },
    { value: "RR", label: "RR" },
    { value: "PA", label: "PA" },
    { value: "AP", label: "AP" },
    { value: "TO", label: "TO" },
    { value: "MA", label: "MA" },
    { value: "PI", label: "PI" },
    { value: "CE", label: "CE" },
    { value: "RN", label: "RN" },
    { value: "PB", label: "PB" },
    { value: "PE", label: "PE" },
    { value: "AL", label: "AL" },
    { value: "SE", label: "SE" },
    { value: "BA", label: "BA" },
    { value: "MG", label: "MG" },
    { value: "ES", label: "ES" },
    { value: "RJ", label: "RJ" },
    { value: "SP", label: "SP" },
    { value: "PR", label: "PR" },
    { value: "SC", label: "SC" },
    { value: "RS", label: "RS" },
    { value: "MS", label: "MS" },
    { value: "MT", label: "MT" },
    { value: "GO", label: "GO" },
    { value: "DF", label: "DF" },
  ];
  public filteredUfs: Observable<{ value: string; label: string }[]>;

  get controls() {
    return this.personalDataForm.controls;
  }

  public previousComplement: string = "";

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.filteredUfs = this.controls.state.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );

    this.initZipCodeSubscription();
  }

  ngAfterViewInit(): void {
    if (!this.controls.complement.value) {
      this.controls.complement.disable();
      this.controls.complement.setValidators(null);
      this.controls.complement.updateValueAndValidity();
      this.noComplementCheckbox.checked = true;
    }
  }

  toggleComplement() {
    const complement = this.controls.complement;

    if (complement.enabled) {
      this.previousComplement = complement.value;
      complement.disable();
      complement.setValue("");
      complement.setValidators(null);
      complement.updateValueAndValidity();
    } else {
      complement.enable();
      complement.setValue(this.previousComplement);
      complement.setValidators([Validators.required]);
      complement.updateValueAndValidity();
    }
  }

  private _filter(value: string): { value: string; label: string }[] {
    const filterValue = value.toLowerCase();

    return this.ufs.filter((uf: { value: string; label: string }) => uf.label.toLowerCase().includes(filterValue));
  }

  private initZipCodeSubscription() {
    this.personalDataForm.controls.zipCode.valueChanges
      .pipe(
        debounceTime(1000),
        map((search) => search.replace(/[^\d]/g, "")),
        filter((search) => search.length >= 8)
      )
      .subscribe(async (zipCode) => {
        const zipCodeResponse = await this.getAddressByZipCode(zipCode);
        if (!(zipCodeResponse as ZipCodeError).erro) {
          const address = zipCodeResponse as ZipCodeAddress;

          this.personalDataForm.patchValue({
            address: address.logradouro,
            neighborhood: address.bairro,
            city: address.localidade,
            state: address.uf,
          });
        }
      });
  }

  private async getAddressByZipCode(zipCode: string): Promise<ZipCodeResponse> {
    return this.http.get<ZipCodeResponse>(`https://viacep.com.br/ws/${zipCode}/json`).toPromise();
  }
}
