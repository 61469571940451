import { Component, ViewEncapsulation, ViewChild, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { Subject, Observable, Subscription } from 'rxjs';
import { AnimationController, IonSlides } from '@ionic/angular';
import { Order } from 'app/store/orders/.model';
import { ChatsState } from 'app/store/chats/chats.state';
import { SetChat, NewChat } from 'app/store/chats/chats.actions';
import { AuthState } from 'app/store/auth/auth.state';

@Component({
  selector: 'tab-costumer-details',
  templateUrl: './costumer-details.component.html',
  styleUrls: ['./costumer-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CostumerDetailsComponent implements OnInit {
  @Input() public startOnTab: string; 
  @Input() public order: Order;
  @Output() public onCloseDialog:EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild("slides", {static: false}) slides: IonSlides;

  public tab = "details";
  public chat: any;
  public chats: any[] = [];
  public chats$: Observable<any[]>;
  public chatsSubscription$: Subscription;
  
  private role:number;
  private role$: Observable<number>;
  private roleSubscription$: Subscription;

  constructor(
    private _store: Store,
    private _router: Router
  ) { }

  ngOnInit(){    
    this.chats$ = this._store.select(ChatsState.getChats);
    this.chatsSubscription$ = this.chats$.subscribe((response) => {
      if(response){
        this.chats = response;
        this.chat = response.find(c => c.contactPhoneNumber == this.order.user.phoneNumber);
      }        
    });
    
    this.role$ = this._store.select(AuthState.role);
    this.roleSubscription$ = this.role$.subscribe((response) => {
        this.role = response;
    });
    
  }

  public sendMessage(){    
    if(this.chat){
      this._store.dispatch(
        new SetChat({ phoneNumber: this.order.user.phoneNumber, parent: undefined, role: this.role})
      );
    }else{
      this._store.dispatch(
        new NewChat({
          contactId: this.order.user.id,
          contactUid: this.order.user.uid,        
          contactName: this.order.user.name,
          contactPhoneNumber: this.order.user.phoneNumber,
          contactType: 0,
          parent: "",
          children: []
        })
      );
    }

    setTimeout(() => {
      this.onCloseDialog.emit([]);
      this._router.navigate(['chats']);
    }, 250);
  }

  /*
  public segmentChanged(event){
    this.showTab(event.detail.value);
  }

  public showTab(tab) {
    this.tab = tab;
    switch(tab){
      case 'details':
        this.slides.slideTo(0);
        break;
      case 'whatsapp':
        this.slides.slideTo(1);
        break;
      case 'sms':
        this.slides.slideTo(2);
        break;
      case 'phonecall':
        this.slides.slideTo(3);
        break;
      default:
        break;
    }
  }
  */
}
