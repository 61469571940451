import { Injectable } from '@angular/core';  
import { Subject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { Router, NavigationEnd } from '@angular/router';
import { SignalrService } from './services/signalr.service';
import { ProcessNewOrderNotifications, ProcessNotifications, SetOrdersByStatus, SetOrders, ClearOrders } from '../store/orders/orders.actions';
import { ClearChats, ProcessChatMessageNotification, SetChats } from '../store/chats/chats.actions';
import { ClearAppsCounter, ProcessAppStatusNotification, SetUsingApps } from '../store/activeApps/activeApps.actions';
import { ProcessCreditRequestNotification, SetCreditRequests } from '../store/creditRequests/creditRequests.actions';
import { ProcessDeliveryMapNewOrderNotification, ProcessDeliveryMapUpdateNotification } from '../store/deliveryMap/deliveryMap.actions';
import { BackofficeService } from 'app/shared/services/api/backoffice.service';
import { orderStatus } from 'app/store/orders/orderStatus';
import { Logout } from 'app/store/auth/auth.actions';
import { AuthState } from 'app/store/auth/auth.state';
import { ROLES } from 'app/store/auth/roles';

@Injectable()  
export class SessionService {    
    private connectionSubscription: Subscription;
    private appStatusUpdatedSubscription: Subscription;
    private newOrderSubscription: Subscription;
    private orderUpdatedSubscription: Subscription;    
    private incomingChatMessageSubscription: Subscription;
    private creditRequestSubscription: Subscription;
    private deliveryMapUpdateSubscription: Subscription;

    private role:number;
    private role$: Observable<number>;
    private roleSubscription$: Subscription;

  constructor(
    private _store: Store,
    private _signalrService: SignalrService,
    private _backofficeService: BackofficeService,
    private _router: Router,
  ) { 

  }  

  public load(token){
    return new Promise((resolve, reject) => {
        this.connectionSubscription = this._signalrService.connectionEstablished.subscribe(() => {
            this.configOnConnect(token)
                .then((response) => {
                    resolve(true);
                }, (error) => {
                    reject(error);
                });
        });

        this.appStatusUpdatedSubscription = this._signalrService.appStatusUpdated.subscribe((message: any) => {
            setTimeout(() => {
                this._store.dispatch(
                    new ProcessAppStatusNotification(message)
                );
            }, 750);
        });    

        this.creditRequestSubscription = this._signalrService.creditRequest.subscribe((message: any) => {
            setTimeout(() => {
                this._store.dispatch(
                    new ProcessCreditRequestNotification(JSON.parse(message))
                );
            }, 750);
        });    
        
        this.newOrderSubscription = this._signalrService.newOrder.subscribe((message: any) => {
            setTimeout(() => {
                this._store.dispatch(
                    new ProcessNewOrderNotifications({notifications: message})
                );
            }, 750);
        });
    
        this.orderUpdatedSubscription = this._signalrService.orderUpdated.subscribe((message: any) =>{
            setTimeout(() => {
                this._store.dispatch(
                    new ProcessNotifications({notifications: message})
                );
                this._store.dispatch(
                    new ProcessDeliveryMapNewOrderNotification({notifications: message})
                );
            }, 750);
        });
    
        this.incomingChatMessageSubscription = this._signalrService.incomingChatMessage.subscribe((message: any) => {
            setTimeout(() => {
                this._store.dispatch(
                    new ProcessChatMessageNotification({notifications: message})
                );
            }, 750);
        });

        this.deliveryMapUpdateSubscription = this._signalrService.deliveryMapUpdate.subscribe((message: any) => {
            setTimeout(() => {
                this._store.dispatch(
                    new ProcessDeliveryMapUpdateNotification({notification: message})
                );
            }, 750);
        });

        this.connect(token);
    });    
  }

  private connect(token){
      this._signalrService.startService(token)
        .catch(() => {
            setTimeout(() => {
                this.connect(token);
            }, 5000);
        });
  }

  private configOnConnect(token){
    return new Promise(async (resolve, reject) => {        
        this.role$ = this._store.select(AuthState.role);
        this.roleSubscription$ = this.role$.subscribe((response) => {
            this.role = response;
        });
        
        let session = await this._backofficeService
            .getSession()
            .toPromise()
            .catch(e => reject(e));
        
        let online = await this._signalrService
            .getOnline()
            .toPromise()
            .catch(e => reject(e));
        
        //CONFIGURATION
        //ROLES
        //CANCELATIONREASONS
        //HELPDESKLEVELS
        
        //COUNTERS
        this._store.dispatch(
            new SetUsingApps({usingApps: session.drugstores, onlineApps: online.drugstores})
        );    

        this._store.dispatch(
            new SetChats(session.chats)
        );

        this._store.dispatch(
            new SetOrdersByStatus(session.orders)
        );
    
        this._store.dispatch(
            new SetOrders({status: orderStatus.AAF.value, orders: []})
        );
        
        if(this.role == ROLES.GERENTE){
            this._store.dispatch(
                new SetCreditRequests({counter: session.creditRequests})
            );
        }
        
        setTimeout(() => {
            resolve(true);
        }, 250);
    });
  }

  public logout(){
    this._router.navigate(['login']);

    setTimeout(() => {
        this._signalrService.stopService();
        
        this.connectionSubscription.unsubscribe();
        this.appStatusUpdatedSubscription.unsubscribe();
        this.newOrderSubscription.unsubscribe();
        this.orderUpdatedSubscription.unsubscribe();
        this.incomingChatMessageSubscription.unsubscribe();
        this.creditRequestSubscription.unsubscribe();
        this.deliveryMapUpdateSubscription.unsubscribe();

        this._store.dispatch(
            new ClearOrders()
        );
        this._store.dispatch(
            new ClearChats()
        );
        this._store.dispatch(
            new ClearAppsCounter()
        );
        this._store.dispatch(
            new Logout()
        );
    }, 100);
  }
}    