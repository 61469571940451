import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngxs/store";
import { ListWorkingAreas } from "app/store/users/users.actions";
import { UsersState } from "app/store/users/users.state";

export interface WorkingArea {
  id: number;
  name: string;
}

type WorkingAreaOption = WorkingArea & { checked?: boolean };

@Component({
  selector: "delivery-data",
  templateUrl: "delivery-data.component.html",
  styleUrls: ["delivery-data.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryDataComponent implements OnInit {
  @Input() deliveryDataForm: FormGroup;
  @Input() label: string;

  public workingAreas: WorkingAreaOption[] = [];

  get controls() {
    return this.deliveryDataForm.controls;
  }

  constructor(private _store: Store) {
    this._store.select(UsersState.getWorkingAreas).subscribe((areas) => (this.workingAreas = areas.map((a) => ({ ...a, checked: false }))));
  }

  updateAreas() {
    const selectedAreasIds = this.workingAreas.filter((wa) => wa.checked).map((wa) => wa.id);
    this.deliveryDataForm.get("workingAreaId").setValue(selectedAreasIds);
  }

  ngOnInit() {
    this._store.dispatch(new ListWorkingAreas());
  }
}
