import {orderStatus} from './orderStatus';

export function getInitialState() {
    let initial : OrdersStateModel = {
        selectedOrder: undefined,
        ordersSearchResult: [],
        ordersByStatus: [],
        counterByStatus: [],
        totalByStatus: 0
    };

    Object.keys(orderStatus).forEach(function(key){
        let value = orderStatus[key].value;
        
        initial.counterByStatus.push({ id: value, key: key, count: 0});

        initial.ordersByStatus.push({
            label: key,
            status: value,
            loadedAt: undefined,
            orders: []
        });
    });

    return initial;
}

export class OrdersStateModel {
    selectedOrder: Order;
    ordersSearchResult: OrderSearchEntry[];
    ordersByStatus: StatusList[];
    counterByStatus: StatusCounter[];
    totalByStatus: number;
}

export class StatusCounter {
    id: number;
    key: string;
    count: number;
}

export class StatusList {
    loadedAt: Date;
    status: number;
    label: string;
    orders: Order[];
}

export class Order {
    id: number;
    shoppingCartId: number;
    drugstoreId: number;
    drugstore: any;
    delivery: any;
    user: any;
    loaded: boolean;
    createdAt: Date;
    status: number;
    updatedAt?: Date;
    deliveryFee: number;
    convenienceFee: number;
    newConvenienceFee: number;
    subTotal: number;
    newSubTotal: number;
    totalPrice: number;
    totalItems: number;
    total: number;
    newTotal: number;
    problemDescription: string;
    address: OrderAddress;
    items: OrderItem[];
}

export class OrderAddress {
    latitude: number;
    longitude: number;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    zipcode: string;
}

export class OrderItem {
    productId: number;
    product: string;
    quantity: number;
    stock: number;
    name: string;
    price: number;
    discountPrice: number;
    removed: boolean;
    updateRequest: OrderItemUpdateRequest;
    history: OrderItemHistory;
}

export class OrderItemUpdateRequest {
    productId: number;
    quantity: number;
    price: number;
    discountPrice: number;
    delete: boolean;
}

export class OrderItemHistory {
    productId: number;
    quantity: number;
    price: number;
    discountPrice: number;
    delete: boolean;
}

export class OrderRejection {
    id: number;
    items: OrderRejectionItem[]
}

export class OrderRejectionItem {
    productId: number;
    quantity: number;
}

export class OrderSearch {
    id: number;
    status: number;
    userName: string;
    userCpf: string;
    userPhone: string;
}

export class OrderSearchEntry {
    id: number;
    statusId: number;
    status: string;
    user: string;
    createdAt: string;
    total: number;
}