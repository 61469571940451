import { NgModule } from '@angular/core';
import { MatDialogModule, MatIconModule, MatButtonModule } from '@angular/material';
import { ConfirmDialogComponent } from './confirm.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        ConfirmDialogComponent
    ],
    entryComponents: [
        ConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule
    ]
})

export class ConfirmModule { }

