import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { Order } from 'app/store/orders/.model';
import { OrdersService } from 'app/shared/services/api/orders.service';
import { CancelOrder } from 'app/store/orders/orders.actions';
import { ConfirmDialogComponent } from 'app/layout/components/confirm/confirm.component';
import { RejectionReason } from 'app/store/orders/orderRejectionReason';

@Component({
  selector: 'tab-cancel-details',
  templateUrl: './cancel-details.component.html',
  styleUrls: ['./cancel-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CancelDetailsComponent {
  @Input() public order: Order;
  @Output() public onCloseDialog:EventEmitter<any> = new EventEmitter<any>();

  public loading:boolean = false;
  public reasons:any[] = RejectionReason;
  public reason:number = 0;
  public details:string = "";
  public numberRequired:boolean = false;

  constructor(
    private _store: Store,
    private _ordersService: OrdersService, 
    private _dialog: MatDialog
  ) {
  }

  cancelOrder() {
    this.numberRequired = false;

    if(this.reason == 21 && this.details == "") {
      this.numberRequired = true;
    }else{
      this.loading = true;
      
      this._ordersService.cancelOrder(this.order.id, this.reason / 1, this.details)
        .subscribe((response) => {
          this._store.dispatch(
            new CancelOrder({ id: this.order.id, status: this.order.status, details: this.details })
          );
          this.loading = false;
          this.onCloseDialog.emit([]);
          this._dialog.open(ConfirmDialogComponent, { data: { title: "Pedido Cancelado", text: "O pedido foi cancelado com sucesso.", close: true }});
        } ,(error) => {
          console.log(error);
          this.loading = false;
          this._dialog.open(ConfirmDialogComponent, { data: { title: "Erro", text: "Houve um problema cancelando o pedido.", close: true }});
        });
    }    
  }
}
