import { Component, ViewEncapsulation, Inject, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, Observable, Subscription } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrdersState } from 'app/store/orders/orders.state';
import { Order } from 'app/store/orders/.model';
import { orderStatus, drugstoreAvailable, deliveryAvailable, cancelUnavailable } from 'app/store/orders/orderStatus';
import { ClearSelectedOrder } from 'app/store/orders/orders.actions';

@Component({
  selector: 'dialog-order-entry-detail',
  templateUrl: './order-entry-detail.component.html',
  styleUrls: ['./order-entry-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OrderEntryDetailDialogComponent implements OnInit, OnDestroy {
  public order:Order = undefined;
  public menuSelected = "order";
  public startOnTab = "";
  public loading = false;
  public largeModal = false;
  
  public orderSubscription$: Subscription;

  private _destroyComponent$ = new Subject();

  constructor(
    private _store: Store,
    public dialogRef: MatDialogRef<OrderEntryDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { area: string, tab: string }
  ) { }

  ngOnInit(){
    this.loading = true;

    this.orderSubscription$ = this._store.select(OrdersState.getSelectedOrder)
      .subscribe((response) => {
        if(response)
          this.order = response;
          
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      });
    
    this.changeMenu(this.data.area);
    
    if(this.data.tab)
      this.startOnTab = this.data.tab;
  }

  ngOnDestroy() {
    this._store.dispatch(new ClearSelectedOrder());

    if(this.orderSubscription$)
      this.orderSubscription$.unsubscribe();

    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public close() {
    this.dialogRef.close();
  }

  public changeMenu(area){
    this.largeModal = false;
    this.menuSelected = area;
    this.startOnTab = "";
  }

  showUser() {
    return true;
  }

  showDrugstore() {
    return drugstoreAvailable.includes(this.order.status);
  }

  showDelivery() {
    return deliveryAvailable.includes(this.order.status);
  }

  showSupport() {
    return this.order.status == orderStatus.PEN.value;
  }

  showActions() {
    return this.order.status == orderStatus.AAF.value;
  }

  showCancel() {
    return !cancelUnavailable.includes(this.order.status);
  }

  enlarge() {
    this.largeModal = true;
  }
  shrink() {
    this.largeModal = false;
  }
}
