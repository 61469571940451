import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { Order } from 'app/store/orders/.model';
import { OrdersService } from 'app/shared/services/api/orders.service';
import { RejectOrder } from 'app/store/orders/orders.actions';
import { ConfirmDialogComponent } from 'app/layout/components/confirm/confirm.component';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'tab-reject-details',
  templateUrl: './reject-details.component.html',
  styleUrls: ['./reject-details.component.scss'],
  encapsulation: ViewEncapsulation.None, 
  animations: [
    trigger('slideInOut', [
      state('true', style({
        overflow: 'hidden',
        height: '100px',
        width: '*'
      })),
      state('false', style({
        opacity: '0',
        overflow: 'hidden',
        height: '0px',
        width: '*'
      })),
      transition('true => false', animate('400ms ease-in-out')),
      transition('false => true', animate('400ms ease-in-out'))
    ])
  ]
})

export class RejectDetailsComponent implements OnInit {
  @Input() public order: Order;
  @Output() public onCloseDialog:EventEmitter<any> = new EventEmitter<any>();

  public rejection = {
    id: 0,
    items: [],
    otherReason: false
  };  

  public loading:boolean = false;
  public enabled:boolean = false;
  rejected: boolean = false;

  public items = [];

  constructor(
    private _store: Store,
    private _ordersService: OrdersService,
    private _dialog: MatDialog
  ) { }
  
  ngOnInit(){
    for(let i = 0; i < this.order.items.length; i++){
      let item = this.order.items[i];
      this.rejection.items.push({
        id: item.productId,
        name: item.product,
        quantity: item.quantity,
        checked: false,
        remains: 0
      });
    }
  }

  public increase(index, item) {
    if(item.remains < item.quantity - 1)
      this.rejection.items[index].remains += 1;
  }

  public decrease(index, item) {
    if(this.rejection.items[index].remains > 0)
      this.rejection.items[index].remains -= 1;
  }

  public updateEnabled(){
    let count = this.rejection.items.filter((item) => item.checked == true).length;
    
    if(count == 0 && !this.rejection.otherReason)
      this.enabled = false;
    else
      this.enabled = true;
  }

  public async  rejectOrder() {      
    this.loading = true;

    let selected = this.rejection.items
      .filter((item) => item.checked)
      .map((item) => { return { productId: item.id, quantity: item.remains }; });

    let rejectionDetails = {
      id: this.order.id,
      items: selected,
      otherReason: this.rejection.otherReason
    };

    this._ordersService.rejectOrder(rejectionDetails.id, rejectionDetails.items, rejectionDetails.otherReason)
      .subscribe((response) => {  
        this._store.dispatch(
          new RejectOrder({ orderRejection: rejectionDetails })
        ).toPromise();
        this.rejected = true;
        this.loading = false;
        this.onCloseDialog.emit([]);
        this._dialog.open(ConfirmDialogComponent, { data: { title: "Pedido Rejeitado", text: "O pedido foi rejeitado com sucesso.", close: true }});
      }, (error) => {
        this.loading = false;
        
        let message = !error.error? undefined : !error.error.errors? undefined : error.error.errors[0].message;
        
        if(!message)
          message = "Houve um problema confirmando o pedido, por favor tente novamente em alguns instantes ou  tente atualizar a página.";

          this._dialog.open(ConfirmDialogComponent, { data: { title: "Erro", text: message, close: true }});
      });
  }
}
