export const orderStatus = {
    "AAF": { value: 10, label: "Aguardando aceite da farmácia" },
    "PRE": { value: 20, label: "Pedido rejeitado" },
    "AAE": { value: 40, label: "Aguardando aceite do entregador" },
    "ETF": { value: 50, label: "Entregador em trânsito para farmácia"},
    "ETCR": { value: 60, label: "Entregador em trânsito para o cliente buscando receita" },
    "ENLR": { value: 70, label: "Entregador no local aguardando receita" },
    "ETFR": { value: 80, label: "Entregador em trânsito para farmácia com receita" },
    "EAF": { value: 90, label: "Entregador aguardando na farmácia" },
    "EMPF": { value: 91, label: "Entregador montando pacote na farmácia" },
    "EAAPF": { value: 92, label: "Entregador aguardando aprovação de mudança no pedido" },
    "EALCF": { value: 93, label: "Entregador aguardando liberação de crédito" },
    "EPPF": {value: 94, label: "Entregador pagando o pedido na farmácia" },
    "ETC": { value: 100, label: "Entregador em trânsico para o cliente" },
    "ENL": { value: 110, label: "Entregador no local da entrega" },
    "PEC": { value: 120, label: "Pedido entregue ao cliente" },
    "PEN": { value: 130, label: "Pedido pendente de captura do pagamento" },
    "PCA": { value: 140, label: "Pedido cancelado" },
    "PSU": { value: 150, label: "Pedido substituído" }
}

export const deliveryAvailable = [orderStatus.ETF.value, orderStatus.ETCR.value, orderStatus.ENLR.value, orderStatus.ETFR.value, orderStatus.EAF.value, orderStatus.EMPF.value, orderStatus.EAAPF.value, orderStatus.EALCF.value, orderStatus.EPPF.value, orderStatus.ETC.value,orderStatus.ENL.value];
export const drugstoreAvailable = [orderStatus.AAF.value, orderStatus.EAF.value, orderStatus.EMPF.value, orderStatus.EAAPF.value, orderStatus.EALCF.value, orderStatus.EPPF.value, orderStatus.PSU.value, orderStatus.PCA.value];
export const cancelUnavailable = [orderStatus.ETC.value, orderStatus.ENL.value, orderStatus.PEN.value, orderStatus.PSU.value, orderStatus.PCA.value];