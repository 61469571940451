import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '../../../store/auth/auth.state';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {
  private headers: HttpHeaders;

  constructor(
    private _http: HttpClient,
    private store: Store
  ) {}

  _doRequest(
    method: string, url: string, data?: any, headers?: HttpHeaders, responseType: 'blob' | 'json' = 'json'
  ): Observable<any> {
    const token = this.store.selectSnapshot(AuthState.token);

    this.headers = headers || new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if (!this.headers.has('Authorization')) {
      this.headers = this.headers.set('Authorization', 'Bearer ' + token);
    }

    let result;
    switch (method) {
      case 'get': {
        if (token) {
          result = this._http.get(url, { headers: this.headers, params: data });
        } else {
          result = this._http.get(url, { params: data });
        }
        break;
      }
      case 'post': {
        const options = {
          headers: this.headers,
          responseType: (responseType as 'json') || 'json',
          observe: responseType === 'blob' ? 'response' as 'body' : 'body'
        };
        result = this._http.post(url, data, options);
        break;
      }
      case 'put': {
        result = this._http.put(url, data, { headers: this.headers });
        break;
      }
      case 'delete': {
        result = this._http.delete(url, { headers: this.headers });
        break;
      }
    }

    return result;
  }

  delete(path: string, data?: any) {
    return this._doRequest('delete', path, {});
  }

  get(path: string, data?: any) {
    return this._doRequest('get', path, data);
  }

  post(path: string, data: any, headers?: HttpHeaders, responseType: 'blob' | 'json' = 'json') {
    return this._doRequest('post', path, data, headers, responseType);
  }

  put(path: string, data: any) {
    return this._doRequest('put', path, data);
  }
}
