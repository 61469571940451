import { Component, ViewEncapsulation, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'status-timer',
  template: `<div>{{hours | number: '2.'}}:{{minutes | number: '2.'}}:{{seconds | number: '2.'}}</div>`
})
export class TimerComponent implements OnInit, OnDestroy {
  public hours = 0;
  public minutes = 0;
  public seconds = 0;

  private startDate: moment.Moment;
  private subscription: Subscription;
  private everySecond: Observable<number> = timer(0, 1000);

  @Input() counterStartDate: string;

  constructor () 
  { }

  ngOnInit(): void {
    this.startDate = moment(this.counterStartDate);

    this.subscription = this.everySecond.subscribe((seconds) => {
      let currentTime: moment.Moment = moment();
      let diffTime = currentTime.diff(this.startDate) / 1000;
      
      this.hours = Math.floor(diffTime / 3600);
      diffTime %= 3600;
      this.minutes = Math.floor(diffTime / 60);
      diffTime %= 60;
      this.seconds = Math.floor(diffTime);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}