import { Component, OnInit, OnDestroy, ViewEncapsulation,ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material';
import { Subject, Observable, Subscription } from 'rxjs';
import { GetMapData, SelectDeliveryPosition } from 'app/store/deliveryMap/deliveryMap.actions';
import { DeliveryMapState } from 'app/store/deliveryMap/deliveryMap.state';
import { OrderEntryDetailDialogComponent } from 'app/layout/components/order-entry-detail/order-entry-detail.component';
import { SearchOrders, SelectOrder, SetOrders } from 'app/store/orders/orders.actions';
import { GoogleMapsAPIWrapper, AgmMap, LatLngBounds, LatLngBoundsLiteral} from '@agm/core';
import { BackofficeService } from 'app/shared/services/api/backoffice.service';

@Component({
  selector: 'app-map',
  templateUrl: 'map.page.html',
  styleUrls: ['map.page.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapPage implements OnDestroy, OnInit {
  public deliveryPositions: any[] = [];
  public deliveryPositions$: Observable<any[]>;
  public deliveryPositionsSubscription$: Subscription;

  public orderPositions: any[] = [];
  public orderPositions$: Observable<any[]>;
  public orderPositionsSubscription$: Subscription;

  public selectedPosition: any;
  public selectedPosition$: Observable<any>;
  public selectedPositionSubscription$: Subscription;

  @ViewChild('AgmMap', {static: false}) agmMap: AgmMap;

  public orderMarker: any;

  public simpleMapStyles = [
    {
      featureType: "poi.business",
      stylers: [{ visibility: "off" }],
    }
  ];

  private _destroyComponent$ = new Subject();
  
  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _backofficeService: BackofficeService
  ) {
    
  }

  ngOnInit(){
    this.deliveryPositions$ = this._store.select(DeliveryMapState.getDeliveryPositions);        
    this.deliveryPositionsSubscription$ = this.deliveryPositions$.subscribe((response) => {
      if(response)
        this.deliveryPositions = response;
    });

    this.orderPositions$ = this._store.select(DeliveryMapState.getOrderPositions);
    this.orderPositionsSubscription$ = this.orderPositions$.subscribe((response) => {
      if(response)
        this.orderPositions = response;
    });
    
    this.selectedPosition$ = this._store.select(DeliveryMapState.getSelected);
    this.selectedPositionSubscription$ = this.selectedPosition$.subscribe((response) => {
      if(response)
        this.selectedPosition = response;
    });

    this._store.dispatch(new GetMapData());
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public getIcon(marker){
    if(marker){
      switch(marker.status){
        case 1:
          return "./assets/marker-unavailable.png";
        case 2:
          return "./assets/marker-available.png";
        case 3:
          return "./assets/marker-ondelivery.png";
        default:
          return "";
      }
    }else{
      return "";
    }
  }

  public onMapClick(){
    //this.orderMarker = undefined;
  }

  public onDeliveryMarkerClick(marker){

  }

  public onOrderMarkerClick(marker){
    console.log(marker);
    this._store.dispatch(new SelectOrder({
      order: { 
        id: marker.id,
        shoppingCartId: undefined,
        drugstoreId:  undefined,
        drugstore: undefined,
        delivery: undefined,
        user:  undefined,
        loaded: undefined,
        createdAt: undefined,
        status: undefined,
        updatedAt: undefined,
        deliveryFee: undefined,
        convenienceFee: undefined,
        newConvenienceFee:  undefined,
        subTotal:  undefined,
        newSubTotal: undefined,
        totalPrice:  undefined,
        totalItems: undefined,
        total:  undefined,
        newTotal: undefined,
        problemDescription:  undefined,
        address:  undefined,
        items: undefined,
      }
    }));
    this._dialog.open(OrderEntryDetailDialogComponent, {data:{ area: 'order', tab: undefined }});
  }
}
