import { Component, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { AnimationController, IonSlides } from '@ionic/angular';

@Component({
  selector: 'status-item',
  templateUrl: './status-item.component.html',
  styleUrls: ['./status-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class StatusItemComponent {
  constructor(
    private store: Store
  ) { }
}
