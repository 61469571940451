import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { ChatsStateModel, NewChatEntry } from './.model';
import { ProcessChatMessageNotification, NewChat, NewChildChat, SetChat, SendMessage, CloseChat, SetChats, ClearChats } from './chats.actions';
import { BackofficeService } from 'app/shared/services/api/backoffice.service';
import { Chat, ChatMessage } from './.model';
import { ROLES } from '../auth/roles';

@State<ChatsStateModel>({
    name: 'Chats',
    defaults: {
        currentPhoneNumber: '',
        counter: 0,
        chats: [],
        currentChat: undefined,
        currentParent: undefined
    }
})
export class ChatsState {
    constructor(
        private _store: Store,
        private _backofficeService: BackofficeService
    ) {}
        
    @Selector()
    static getTotalChats(state: ChatsStateModel){
        return state.counter;
    }

    @Selector()
    static getChats(state: ChatsStateModel){
        return state.chats;
    }

    @Selector()
    static getChat(state: ChatsStateModel){
        return state.currentChat;
    }    

    private createChat(notification){
        return {
            contactUid: notification.contactUid,
            contactId: notification.contactId,
            contactName: notification.contactName,
            contactPhoneNumber: notification.contactPhoneNumber,
            contactType: notification.contactType,
            parent: notification.parent? notification.parent.contactUid : "",
            badge: 0,
            loaded: true,
            children: [],
            messages: [notification]
        }
    }

    @Action(ProcessChatMessageNotification)
    ProcessChatMessageNotification(
        { patchState, getState }: StateContext<ChatsStateModel>,
        { payload }: ProcessChatMessageNotification
    ) {        
        let state = JSON.parse(JSON.stringify(getState()));
        
        let chats:Chat[] = state.chats;
        let currentChat:Chat = state.currentChat;
        let notifications:ChatMessage[] = JSON.parse(payload.notifications);        

        let updatedState = {
            chats: chats,
            currentChat: currentChat,
            counter: chats.length
        };

        notifications.forEach((notification) => {
            let parentIndex = undefined;
            let chatIndex = undefined;
            
            notification.unread = true;
            
            if(notification.parent){
                parentIndex = chats.findIndex(c => c.contactUid == notification.parent.contactUid);

                if(parentIndex != -1){
                    chatIndex = chats[parentIndex].children.findIndex(c => c.contactUid == notification.contactUid);

                    if(chatIndex != -1){
                        chats[parentIndex].children[chatIndex].messages.push(notification);

                        if(updatedState.currentChat && updatedState.currentChat.contactUid == chats[parentIndex].children[chatIndex].contactUid){
                            chats[parentIndex].children[chatIndex].messages.forEach(m => m.unread = false);
                            updatedState.currentChat = chats[parentIndex].children[chatIndex];
                        }                            
                    }else{
                        chats[parentIndex].children.push(this.createChat(notification));
                    }                        
                }else{
                    let parent:Chat = {
                        contactUid: notification.parent.contactUid,
                        contactId: notification.parent.contactId,
                        contactName: notification.parent.contactName,
                        contactPhoneNumber: notification.parent.contactPhoneNumber,
                        contactType: 0,
                        parent: notification.parent.contactUid,
                        badge: 0,
                        loaded: true,
                        children: [this.createChat(notification)],
                        messages: []
                    };

                    chats.push(parent);
                }                
            }else{
                chatIndex = chats.findIndex(c => c.contactUid == notification.contactUid);
    
                if(chatIndex != -1){
                    chats[chatIndex].messages.push(notification);

                    if(updatedState.currentChat && updatedState.currentChat.contactUid == chats[chatIndex].contactUid){
                        chats[chatIndex].messages.forEach(m => m.unread = false);
                        updatedState.currentChat = chats[chatIndex];
                    }
                }else{
                    chats.push(this.createChat(notification));
                }       
            }
        });

        updatedState.chats = chats;
        updatedState.counter = chats.length;
        
        patchState(updatedState);
    }

    @Action(SetChats)
    SetChats(
        { patchState, getState }: StateContext<ChatsStateModel>,
        { payload }: any
    ) {
        let state = JSON.parse(JSON.stringify(getState()));

        let chats:Chat[] = state.chats;
        
        for(let i = 0; i < payload.length; i++){
            let exists = chats.find(c => c.contactId == payload[i].contactId);
            
            if(!exists){
                let chat:Chat = {
                    contactUid: payload[i].contactUid,
                    contactId: payload[i].contactId,
                    contactName: payload[i].contactName,
                    contactPhoneNumber: payload[i].contactPhoneNumber,
                    contactType: payload[i].contactType,
                    parent: payload[i].parent? payload[i].parent.contactUid : "",
                    loaded: false,
                    badge: payload[i].messages,
                    children: payload[i].children,
                    messages: []
                };
                
                if(chat.children && chat.children.length > 0)
                    chat.children.forEach(c => c.parent = chat.contactUid);
    
                chats.push(chat);
            }
        }

        patchState({
            'chats': chats,
            'counter': chats.length
        });
    }

    @Action(ClearChats)
    ClearChats(
        { patchState }: StateContext<ChatsStateModel>
    ) {
        patchState({
            'chats': [],
            'counter': 0,
            'currentChat': undefined,
            'currentParent': undefined,
            'currentPhoneNumber': undefined
        });
    }

    @Action(SetChat)
    async SetChat(
        { patchState, getState }: StateContext<ChatsStateModel>,
        { payload }: SetChat
    ) {
        let state = JSON.parse(JSON.stringify(getState()));

        let chats:Chat[] = state.chats;

        let parentIndex;
        let chatIndex;

        if(payload.parent){
            parentIndex = chats.findIndex(c => c.contactPhoneNumber == payload.parent);
            chatIndex = chats[parentIndex].children.findIndex(c => c.contactPhoneNumber == payload.phoneNumber);
        }else{
            chatIndex = chats.findIndex(c => c.contactPhoneNumber == payload.phoneNumber);
        }

        let chat = parentIndex != undefined? chats[parentIndex].children[chatIndex] : chats[chatIndex];
        
        if(chatIndex != -1){            
            if(!chat.loaded){
                let chatHistory = await this._backofficeService.getChatHistory(chat.contactPhoneNumber, null)
                    .toPromise()
                    .catch((e) => console.log(e));

                chat.loaded = true;

                if(chatHistory)
                    chat.messages = chatHistory;
                else
                    chat.messages = [];
            }

            chat.badge = 0;            
        }            

        if(parentIndex != undefined)
            chats[parentIndex].children[chatIndex] = chat;
        else
            chats[chatIndex] = chat;
        
        if(payload.role == ROLES.OPERADOR){
            if(typeof chat.messages != 'number')
                chat.messages.forEach(message => message.unread = false);

            await this._backofficeService.markChatAsRead(chat.contactPhoneNumber).toPromise();
        }            

        patchState({
            'currentPhoneNumber': payload.phoneNumber,
            'currentChat': chat,
            'chats': chats
        });
    }

    @Action(NewChat)
    NewChat(
        { patchState, getState }: StateContext<ChatsStateModel>,
        { payload }: NewChat
    ){
        let chat:Chat = {
            contactId: payload.contactId,
            contactUid: payload.contactUid,
            contactName: payload.contactName,
            contactPhoneNumber: payload.contactPhoneNumber,
            contactType: payload.contactType,
            loaded: true,
            badge: 0,
            parent: payload.parent,
            children: [],
            messages: []
        }

        patchState({
            'currentChat': chat
        });
    }

    @Action(NewChildChat)
    NewChildChat(
        { patchState, getState }: StateContext<ChatsStateModel>,
        { payload }: NewChildChat
    ){
        let childChat:Chat = {
            contactId: payload.contactId,
            contactUid: payload.contactUid,
            contactName: payload.contactName,
            contactPhoneNumber: payload.contactPhoneNumber,
            contactType: payload.contactType,
            loaded: true,
            badge: 0,
            parent: payload.parent.contactUid,
            children: [],
            messages: []
        }

        patchState({
            'currentChat': childChat,
            'currentParent': payload.parent
        });
    }

    @Action(SendMessage)
    SendMessage(
        { patchState, getState }: StateContext<ChatsStateModel>,
        { payload }: SendMessage
    ) { 
        let state = JSON.parse(JSON.stringify(getState()));

        let chats:Chat[] = state.chats;
        let chat:Chat = state.currentChat;

        let message = {
            userUid: "", 
            parent: undefined,
            contactUid: payload.contactUid,
            contactId: payload.contactId,
            contactName: payload.contactName,
            contactPhoneNumber: payload.contactPhoneNumber,
            contactType: payload.contactType,
            content: payload.content, 
            createdAt: new Date(), 
            type:"chat", 
            incoming: false, 
            unread: false             
        };

        chat.messages.push(message);
        
        if(chat.parent != ""){
            let parentIndex = chats.findIndex(c => c.contactUid == payload.parent);
            
            if(parentIndex != -1){
                let chatIndex = chats[parentIndex].children.findIndex(c => c.contactUid == payload.contactUid);

                if(chatIndex != -1)
                    chats[parentIndex].children[chatIndex] = chat;
                else
                    chats[parentIndex].children.push(chat);
            }else{
                let parent:Chat = state.currentParent;

                parent.contactType = 0;
                parent.parent = undefined;
                parent.loaded = false;
                parent.badge = 0;
                parent.messages = [];
                parent.children = [state.currentChat];

                chats.push(parent);
            }
        }else{
            let chatIndex = chats.findIndex(c => c.contactUid == payload.contactUid);

            if(chatIndex != -1)
                chats[chatIndex] = chat;
            else
                chats.push(chat);
        }
        
        patchState({
            'chats': chats,
            'currentChat': chat,
            'counter': chats.length
        });
    }

    @Action(CloseChat)
    CloseChat(
        { patchState, getState }: StateContext<ChatsStateModel>,
        { payload }: CloseChat
    ) {
        let state = JSON.parse(JSON.stringify(getState()));
        
        let chats:Chat[] = state.chats;
        let chatIndex = chats.findIndex(c => c.contactPhoneNumber == payload.phoneNumber);

        if(chatIndex != -1)
          chats.splice(chatIndex, 1);
        
        patchState({
            'chats': chats,
            'currentChat': undefined,
            'currentParent': undefined,
            'currentPhoneNumber': undefined,
            'counter': chats.length
        });
    }
}