import { EventEmitter, Injectable } from '@angular/core';  
import { HubConnection, HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';  
import { environment } from 'environments/environment';
import { Store } from '@ngxs/store';
import { ProcessAppStatusNotification, SetUsingApps } from 'app/store/activeApps/activeApps.actions';
import { ProcessNotifications, SetOrdersByStatus } from 'app/store/orders/orders.actions';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from './api/api.service';

class OrderMessage {
  public orderId:number;
  public statusId:number;
  public status:string;
  public sent:string;
  public details:OrderMessageDetails;
}

class OrderMessageDetails {
  public latitude:number;
  public longitude:number;
}

class StatusMessage {
  public id:string;
  public status:string;
}

@Injectable()  
export class SignalrService {  
  newOrder = new EventEmitter<OrderMessage>();
  orderUpdated = new EventEmitter<OrderMessage>();
  orderDeliveryUpdated = new EventEmitter<any>();
  appStatusUpdated = new EventEmitter<StatusMessage>();
  connectionEstablished = new EventEmitter<Boolean>();  
  incomingChatMessage = new EventEmitter<any>();
  creditRequest = new EventEmitter<any>();
  deliveryMapUpdate = new EventEmitter<any>();
    
  private _hubUrl: string;
  private token = "";
  
  private connectionIsEstablished = false;  
  connectionIsEstablished$ = new BehaviorSubject<boolean>(false);

  private retryOnDisconnect = true;

  private _hubConnection: HubConnection;  
  
  constructor(
    private _store: Store,
    private _apiService: ApiService 
  ) { 

  }  

  public startService(token) {
    if(token){
      this.token = token;
      this.createConnection();  
      this.registerOnServerEvents();  

      return this._hubConnection.start();
    }
  }

  public stopService() {
    this.token = "";
    this.connectionIsEstablished = false;
    this.retryOnDisconnect = false;
    this._hubConnection.stop();
  }

  private createConnection() {  
    if(this.token) {
      let connectionOptions = {
        skipNegotiation: true, 
        transport: HttpTransportType.WebSockets, 
        accessTokenFactory: () => {
          return this.token;
        }
      };
    
      this._hubConnection = new HubConnectionBuilder()  
        .withUrl(environment.NOTIFICATIONS_URL + "/notifications", connectionOptions)  
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            let retryIn = Math.floor(Math.random() * 10000);      
            return retryIn;
          }
        })
        .configureLogging(LogLevel.Error)
        .build();  
    }
  }  

  private registerOnServerEvents(): void {  
    this._hubConnection.on('NEWORDER', (data: any) => {  
      this.newOrder.emit(data);
    });

    this._hubConnection.on('CREDITREQUEST', (data: any) => {
      this.creditRequest.emit(data);
    });

    this._hubConnection.on('ORDERUPDATE', (data: any) => {  
      this.orderUpdated.emit(data);  
    });

    this._hubConnection.on('ORDERDELIVERYACCEPTED', (data: any) => {
      this.orderDeliveryUpdated.emit(data);
    });

    this._hubConnection.on('APPSTATUSUPDATE', (data: any) => {
      this.appStatusUpdated.emit(data);
    });

    this._hubConnection.on('INCOMINGCHATMESSAGE', (data: any) => {
      this.incomingChatMessage.emit(data);
    });

    this._hubConnection.on('ORDERDELIVERY', (data: any) => {
      this.deliveryMapUpdate.emit(data);
    });

    this._hubConnection.onreconnected(connectionId => {
      console.log("Connection reestablished. Listening to updates.");
      this.connectionEstablished.emit(true);
      this.connectionIsEstablished = true;  
      setTimeout(() => {
        this.connectionIsEstablished$.next(true);
      }, 500);
    });

    this._hubConnection.on("ONCONNECTIONERROR", (data: any) => {
      console.log("Connection failed.");
      this._hubConnection.stop();
    });
    this._hubConnection.on("ONCONNECTIONSUCCESS", (data: any) => {
      console.log("Connection established. Listening to updates.");
      this.connectionEstablished.emit(true);
      this.connectionIsEstablished = true;
      setTimeout(() => {
        this.connectionIsEstablished$.next(true);
      }, 500);
    });
  }  
  
  private startConnection(): void {  
    this._hubConnection  
      .start()  
      .then(() => { })  
      .catch(err => {  
        setTimeout(() => { this.startConnection(); }, 5000);  
      });  
  }   

  public getOnline() {
    return this._apiService.get(environment.NOTIFICATIONS_URL + '/api/online');
  }
}    