import { Component, OnInit, OnDestroy, ViewEncapsulation, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { HubConnection, HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';  
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

class ConnectionsResponse {
  public connections:ConnectionEntry[]
}

class ConnectionEntry {
  public group:string;
  public count:number;
  public uids:string[]
}

@Component({
  selector: 'app-debugger',
  templateUrl: 'debugger.page.html',
  styleUrls: ['debugger.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DebuggerPage implements OnInit, OnDestroy {
  private _destroyComponent$ = new Subject();
  
  private _hubConnection: HubConnection;  
  private connectionIsEstablished = false;  

  public connectionEstablished = new EventEmitter<Boolean>();  

  public connections = {
    backoffice: {
      count: 0,
      uids: []
    },
    drugstore: {
      count: 0,
      uids: []
    },
    user: {
      count: 0,
      uids: []
    }
  };
  public notifications = [];

  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _http: HttpClient
  ) {}

  ngOnInit() {
    let connectionOptions = {
      skipNegotiation: true, 
      transport: HttpTransportType.WebSockets
    };
  
    this._hubConnection = new HubConnectionBuilder()  
      .withUrl(environment.NOTIFICATIONS_URL + "/debugger", connectionOptions)  
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: retryContext => {
          let retryIn = Math.floor(Math.random() * 10000);      
          return retryIn;
        }
      })
      .configureLogging(LogLevel.Error)
      .build();  

      this._hubConnection.on('DEBUG', (data: any) => {  
        let a = JSON.parse(data);

        if(a.action){          
          let index = this.connections[a.role.toLowerCase()].uids.indexOf(a.user);
          
          if(a.action == "connected"){
            if(index == -1){
              this.connections[a.role.toLowerCase()].uids.push(a.user);
              this.connections[a.role.toLowerCase()].count += 1;
            }
          }else if(a.action == "disconnected"){
            if(index > -1){
              this.connections[a.role.toLowerCase()].uids.splice(index, 1);
              this.connections[a.role.toLowerCase()].count -= 1;
            }
          }       
        }else{
          this.notifications = this.notifications.concat(a);
        }
      });

      this.startConnection();

      this._http.get(environment.NOTIFICATIONS_URL + "/api/debug").
        subscribe((response:ConnectionsResponse) => {
          if(response && response.connections.length > 0){
            response.connections.forEach((entry) => {
              this.connections[entry.group.toLowerCase()].count = entry.count;
              this.connections[entry.group.toLowerCase()].uids = entry.uids;
            });
          }
        });
  }

  private startConnection(): void {  
    this._hubConnection  
      .start()  
      .then(() => {  
        this.connectionIsEstablished = true;  
        this.connectionEstablished.emit(true); 
        console.log("Connection established. Listening to updates.");
      })  
      .catch(err => {  
        setTimeout(() => { this.startConnection(); }, 5000);  
      });  
  }   

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }
}
