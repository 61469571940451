import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class OrdersService {

  private _baseUrl: string;
  private _coreUrl: string;

  constructor(private _apiService: ApiService, private http: HttpClient) {
    this._baseUrl = environment.API_URL;
    this._coreUrl = environment.CORE_URL;
  }

  public getOrders(status) {
    return this._apiService.get(this._baseUrl + '/orders', {status: status});
  }

  public search(params) {
    return this._apiService.get(this._baseUrl + '/orders/search', params);
  }

  public getOrder(id) {
    return this._apiService.get(this._baseUrl + '/orders/'+id);
  }

  public acceptOrder(id, items) {
    return this._apiService.post(this._baseUrl + '/orders/'+id+'/accept', items);
  }

  public rejectOrder(id, items, otherReason) {
    return this._apiService.post(this._baseUrl + '/orders/'+id+'/reject',  {id: id, items: items, otherReason: otherReason});
  }

  public recreateOrder(id, details){
    return this._apiService.post(this._baseUrl + '/orders/'+id+'/replace', details);
  }

  public acceptUpdate(id){
    return this._apiService.post(this._baseUrl + '/orders/'+id+'/update-accept', {});
  }
  public cancelOrder(id, reason, details) {
    return this._apiService.post(this._baseUrl + "/orders/"+id+"/cancel", { reasonOrderCancelationId: reason, details: details });
  }
  
  public getOrderLocation(id) {
    return this._apiService.get(this._baseUrl + '/orders/'+id+'/delivery');
  }

  public getTopFive(shoppingCartId, latitude, longitude) {
    return this._apiService.get(this._coreUrl + '/cart/'+shoppingCartId+'/drugstores?latitude='+latitude+'&longitude='+longitude);
  }
}
