import { Component, ViewEncapsulation, Input, Inject, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'map-details',
  templateUrl: './map-details.component.html',
  styleUrls: ['./map-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MapDetailsComponent {
  @Input() name: string;
  @Input() address: any;

  constructor(
    private store: Store,
  ) { }
}
