import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Store } from '@ngxs/store';
import { Subject, Observable } from 'rxjs';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'app/shared/services/api/auth.service';
import { SetProfile, Logout } from 'app/store/auth/auth.actions';
import { ConfirmDialogComponent } from 'app/layout/components/confirm/confirm.component';
import { SessionService } from 'app/shared/session';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnDestroy {
  public form: FormGroup;
  public showPassword:boolean = false;
  public loading: boolean = false;

  private _loading:any;
  private _destroyComponent$ = new Subject();

  constructor(
    private _store: Store,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _dialog: MatDialog
  ) {
    this.form = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public login() {
    if(this.loading)
      return

    if (!this.form.valid) {
      this.form.markAsTouched();

      return;
    }

    this.loading = true;

    this._authService.login({
      email: this.form.get('email').value, 
      password: this.form.get('password').value
    }).subscribe((response) => {
        setTimeout(() => {
          this.loading = false;

          let authorizedRoles = ["gerente", "supervisor", "operador"];
        
          if(authorizedRoles.findIndex(i => i == response.role) == -1) {  
            const dialogRef = this._dialog.open(ConfirmDialogComponent, {
              height: 'auto',
              data: {
                'close': true,
                'title': 'Atenção',
                'text': `Você não tem permissão para acessar este serviço. Entre em contato com a administração do sistema.`
              }
            });
          }else{            
            this._store.dispatch(
              new SetProfile(response)
            );

            setTimeout(() => {
              this.loading = false;
              this._router.navigate(['dashboard']);
            }, 100);
          }
        }, 1000);
      }, (error) => {        
        setTimeout(() => {
          this.loading = false;

          this._dialog.open(ConfirmDialogComponent, {
            height: 'auto',
            data: {
              'close': true,
              'title': 'Atenção',
              'text': `Houve um problema na autenticação. Verifique suas credenciais e tente novamente.`
            }
          });
        }, 1000);
      });
  }
}
