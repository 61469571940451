import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MapComponent } from './map.component';
import { MapDetailsComponent } from './map-details/map-details.component';
import { AgmCoreModule } from '@agm/core';

@NgModule({
    declarations: [
        MapComponent,
        MapDetailsComponent
    ],
    entryComponents: [
        MapComponent,
        MapDetailsComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        AgmCoreModule
    ],
    exports: [
        MapComponent,
        MapDetailsComponent
    ]
})

export class MapModule { }

