import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from 'app/shared/shared.module';

import { AlertModule } from './components/alert/alert.module';
import { ConfirmModule } from './components/confirm/confirm.module';
import { DrugstoreDetailModule } from './components/drugstore-detail/drugstore-detail.module';
import { OrderEntryDetailModule } from './components/order-entry-detail/order-entry-detail.module';
import { ChatModule } from './components/chat/chat.module';
import { PhoneCallHistoryModule } from './components/phonecall-history/phonecall-history.module';
import { TabsModule } from './components/tabs/tabs.module';
import { MapModule } from './components/map/map.module';
import { CustomHeaderModule } from './components/custom-header/custom-header.module';
import { TimerModule } from './components/timer/timer.module';

import { OrderDetailModule } from './components/order-detail/order-detail.module';
import { OrdersHistoryModule } from './components/orders-history/orders-history.module';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        SharedModule
    ],
    exports: [ 
        BrowserAnimationsModule,
        AlertModule,
        ConfirmModule,
        DrugstoreDetailModule,
        OrderEntryDetailModule,
        ChatModule,
        PhoneCallHistoryModule,
        TabsModule,
        MapModule,
        CustomHeaderModule,
        TimerModule,
        OrdersHistoryModule,
        OrderDetailModule
    ]
})
export class LayoutModule {
}
