import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appCpfCnpjValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CpfCnpjValidatorDirective, multi: true }]
})
export class CpfCnpjValidatorDirective implements Validator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    const valueToValidate = String(control.value).replace(/\D+/gi, '');
    if (valueToValidate.length === 14) {
      return this.validateCnpj(valueToValidate);
    } else if (valueToValidate.length === 11) {
      return this.validateCpf(valueToValidate);
    } else {
      return { [this.generateUnexpectedMessageError(control)]: true };
    }
  }

  generateUnexpectedMessageError(control) {
    let msg = 'invalidCPForCNPJ';
    for (const key in (control.parent && control.parent.controls)) {
      if ((key === 'cpf' || key === 'identification') && (control.parent && control.parent.controls[key] === control)) {
        msg = 'invalidCPF';
      } else if (key === 'cnpj' && (control.parent && control.parent.controls[key] === control)) {
        msg = 'invalidCNPJ';
      }
    }
    return msg;
  }

  validateCpf(cpf) {
    const invalid = { invalidCPF : true };
    let numbers;
    let digits;
    let sum;
    let result;
    let equalDigits = 1;

    for (let i = 0; i < cpf.length - 1; i++) {
      if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
        equalDigits = 0;
        break;
      }
    }
    if (!equalDigits) {
      numbers = cpf.substring(0, 9);
      digits = cpf.substring(9);
      sum = 0;
      for (let i = 10; i > 1; i--) {
        sum += numbers.charAt(10 - i) * i;
      }
      result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (String(result) !== digits.charAt(0)) {
        return invalid;
      }
      numbers = cpf.substring(0, 10);
      sum = 0;
      for (let i = 11; i > 1; i--) {
        sum += numbers.charAt(11 - i) * i;
      }
      result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (String(result) !== digits.charAt(1)) {
        return invalid;
      }
      return null;
    } else {
      return invalid;
    }
  }

  validateCnpj(cnpj) {
    const invalid = { 'CNPJ inválido': true };

    // Valida DVs
    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    const digit = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;
    let result;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (String(result) !== digit.charAt(0)) {
      return invalid;
    }

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      sum += numbers.charAt(size - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (String(result) !== digit.charAt(1)) {
      return invalid;
    }

    return null;

  }
}
