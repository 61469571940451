import { Component, ViewEncapsulation, Input, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject, Observable, Subscription } from 'rxjs';
import { Chat, ChatMessage } from 'app/store/chats/.model';
import { Order } from 'app/store/orders/.model';
import { SendMessage } from 'app/store/chats/chats.actions';
import { ChatsState } from 'app/store/chats/chats.state';
import { BackofficeService } from 'app/shared/services/api/backoffice.service';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ChatComponent {
  @Input() public order: Order;

  @ViewChild("messageInput", {static: false}) messageInput : ElementRef;
  @ViewChild("messagesContainer", {static: false}) messagesContainer : ElementRef;

  public sending:boolean = false;
  public message:string = '';
  
  public chat:Chat = undefined;
  public messages: ChatMessage[] = [];
  public chatSubscription$: Subscription;

  constructor(
    private _store: Store,
    private _backofficeService: BackofficeService
  ) {
    this.chatSubscription$ = this._store.select(ChatsState.getChat)
      .subscribe((response:any) => {
        if(response){
          this.chat = response;
          this.messages = response.messages;
          setTimeout(() => {
            this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
          }, 1);
        }        
      });   
  }

  onKeyDown(event){
    if(event.ctrlKey && event.key === 'Enter' && this.message != ''){
      this.message += '\n';
      setTimeout(() => {
        this.messageInput.nativeElement.scrollTop = this.messageInput.nativeElement.scrollHeight;
      }, 1);      
    }else if(event.key === 'Enter'){
      this.send();
      return false;
    }
  }

  send(){
    if(this.message != undefined && this.message != ''){
      this.sending = true;
      
      this._store.dispatch(
        new SendMessage({
          contactUid: this.chat.contactUid, 
          contactId: this.chat.contactId,
          contactName: this.chat.contactName,
          contactPhoneNumber: this.chat.contactPhoneNumber, 
          contactType: this.chat.contactType,
          content: this.message,
          parent: this.chat.parent,
        })
      );

      this._backofficeService.sendChatMessage(this.chat.contactUid, this.message, this.chat.parent != undefined && this.chat.parent != ""? this.chat.parent : undefined)
        .subscribe((response) => {
          this.sending = false;
        });

      this.message = '';

      setTimeout(() => {
        this.messageInput.nativeElement.focus();
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      }, 1);
    }
  }
}
