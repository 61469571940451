import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngxs/store';
import { Subject, Observable, Subscription } from 'rxjs';
import { OrderEntryDetailDialogComponent } from 'app/layout/components/order-entry-detail/order-entry-detail.component';
import { SelectOrder, SetOrders } from 'app/store/orders/orders.actions';
import { OrdersState } from 'app/store/orders/orders.state';
import { orderStatus, drugstoreAvailable, deliveryAvailable} from 'app/store/orders/orderStatus';
import { RejectionReason } from 'app/store/orders/orderRejectionReason';
import { SignalrService } from 'app/shared/services/signalr.service';

import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.page.html',
  styleUrls: ['dashboard.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardPage implements OnInit, OnDestroy {
  public loading: boolean = false;

  public orderStatus = orderStatus;

  public orders: any[] = [];
  public orders$: Observable<any[]>;
  public ordersSubscription$: Subscription;

  public currentDirection:string = "asc";
  public currentSortField:string = "id";

  private _destroyComponent$ = new Subject();
  
  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _signalrService: SignalrService,
  ) { }

  ngOnInit() {
    this.orders$ = this._store.select(OrdersState.getAAFOrders);
        
    this.ordersSubscription$ = this.orders$.subscribe((response) => {
      if(response)
        this.orders = response;
    });
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
  }

  public orderBy(header){
    if(this.currentSortField != header)
      this.currentDirection = "asc";

    switch(header){
      case "id":
        this.currentSortField = "id";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return b.id - a.id;
          });
        }else{
          this.currentDirection = "asc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return a.id - b.id;
          });
        }
        break;
      case "total":
        this.currentSortField = "total";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return b.total - a.total;
          });
        }else{
          this.currentDirection = "asc";  
          this.orders = this.orders.slice().sort(function(a, b) {
            return a.total - b.total;
          });
        }
        break;
      case "drugstore":
        this.currentSortField = "drugstore";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.orders = this.orders.slice().sort(function(a, b) {
            if(a.drugstore.name && b.drugstore.name)
              return b.drugstore.name.localeCompare(a.drugstore.name);

            return b.drugstore.localeCompare(a.drugstore);
          });
        }else{
          this.currentDirection = "asc";  
          this.orders = this.orders.slice().sort(function(a, b) {
            if(a.drugstore.name && b.drugstore.name)
              return a.drugstore.name.localeCompare(b.drugstore.name);
              
            return a.drugstore.localeCompare(b.drugstore);
          });
        }
      break;
      case "totalTime":
        this.currentSortField = "totalTime";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return moment(b.createdAt).isAfter(moment(a.createdAt))? -1 : 1;
          });
        }else{
          this.currentDirection = "asc";  
          this.orders = this.orders.slice().sort(function(a, b) {
            return moment(a.createdAt).isAfter(moment(b.createdAt))? -1 : 1;
          });
        }
        break;
      case "statusTime":
        this.currentSortField = "statusTime";
        if(this.currentDirection == "asc")
        {
          this.currentDirection = "desc";
          this.orders = this.orders.slice().sort(function(a, b) {
            return moment(b.updatedAt?b.updatedAt : b.createdAt).isAfter(moment(a.updatedAt?a.updatedAt : a.createdAt))? -1 : 1;
          });
        }else{
          this.currentDirection = "asc";  
          this.orders = this.orders.slice().sort(function(a, b) {
            return moment(a.updatedAt?a.updatedAt : a.createdAt).isAfter(moment(b.updatedAt?b.updatedAt : b.createdAt))? -1 : 1;
          });
        }
        break;
      default:
        break;
    }
  }

  public changeStatus(status){
    this.orders = [];

    if(orderStatus[status]){
      if(this.ordersSubscription$)
          this.ordersSubscription$.unsubscribe();
    
      let update = false;

      switch(orderStatus[status].value){
        case orderStatus.AAF.value:
          this._store.dispatch(new SetOrders({status: orderStatus.AAF.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getAAFOrders);
          update = true;
          break;
        case orderStatus.PRE.value:
          this._store.dispatch(new SetOrders({status: orderStatus.PRE.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getPREOrders);
          update = true;
          break;
        case orderStatus.AAE.value:
          this._store.dispatch(new SetOrders({status: orderStatus.AAE.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getAAEOrders);
          update = true;
          break;
        case orderStatus.ETF.value:
          this._store.dispatch(new SetOrders({status: orderStatus.ETF.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getETFOrders);
          update = true;
          break;
        case orderStatus.ETCR.value:
          this._store.dispatch(new SetOrders({status: orderStatus.ETCR.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getETCROrders);
          update = true;
          break;
        case orderStatus.ENLR.value:
          this._store.dispatch(new SetOrders({status: orderStatus.ENLR.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getENLROrders);
          update = true;
          break;
        case orderStatus.ETFR.value:
          this._store.dispatch(new SetOrders({status: orderStatus.ETFR.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getETFROrders);
          update = true;
          break;
        case orderStatus.EAF.value:
          this._store.dispatch(new SetOrders({status: orderStatus.EAF.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getEAFOrders);
          update = true;
          break;
        case orderStatus.EMPF.value:
          this._store.dispatch(new SetOrders({status: orderStatus.EMPF.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getEMPFOrders);
          update = true;
          break;
        case orderStatus.EAAPF.value:
          this._store.dispatch(new SetOrders({status: orderStatus.EAAPF.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getEAAPFOrders);
          update = true;
          break;
        case orderStatus.EALCF.value:
          this._store.dispatch(new SetOrders({status: orderStatus.EALCF.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getEALCFOrders);
          update = true;
          break;
        case orderStatus.EPPF.value:
          this._store.dispatch(new SetOrders({status: orderStatus.EPPF.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getEPPFOrders);
          update = true;
          break;
        case orderStatus.ETC.value:
          this._store.dispatch(new SetOrders({status: orderStatus.ETC.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getETCOrders);
          update = true;
          break;
        case orderStatus.ENL.value:
          this._store.dispatch(new SetOrders({status: orderStatus.ENL.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getENLOrders);
          update = true;
          break;
        case orderStatus.PEN.value:
          this._store.dispatch(new SetOrders({status: orderStatus.PEN.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getPENOrders);
          update = true;
          break;
        case orderStatus.PCA.value:
          this._store.dispatch(new SetOrders({status: orderStatus.PCA.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getPCAOrders);
          update = true;
          break;
        case orderStatus.PSU.value:
          this._store.dispatch(new SetOrders({status: orderStatus.PSU.value, orders: []}));
          this.orders$ = this._store.select(OrdersState.getPSUOrders);
          update = true;
          break;
        default:
          break;
      }      

      if(update) {
        this.loading = true;
        
        this.ordersSubscription$ = this.orders$
          .subscribe((response) => { 
            if(response) 
              this.orders = response; 

            setTimeout(() => {
              this.loading = false;
            }, 500);
          });
      }
    }
  }

  public getReason(reasonId){
    let reason = RejectionReason.find(i => i.id == reasonId);
    
    if(reason)
      return reason.label;
      
    return '';
  }

  showUser() {
    //aaf, pre, aae, etf, etcr, enlr, etfr, eaf, etc, enl, pen, pca (todos exceto PSU)
    return true;
  }

  showDrugstore(order) {
    //aaf, eaf, pca
    return drugstoreAvailable.includes(order.status);
  }

  showDelivery(order) {
    //etf, etcr, enlr, etfr, eaf, etc, enl, pca
    return deliveryAvailable.includes(order.status);
  }

  showSupport(order) {
    //pen
    return order.status == orderStatus.PEN.value;
  }

  showActions(order) {
    //aaf
    return order.status == orderStatus.AAF.value;
  }

  public async openDialog(area, tab, order) {
    this._store.dispatch(new SelectOrder({order: order}));
    
    this._dialog.open(OrderEntryDetailDialogComponent, {data:{area: area, tab: tab }});
  }
}
