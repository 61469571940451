import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { DeliveryMapStateModel, DeliveryPosition, OrderPosition } from './.model';
import { GetMapData, ProcessDeliveryMapUpdateNotification, ProcessDeliveryMapNewOrderNotification, SelectDeliveryPosition } from './deliveryMap.actions';
import { BackofficeService } from 'app/shared/services/api/backoffice.service';

@State<DeliveryMapStateModel>({
    name: 'DeliveryMaps',
    defaults: {
        counter: 0,
        deliveryPositions: [],
        orderPositions: [],
        selectedPosition: undefined
    }
})
export class DeliveryMapState {
    constructor(
        private _store: Store,
        private _backofficeService: BackofficeService
    ) {}
        
    @Selector()
    static getCounter(state: DeliveryMapStateModel){
        return state.counter;
    }

    @Selector()
    static getDeliveryPositions(state: DeliveryMapStateModel){
        return state.deliveryPositions;
    }  

    @Selector()
    static getOrderPositions(state: DeliveryMapStateModel){
        return state.orderPositions;
    }

    @Selector()
    static getSelected(state: DeliveryMapStateModel){
        return state.selectedPosition;
    }  

    @Action(ProcessDeliveryMapUpdateNotification)
    DeliveryMapUpdateNotification(
        { patchState, getState }: StateContext<DeliveryMapStateModel>,
        { payload }: ProcessDeliveryMapUpdateNotification
    ) {
        let state = JSON.parse(JSON.stringify(getState()));
        let notification = JSON.parse(payload.notification);
        let deliveryPositions:DeliveryPosition[] = state.deliveryPositions;        

        let deliveryPositionIndex = deliveryPositions.findIndex(p => p.id == notification.id);
        
        if(deliveryPositionIndex != -1){
            deliveryPositions[deliveryPositionIndex].latitude = notification.latitude / 1;
            deliveryPositions[deliveryPositionIndex].longitude = notification.longitude / 1;
            deliveryPositions[deliveryPositionIndex].orders = notification.orders;
            deliveryPositions[deliveryPositionIndex].status = notification.status;
        }else{ 
            deliveryPositions.push({
                orders: notification.orders,
                id: notification.id / 1,
                latitude: notification.latitude / 1,
                longitude: notification.longitude / 1,
                name: notification.name,
                type: notification.type,
                status: notification.status
            });
        }
        
        patchState({
            'deliveryPositions': deliveryPositions,
            'counter': deliveryPositions.length
        });
    }

    @Action(ProcessDeliveryMapNewOrderNotification)
    ProcessDeliveryMapNewOrderNotification(
        { patchState, getState }: StateContext<DeliveryMapStateModel>,
        { payload }: ProcessDeliveryMapNewOrderNotification
    ) {
        let state = JSON.parse(JSON.stringify(getState()));
        let notifications = JSON.parse(payload.notifications);
        let orderPositions:OrderPosition[] = state.orderPositions;        

        for(var i = 0; i < notifications.length; i++){
            let entry = JSON.parse(JSON.stringify(notifications[i]));

            let indexOf = orderPositions.findIndex((item) => item.id == entry.orderId);

            if(indexOf == -1){
                this._backofficeService.getOrderMapData(entry.orderId)
                    .subscribe((response) => {
                        if(response){
                            orderPositions.push({
                                id: response.id,
                                latitude: response.latitude,
                                longitude: response.longitude
                            });
                            patchState({
                                'orderPositions': orderPositions
                            });
                        }
                    });
            }
        }       
    }

    @Action(SelectDeliveryPosition)
    SelectDeliveryPosition(
        { patchState, getState }: StateContext<DeliveryMapStateModel>,
        { payload }: SelectDeliveryPosition
    ) {
        let state = JSON.parse(JSON.stringify(getState()));
        let deliveryPositions:DeliveryPosition[] = state.deliveryPositions;  

        let deliveryPositionIndex = deliveryPositions.findIndex(p => p.id == payload.id);      

        let selected:DeliveryPosition = undefined;

        if(deliveryPositionIndex != -1)
            selected = deliveryPositions[deliveryPositionIndex];
        
        patchState({
            'selectedPosition': selected
        });
    }

    @Action(GetMapData)
    GetMapData(
        { patchState, getState }: StateContext<DeliveryMapStateModel>,
    ) {
        this._backofficeService.getMapData().subscribe((response) => {
            if(response){
                patchState({
                    'orderPositions': response
                });
            }
        });
    }
}