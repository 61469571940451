import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CreateBaseFeeRequest, CreateDynamicFeeRequest, ListBaseFeesResponse, ListDynamicFeesResponse } from "app/store/fees/.model";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ApiService } from "./api.service";

@Injectable()
export class FeesService {
  private _baseUrl: string;

  constructor(private _apiService: ApiService, private http: HttpClient) {
    this._baseUrl = environment.API_URL;
  }

  public listBaseFees(): Observable<ListBaseFeesResponse> {
    return this._apiService.get(this._baseUrl + "/BaseFee/All", { pageSize: 100 });
  }

  public listDynamicFees(): Observable<ListDynamicFeesResponse> {
    return this._apiService.get(this._baseUrl + "/DynamicFee/All", { pageSize: 100 });
  }

  public createBaseFee(newFee: CreateBaseFeeRequest) {
    return this._apiService.post(this._baseUrl + "/BaseFee", newFee);
  }

  public createDynamicFee(newFee: CreateDynamicFeeRequest) {
    return this._apiService.post(this._baseUrl + "/DynamicFee", newFee);
  }

  public updateBaseFee(editFee: CreateBaseFeeRequest, feeId: number) {
    return this._apiService.put(this._baseUrl + `/BaseFee/${feeId}`, editFee);
  }

  public updateDynamicFee(editFee: CreateDynamicFeeRequest, feeId: number) {
    return this._apiService.put(this._baseUrl + `/DynamicFee/${feeId}`, editFee);
  }

  public deleteBaseFee(feeId: number) {
    return this._apiService.delete(this._baseUrl + `/BaseFee/${feeId}`);
  }
  
  public deleteDynamicFee(feeId: number) {
    return this._apiService.delete(this._baseUrl + `/DynamicFee/${feeId}`);
  }
}
