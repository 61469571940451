import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AuthService {

  private _baseUrl: string;

  constructor(private http: HttpClient) {
    this._baseUrl = environment.API_URL;
  }

  public login(payload) {
    return this.http.post(
      this._baseUrl + '/auth',
      payload
    ).pipe(
      map((response: any) => {
        const decoded = jwt_decode(response.token);
        return {
          'id': decoded.uid,
          'token': response.token,
          'username': response.username,
          'role': decoded.role
        };
      })
    );
  }
}
