import { Action, Selector, State, StateContext } from "@ngxs/store";
import { FeesService } from "app/shared/services/api/fees.service";
import { FeesStateModel } from "./.model";
import {
  CreateBaseFee,
  CreateDynamicFee,
  DeleteBaseFee,
  DeleteDynamicFee,
  ListBaseFees,
  ListDynamicFees,
  UpdateBaseFee,
  UpdateDynamicFee,
} from "./fees.actions";

@State<FeesStateModel>({
  name: "Fees",
  defaults: {
    baseFees: [],
    dynamicFees: [],
    loadingFees: false,
    selectedBaseFee: null,
    selectedDynamicFee: null,
  },
})
export class FeesState {
  constructor(private feesService: FeesService) {}

  @Selector()
  static getBaseFees(state: FeesStateModel) {
    return state.baseFees;
  }

  @Selector()
  static getDynamicFees(state: FeesStateModel) {
    return state.dynamicFees;
  }

  @Selector()
  static getLoadingFees(state: FeesStateModel) {
    return state.loadingFees;
  }

  @Action(ListBaseFees)
  ListBaseFees({ getState, patchState }: StateContext<FeesStateModel>, {}: ListBaseFees) {
    const state = getState();
    patchState({ ...state, loadingFees: true });
    this.feesService.listBaseFees().subscribe((response) => {
      patchState({
        ...state,
        baseFees: response.baseFeesList,
        loadingFees: false,
      });
    });
  }

  @Action(ListDynamicFees)
  ListDynamicFees({ getState, patchState }: StateContext<FeesStateModel>, {}: ListDynamicFees) {
    const state = getState();
    patchState({ ...state, loadingFees: true });
    this.feesService.listDynamicFees().subscribe((response) => {
      patchState({
        ...state,
        dynamicFees: response.dynamicFeesList,
        loadingFees: false,
      });
    });
  }

  @Action(CreateBaseFee)
  CreateBaseFee({}: StateContext<FeesStateModel>, { payload }: CreateBaseFee) {
    return this.feesService.createBaseFee(payload);
  }

  @Action(CreateDynamicFee)
  CreateDynamicFee({}: StateContext<FeesStateModel>, { payload }: CreateDynamicFee) {
    return this.feesService.createDynamicFee(payload);
  }

  @Action(UpdateBaseFee)
  UpdateBaseFee({}: StateContext<FeesStateModel>, { payload }: UpdateBaseFee) {
    return this.feesService.updateBaseFee(payload.updateBaseFeeRequest, payload.feeId);
  }

  @Action(UpdateDynamicFee)
  UpdateDynamicFee({}: StateContext<FeesStateModel>, { payload }: UpdateDynamicFee) {
    return this.feesService.updateDynamicFee(payload.updateDynamicFeeRequest, payload.feeId);
  }

  @Action(DeleteBaseFee)
  DeleteBaseFee({}: StateContext<FeesStateModel>, { payload }: DeleteBaseFee) {
    return this.feesService.deleteBaseFee(payload);
  }
  @Action(DeleteDynamicFee)
  DeleteDynamicFee({}: StateContext<FeesStateModel>, { payload }: DeleteDynamicFee) {
    return this.feesService.deleteDynamicFee(payload);
  }
}
