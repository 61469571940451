import { Component, Input, Output, ViewEncapsulation, EventEmitter} from '@angular/core';

@Component({
  selector: 'drugstore-option',
  templateUrl: './drugstore-option.component.html',
  styleUrls: ['./drugstore-option.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DrugstoreOptionComponent {
    @Input() selected: boolean = false;
    @Input() drugstore: any = {};

    @Output() selectedChange: EventEmitter<any> = new EventEmitter();

    constructor() { }

    onSelect() {
        this.selected = !this.selected;
        this.selectedChange.emit({id: this.drugstore.drugstoreId, selected: this.selected});
    }
}
