import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { Order } from 'app/store/orders/.model';
import { OrdersService } from 'app/shared/services/api/orders.service';
import { AcceptMissingOrder } from 'app/store/orders/orders.actions';
import { ConfirmDialogComponent } from 'app/layout/components/confirm/confirm.component';

@Component({
  selector: 'incomplete-confirmation',
  templateUrl: './incomplete-confirmation.component.html',
  styleUrls: ['./incomplete-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class IncompleteConfirmationComponent {
  @Input() order: Order;

  @Output() onCancel: EventEmitter<any> = new EventEmitter();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter();

  public loading:boolean = false;

  constructor(
    private _store: Store,
    private _ordersService: OrdersService,
    private _dialog: MatDialog
  ) { }

  public goBack(){
    this.onCancel.emit([]);
  }

  public confirm(){
    this.loading = true;

    let items = this.order.items.map((item) => {
      return {
        productId: item.productId,
        price: item.price,
        discountPrice: item.discountPrice,
        quantity: item.quantity > item.stock? item.stock : item.quantity
      }
    });

    items = items.filter((item) => item.quantity > 0);
    
    this._ordersService.acceptOrder(this.order.id, items)
      .subscribe((response) => {
        this._store.dispatch(
          new AcceptMissingOrder({ id: this.order.id, newId: response.id })
        );
        this.onConfirm.emit();        
        //this._dialog.open(ConfirmDialogComponent, { data: { title: "Novo Pedido Criado", text: "Um novo pedido foi criado com os itens disponíveis. Identificador do pedido: "+ response.id +".", close: true }});
        this._dialog.open(ConfirmDialogComponent, { data: { title: "Pedido Atualizado", text: "O pedido foi atualizado e aguarda o aceite de um entregador.", close: true }});
        this.loading = false;
      }, (error) => {
        console.log(error);
        this._dialog.open(ConfirmDialogComponent, { data: { title: "Erro", text: "Houve um problema aceitando o pedido incompleto.", close: true }});
        this.loading = false;
      });
  }
}
