import { Component, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'phonecall-history',
  templateUrl: './phonecall-history.component.html',
  styleUrls: ['./phonecall-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class PhoneCallHistoryComponent {
  constructor(
    private store: Store
  ) { }
}
