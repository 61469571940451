import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngxs/store";
import { ConfirmDialogComponent } from "app/layout/components/confirm/confirm.component";
import { CpfCnpjValidatorDirective } from "app/shared/directives/validators/cpf/cpf-validator.directive";
import { BackofficeService } from "app/shared/services/api/backoffice.service";
import { CreateUserRequest, PixKeyType } from "app/store/users/.model";
import { UsersState } from "app/store/users/users.state";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";

@Component({
  selector: "create-user-modal",
  templateUrl: "create-user-modal.component.html",
  styleUrls: ["create-user-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CreateUserModalComponent implements OnInit, OnDestroy {
  private _destroyComponent$ = new Subject();

  public selectedRole: number = 1;
  public selectedHelpDeskLevel: number = 0;

  public form: FormGroup;
  public personalDataForm: FormGroup;
  public deliveryDataForm: FormGroup;
  public bankDataForm: FormGroup;

  public loading: boolean = false;

  public workingAreas = [];
  public workingAreasSub: Subscription;

  constructor(
    private _store: Store,
    private _formBuilder: FormBuilder,
    private _backofficeService: BackofficeService,
    private _dialog: MatDialog,
    private cpfValidator: CpfCnpjValidatorDirective,
    public dialogRef: MatDialogRef<CreateUserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { role: number }
  ) {
    this.personalDataForm = this._formBuilder.group({
      name: ["", [Validators.required]],
      birthDate: ["", [Validators.required]],
      rg: ["", [Validators.required]],
      cpf: ["", [this.cpfValidator, Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
      email: ["", [Validators.required, Validators.email]],
      phoneNumber: ["", [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],
      gender: ["", [Validators.required]],
      zipCode: ["", [Validators.required, Validators.minLength(8), Validators.maxLength(8)]],
      address: ["", [Validators.required]],
      neighborhood: ["", [Validators.required]],
      number: ["", [Validators.required]],
      complement: ["", [Validators.required]],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
    });

    this.bankDataForm = this._formBuilder.group({
      bank: ["", [Validators.required]],
      agency: ["", [Validators.required, Validators.minLength(3)]],
      digit: [""],
      account: ["", [Validators.required, Validators.minLength(4)]],
      pixKeyType: [PixKeyType.CPF, [Validators.required]],
      pixKey: ["", [this.cpfValidator, Validators.required]],
    });

    this.deliveryDataForm = new FormGroup({
      vehicle: new FormControl(0, [Validators.required, Validators.min(1)]),
      workingAreaId: new FormControl([], [Validators.required]),
    });

    this.form = this._formBuilder.group({
      creditCard: ["", [Validators.required, Validators.minLength(16), Validators.maxLength(16)]],
      role: [this.data.role, [Validators.required]],
      helpDeskLevel: [""],
      personalData: this.personalDataForm,
      bankData: this.bankDataForm,
      deliveryData: this.deliveryDataForm,
    });

    this.selectRole(this.data.role);
  }

  ngOnInit() {
    this.workingAreasSub = this._store.select(UsersState.getWorkingAreas).subscribe((areas) => (this.workingAreas = areas));
  }

  ngOnDestroy() {
    this._destroyComponent$.next(true);
    this._destroyComponent$.complete();
    this.workingAreasSub.unsubscribe();
  }

  public selectRole(role) {
    this.selectedRole = role;
    this.form.get("role").setValue(role);
    this.form.removeControl("bankData");
    this.form.removeControl("deliveryData");
    this.form.get("creditCard").clearValidators();
    this.form.get("creditCard").updateValueAndValidity();

    if (role == 3) {
      this.selectHelpDeskLevel(1);
    } else {
      this.selectHelpDeskLevel(undefined);
      if (role == 4) {
        this.form.addControl("bankData", this.bankDataForm);
        this.form.addControl("deliveryData", this.deliveryDataForm);
        this.form.get("creditCard").setValidators([Validators.required, Validators.minLength(16), Validators.maxLength(16)]);
        this.form.get("creditCard").updateValueAndValidity();
      }
    }
  }

  public selectHelpDeskLevel(level) {
    this.selectedHelpDeskLevel = level;
    this.form.get("helpDeskLevel").setValue(level);
  }

  public createUser() {
    if (!this.form.valid) {
      this.form.markAsDirty();
      this.form.markAsTouched();

      return;
    }

    let rawValues = this.form.getRawValue();
    const createUserRequest = this.structUser(rawValues);
    this.loading = true;

    this._backofficeService.createUser(createUserRequest).subscribe(
      (response) => {
        if (response) {
          setTimeout(() => {
            this.dialogRef.close({ succeed: true, role: rawValues.role });

            setTimeout(() => {
              this._dialog.open(ConfirmDialogComponent, {
                data: {
                  title: "Usuário criado",
                  text: "Usuário criado com sucesso.",
                  close: true,
                },
              });
            }, 250);
          }, 1000);
        }
      },
      (error) => {
        let message = "Houve um problema criando o usuário";

        if (error.error.errors) message = error.error.errors.message;

        this.loading = false;

        this._dialog.open(ConfirmDialogComponent, {
          data: { title: "Atenção!", text: message, close: true },
        });
      }
    );
  }
  structUser(values): CreateUserRequest {
    const personalValues = values.personalData;
    const bankValues = values.bankData;
    const deliveryValues = values.deliveryData;

    let req: CreateUserRequest = {
      isActive: true,
      roleId: values.role,
      helpDeskLevel: values.helpDeskLevel ? values.helpDeskLevel : 0,
      name: personalValues.name,
      birthDate: moment(personalValues.birthDate).locale("pt-br").format("L"),
      email: personalValues.email,
      phoneNumber: personalValues.phoneNumber,
      cpf: personalValues.cpf,
      gender: personalValues.gender,
      address: personalValues.address,
      number: personalValues.number,
      city: personalValues.city,
      complement: personalValues.complement,
      neighborhood: personalValues.neighborhood,
      rg: personalValues.rg,
      zipCode: personalValues.zipCode,
      state: personalValues.state,
    };

    if (values.role === 4) {
      req = {
        ...req,
        creditCard: values.creditCard,
        bank: bankValues.bank,
        agency: bankValues.agency,
        digit: bankValues.digit,
        account: bankValues.account,
        pixKey: bankValues.pixKey,
        pixKeyType: bankValues.pixKeyType,
        workingAreaId: deliveryValues.workingAreaId,
        vehicle: deliveryValues.vehicle,
      };
    }

    return req;
  }
}
