import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { Order } from 'app/store/orders/.model';
import { OrdersService } from 'app/shared/services/api/orders.service';
import { AcceptUpdatedOrder } from 'app/store/orders/orders.actions';
import { orderStatus } from 'app/store/orders/orderStatus';
import { ConfirmDialogComponent } from 'app/layout/components/confirm/confirm.component';
import * as moment from 'moment';

@Component({
  selector: 'tab-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OrderDetailsComponent implements OnInit {
  @Input() order: Order;
  @Output() public onCloseDialog:EventEmitter<any> = new EventEmitter<any>();
  @Output() public onOpenRecreate:EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCloseRecreate:EventEmitter<any> = new EventEmitter<any>();

  public canAcceptMissing:boolean = false;
  public canRecreate:boolean = false;
  public loading:boolean = false;

  currentAction:string = "details";

  constructor(
    private _store: Store,
    private _dialog: MatDialog,
    private _ordersService: OrdersService,
  ) { }
  
  ngOnInit() {
    this.order.items.forEach((item) => {
      if(item.stock < item.quantity)
        this.canAcceptMissing = true;
    });
  }

  formatDate(value){
    return moment(value).format("DD/MM/YYYY");
  }
  
  getStatusLabel() {
    let label = "";

    Object.keys(orderStatus).forEach((key) => {
      if(orderStatus[key].value == this.order.status)
        label = key;
    });

    return label;
  }

  isRunning(){
    return this.order.status != orderStatus.PCA.value && this.order.status != orderStatus.PSU.value;
  }
  
  goToRecreate() {
    this.currentAction = "recreate";
    this.onOpenRecreate.emit([]);
  }

  goToConfirmation() {
    this.currentAction = "confirmation";
  }

  goToDetails() {
    this.currentAction = "details";
    this.onCloseRecreate.emit([]);
  }

  confirmUpdateRequest() {
    this.loading = true;
    this._ordersService.acceptUpdate(this.order.id)
      .subscribe((response) => {
        this._store.dispatch(
          new AcceptUpdatedOrder({ id: this.order.id })
        ); 
        this._dialog.open(ConfirmDialogComponent, { data: { title: "Pedido Atualizado", text: "O pedido foi atualizado e aguarda o aceite de um entregador.", close: true }});
        this.loading = false;
        this.onCloseDialog.emit([]);
      }, (error) => {
        console.log(error);
        this._dialog.open(ConfirmDialogComponent, { data: { title: "Erro", text: "Houve um problema aceitando o pedido incompleto.", close: true }});
        this.loading = false;
      });
  }

  showConfirmation() {
    return this.order.items.filter((i) => i.updateRequest != null).length > 0;
  }

  showActions() {
    return this.order.status == orderStatus.PRE.value;
  }

  confirmMissing(){
    this.onCloseDialog.emit([]);
  }
  
  confirmRecreate(){
    this.onCloseDialog.emit([]);
  }
}
