import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { SignalrService } from 'app/shared/services/signalr.service';
import { DebuggerPage } from './debugger.page';
import { DebuggerPageRoutingModule } from './debugger-routing.module';
import { TimerModule } from 'app/layout/components/timer/timer.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TimerModule,
    DebuggerPageRoutingModule
  ],
  providers: [
    SignalrService
  ],
  declarations: [DebuggerPage]
})
export class DebuggerPageModule {}
