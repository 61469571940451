import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { AuthGuard } from 'app/shared/auth.guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

const routes: Routes = [
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then(m => m.LoaderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'chats',
    loadChildren: () => import('./pages/chats/chats.module').then(m => m.ChatsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'active-connections',
    loadChildren: () => import('./pages/active-connections/active-connections.module').then(m => m.ActiveConnectionsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'credit-requests',
    loadChildren: () => import('./pages/credit-requests/credit-requests.module').then(m => m.CreditRequestsPageModule),
    canActivate: [AuthGuard],
    data: { roles: [1] }
  },
  {
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then(m => m.MapPageModule),
    canActivate: [AuthGuard],
    data: { roles: [1] }
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersPageModule),
    canActivate: [AuthGuard],
    data: { roles: [1] }
  },
  {
    path: 'fees',
    loadChildren: () => import('./pages/fees/fees.module').then(m => m.FeesPageModule),
    canActivate: [AuthGuard],
    data: { roles: [1] }
  },
  {
    path: 'debugger',
    loadChildren: () => import('./pages/debugger/debugger.module').then(m => m.DebuggerPageModule),
    canActivate: [AuthGuard],
    data: { roles: [1] }
  },
  {
    path: '',
    redirectTo: 'loader',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    SharedModule,
    NgxMaterialTimepickerModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
