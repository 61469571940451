import { ActiveApp } from './.model';

export class ProcessAppStatusNotification {
    static readonly type = '[ActiveApp] ProcessNotification';
    constructor(public payload: { id: string, status: string }) {}
}

export class UpdateAppsCounter {
    static readonly type = '[ActiveApp] UpdateAppsCounter';
    constructor(public payload: { notifications: any[] }) {}
}
export class SetUsingApps {
    static readonly type = '[ActiveApp] SetUsingApps';
    constructor(public payload: { usingApps: ActiveApp[], onlineApps: string[] }) {}
}
export class ClearAppsCounter {
    static readonly type = '[ActiveApp] ClearAppsCounter';
    constructor() {}
}