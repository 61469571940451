import { Component, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AnimationController, IonSlides } from '@ionic/angular';

@Component({
  selector: 'dialog-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OrderDetailDialogComponent {
  private tab = "itens";

  @ViewChild("slides", {static: false}) slides: IonSlides;

  constructor(
    private store: Store,
    public dialogRef: MatDialogRef<OrderDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, text: string, close?: boolean }
  ) { }

  public close() {
    this.dialogRef.close();
  }

  public segmentChanged(event){
    this.showTab(event.detail.value);
  }

  public showTab(tab) {
    this.tab = tab;
    switch(tab){
      case 'itens':
        this.slides.slideTo(0);
        break;
      case 'status':
        this.slides.slideTo(1);
        break;
      case 'payment':
        this.slides.slideTo(2);
        break;
      default:
        break;
    }
  }
}
