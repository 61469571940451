import { NgModule } from '@angular/core';
import { MatDialogModule, MatIconModule, MatButtonModule } from '@angular/material';
import { TimerComponent } from './timer.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
        TimerComponent
    ],
    entryComponents: [
        TimerComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        IonicModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        TimerComponent
    ]
})

export class TimerModule { }

